import { minBy, uniq } from 'lodash'

import Contract from './GeneralContract'

class HealthInsuranceContract extends Contract {
  constructor(props) {
    super(props)
    this.details = this.details || {}
    this.additionalInfo = this.additionalInfo || {}
  }
  hasFamilyBenefit(familyId: number, benefitId: number): boolean {
    const familyDetail = this.details[familyId]

    return familyDetail && familyDetail.benefits.includes(benefitId)
  }

  getFamilyIds(): number[] {
    return uniq(this.items.map(item => item.familyId))
  }

  clearFamilyBenefit(familyId: number): void {
    this.items = this.items.filter(item => item.familyId !== familyId)
    this.updatePrice()
  }

  getStartMonth(familyId: number, benefitId: number): number {
    const familyItems = this.items.filter(
      item => item.familyId === familyId && item.benefitId === benefitId
    )

    const startMonthItem = minBy(familyItems, 'month')

    return startMonthItem ? startMonthItem.month : 13
  }

  setStartMonth(family: Family, benefit: Benefit, startMonth: number): void {
    let otherItems = this.items.filter(
      item => item.familyId !== family.id || item.benefitId !== benefit.id
    )

    if (benefit.isBenefit()) {
      otherItems = otherItems.filter(
        item => item.familyId !== family.id || item.month >= startMonth
      )
    }

    const newItems = []

    for (let month = startMonth; month < 12; month += 1) {
      newItems.push({
        familyId: family.id,
        month,
        price: benefit.getUserPrice(family),
        benefitId: benefit.id,
      })
    }

    this.items = otherItems.concat(newItems)
    this.updatePrice()
  }

  isMemberContracted(familyId: number, benefitId: number): boolean {
    return !!this.items.find(
      item =>
        item.id > 0 &&
        item.familyId === familyId &&
        item.benefitId === benefitId
    )
  }

  isMemberSelected(familyId: number,benefitId: number): boolean {
    return !!this.items.find(
        item =>
            item.familyId === familyId &&
            item.benefitId === benefitId
    )
  }


  isMissingDocument(): boolean {
    if(this.disableDocumentation) return false

    if (!this.benefit.isInfoRequired()) return false

    const memberIds = uniq(this.items.map(item => item.familyId))

    if (!this.additionalInfo) return true

    for (let i = 0; i < memberIds.length; i += 1) {
      if (!this.additionalInfo[memberIds[i]]) return true
    }

    return false
  }

  getExemptionPrice(tax, family: Family[]): number {


    if (!tax) return this.price

    const familyMap = family.reduce((prev,el)=>{
      return {
        ...prev,
        [el["id"]]: el
      }
    },{})

    const mapWho = {
      "child": "children",
      "you": "employee",
      "spouse": "spouse",
      "domestic_partner": "domestic_partner"
    }

    const opts = tax.whoBenefit.split(',')

    let finalIdsExempt = []

    this.items.forEach(item=>{
      const familyMember = familyMap[item.familyId]
      if( familyMember
          && !finalIdsExempt.includes(item.familyId)
          && mapWho.hasOwnProperty(familyMember.relationship)
          && opts.includes(mapWho[familyMember.relationship])){
        finalIdsExempt.push(item.familyId)
      }
    })

    return Math.min(finalIdsExempt.length * tax.limitExemption, this.price)
  }

  toJSON() {
    // clear member ids with no data
    const familyIds = this.getFamilyIds()
    Object.keys(this.details).forEach(familyId => {
      if (!familyIds.includes(Number(familyId))) {
        delete this.details[familyId]
      }
    })

    return super.toJSON()
  }
}

export default HealthInsuranceContract

import numeral from 'numeral'
import 'numeral/locales/es'

numeral.locale('es')

export const formatNumber = num => {
  if (!num) return 0

  if (typeof num === 'string') {
    num = parseFloat(num)
  }

  return numeral(num).format(`,0.[00]`)
}

export const strToFloat = (str) => {
  if (!str) return null
  return parseFloat(str.replace(/,/g, '.'))
}

export const formatCurrency = num => `${formatNumber(num)} €`

export const percent = (value, total) => {
  return total ? formatNumber((value / total) * 100) : 0
}

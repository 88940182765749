import access from 'safe-access'

import GeneralContract from './GeneralContract'
import FoodVoucherContract from './FoodVoucherContract'
import HealthInsuranceContract from './HealthInsuranceContract'
import KindergartenContract from './KindergartenContract'
import RentingContract from './RentingContract'
import LifeInsuranceContract from './LifeInsuranceContract'
import LifeInsuranceAxaContract from './LifeInsuranceAxaContract'

import LotteryContract from './LotteryContract'

const generateCustomContract = (props: any, contractMethod: string): Contract => {
  if (contractMethod === 'tickets') {
    return new LotteryContract(props)
  } else if (contractMethod === 'hole-year') {
    return new HealthInsuranceContract(props)
  } else if (contractMethod === 'month-to-month') {
    return new GeneralContract(props)
  } else {
    return new GeneralContract(props)
  }
}

export const generateContract = (props: any): Contract => {
  const categoryData = access(props, 'benefit.product.category')
  const contractMethod = access(props, 'benefit.contractMethod')
  const { alias: category, isDefault } = categoryData;

  if (categoryData && !isDefault && contractMethod) {
    return generateCustomContract(props, contractMethod)
  }

  if (category === 'food' && access(props, 'benefit.numberOfFace') > 0) {
    return new FoodVoucherContract(props)
  } else if (category === 'health-insurance') {
    return new HealthInsuranceContract(props)
  } else if (category === 'kindergarten') {
    return new KindergartenContract(props)
  } else if (category === 'renting') {
    return new RentingContract(props)
  } else if (category === 'life-insurance') {
    return new LifeInsuranceContract(props)
  } else if (category === 'life-insurance-axa') {
    return new LifeInsuranceAxaContract(props)
  } else if (category === 'lottery') {
    return new LotteryContract(props)
  } else {
    return new GeneralContract(props)
  }
}

import { generateContract } from 'models/Contract'
import { types } from './actions'


const initialState = []

export default (appState = initialState, { type, payload }) => {
  switch (type) {
    case types.CONTRACT_GET:
      return payload.map(item => generateContract(item))

    case types.CONTRACT_ADD:
      return [...appState, ...payload.map(item => generateContract(item))]

    case types.CONTRACT_UPDATE:
      return appState.map(item =>
        item.id === payload.id
          ? generateContract(
              {
                ...item,
                ...payload,
              },
              true
            )
          : item
      )

    case types.CONTRACT_REMOVE:
      return appState.filter(item => item.id !== payload)

    default:
      return appState
  }
}

export const getContracts = state => state.contracts
import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'

import { uploadFile } from 'store/app/actions'

class DropzoneComponent extends React.Component {
  handleAcceptFiles = acceptedFiles => {
    return uploadFile(acceptedFiles[0], null, 'document').then(res => {
      this.props.onChange({
        name: res.name,
        originalFilename: res.originalFilename,
      })
    })
  }

  render() {
    return (
      <Dropzone className="dropzone-container" onDrop={this.handleAcceptFiles}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>Drag and drop some files here, or click to select files</p>
            </div>
          </section>
        )}
      </Dropzone>
    )
  }
}

DropzoneComponent.propTypes = {
  onChange: PropTypes.func,
}

export default DropzoneComponent

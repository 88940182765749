import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from 'utils/number'

type Props = {
  contract: Contract
}

const GeneralDetail: React.FC<Props> = ({ contract }) => {
  const { i18n } = useTranslation()
  moment.locale(i18n.language)
  const months = moment.months()
  const prices = months.map((name, month) => (
    <div className="row" key={`general_detail_${name}`}>
      <label className="col-5">{name}</label>
      <div className="col-7">
        {formatCurrency(contract.getMonthContractPrice(month))}
      </div>
    </div>
  ))

  return (
    <div className="row mt-5">
      <div className="col-sm-6">{prices.slice(0, 6)}</div>
      <div className="col-sm-6">{prices.slice(6, 12)}</div>
    </div>
  )
}

export default GeneralDetail

import React from 'react'
import { useTranslation } from 'react-i18next'
import access from 'safe-access'

import { isProductLimitNotReached } from 'utils/contracts'
import { showNotify } from 'utils/notify'
import MonthSelect from 'components/BenefitDetail/MonthSelect'
import './Renting.scss'
import ContractPrice from "components/BenefitDetail/ContractPrice"
import NumberField from 'components/forms/NumberField'


interface Props {
  contract: Contract
  editable?: boolean
  simulation: Simulation
  onChange: () => void
}

const Renting = (props: Props) => {
  const { t } = useTranslation()
  const { contract, editable, simulation } = props
  const { details, benefit } = contract
  const isTaxAdvantage = access(contract, 'benefit.isTaxAdvantage')

  const isLimitNotReached = isProductLimitNotReached(contract, simulation)

  const parseDetails = (details) => {
    details.feeWithoutVAT = Number(details.feeWithoutVAT)
    details.feeWithVAT = Number(details.feeWithVAT)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
    const {
      simulation: { available, availableTop, flexibleBag, topBag, fixedSalary }
    } = props
    const possibleMaxPrice = isTaxAdvantage ? flexibleBag : topBag
    let availableMoney = isTaxAdvantage ? available : availableTop
    let isNoLimit = false
    if (!isTaxAdvantage && !topBag) {
      availableMoney = fixedSalary
      isNoLimit = true
    }

    parseDetails(contract.details)

    const oldPrice = contract.price
    const oldDetails = contract.details


    if (!editable) return

    const newDetails = {
      ...contract.details,
      [e.target.name]: e.target.value,
    }

    if (newDetails.feeWithoutVAT > newDetails.feeWithVAT) {
      showNotify("La cuota sin IVA no puede ser superior a la cuota con IVA", 'error')
      return
    }

    contract.updateDetails(newDetails)

    if (!isNoLimit && contract.price > Math.min(availableMoney + oldPrice, possibleMaxPrice)) {
      // restore old state
      contract.updateDetails(oldDetails)
      showNotify('El precio excede la cantidad disponible', 'error')
    } else {
      props.onChange()
    }
  }

  const handleMonthChange = (month: number) => {
    const {
      simulation: { available, availableTop, flexibleBag, topBag, fixedSalary },
    } = props
    const possibleMaxPrice = isTaxAdvantage ? flexibleBag : topBag
    let availableMoney = isTaxAdvantage ? available : availableTop
    let isNoLimit = false
    if (!isTaxAdvantage && !topBag) {
      availableMoney = fixedSalary
      isNoLimit = true
    }

    const oldPrice = contract.price
    const oldDetails = contract.details

    if (!editable) return

    contract.updateDetails({
      ...contract.details,
      startDate: month,
    })

    if (!isNoLimit && contract.price > Math.min(availableMoney + oldPrice, possibleMaxPrice)) {
      // restore old state
      contract.updateDetails(oldDetails)
      showNotify('El precio excede la cantidad disponible', 'error')
    } else {
      props.onChange()
    }
  }

  if(!details.active){
    return (<div className="alert alert-danger" role="alert">
      {t('contracts.inactive_product')}
    </div>)

  }else {
    return (
        isLimitNotReached ? (
            <React.Fragment>
              <div className="mt-4 contract-body-alt">
                <div className="form-group row">
                  <label className="col-lg-6">{t('products.with_tax')}</label>
                  <div className="col-lg-6">
                    <div className="input-group embed">
                      <input
                          type="number"
                          className="form-control"
                          name="feeWithVAT"
                          value={details.feeWithVAT}
                          onChange={handleChange}
                          min="0"
                          disabled={!editable}
                      />
                      <span className="input-group-addon">€</span>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-lg-6">{t('products.with_no_tax')}</label>
                  <div className="col-lg-6">
                    <div className="input-group embed">
                      <input
                          type="number"
                          className="form-control"
                          name="feeWithoutVAT"
                          value={details.feeWithoutVAT}
                          onChange={handleChange}
                          min="0"
                          disabled={!editable}
                      />
                      <span className="input-group-addon">€</span>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-lg-6">{t('products.vehicle_value')}</label>
                  <div className="col-lg-6">
                    <div className="input-group embed">
                      <input
                          type="number"
                          className="form-control"
                          name="vehicleValue"
                          value={details.vehicleValue}
                          onChange={handleChange}
                          min="0"
                          disabled={!editable}
                      />
                      <span className="input-group-addon">€</span>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-lg-6">{t('products.years')}</label>
                  <div className="col-lg-6">

                    <NumberField
                        className="form-control w-auto"
                        min={2}
                        name="years"
                        max={5}
                        returnEvent={true}
                        onChange={handleChange}
                        input={details.years}
                        disabled={!editable}
                    />

                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-lg-6">% {t('products.privative_use')}</label>
                  <div className="col-lg-6">
                    <div className="input-group embed">
                      <input
                          type="number"
                          className="form-control"
                          name="privateUse"
                          value={details.privateUse}
                          onChange={handleChange}
                          min="0"
                          max="100"
                          disabled={true}
                      />
                      <span className="input-group-addon">%</span>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-lg-6">{t('products.start_date')}</label>
                  <div className="col-lg-6">
                    <MonthSelect
                        availability={benefit.monthsAvailability}
                        value={details.startDate}
                        onChange={handleMonthChange}
                        disabled={!editable}
                    />
                  </div>
                </div>

                <div className="text-center my-5">
                  <ContractPrice price={contract.price} discount={contract.userBag}/>

                </div>
              </div>
            </React.Fragment>
        ) : (
            <React.Fragment>
              <div className="alert alert-danger" role="alert">
                {t('HEADER.LIMIT')} {t('HEADER.REACHED')}
              </div>
            </React.Fragment>
        ))
  }
}

Renting.defaultProps = {
  editable: true,
}

export default Renting

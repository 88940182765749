import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { withLayout } from 'pages/Layout'
import { getBenefits } from 'store/benefit/reducer'
import { getContractItems } from 'store/simulator/reducer'
import ProductsPage from './ProductsPage'

const mapStateToProps = state => ({
  benefits: getBenefits(state),
  cart: getContractItems(state),
})

export default withRouter(
  withLayout('Product', connect(mapStateToProps)(ProductsPage))
)

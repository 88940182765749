/* eslint-disable */
import React from 'react'
import { withRouter } from 'react-router-dom'

import './Bottombar.scss'
import { useTranslation } from "react-i18next";

type Props = {
  user: User
}

const Index: React.FC<Props> = props => {
  const { user } = props
  const { company } = user

  const { t, i18n } = useTranslation()


  const style: any = {}

  if (company.colorBar) {
    style.backgroundColor = company.colorBar
  }
  if (company.colorTitles) {
    style.color = company.colorTitles
  }

  return (
    <nav className="navbar navbar-default navbar-fixed-bottom cb-navbar-content">

      <button
        type="button"
        className="bottom-bar-button-left"
      >
        <span className="cp" title="legal">
          <a href="/assets/legal.pdf" target="_blank" className="legal-pdf">
            {t('MENU.LEGAL')}
          </a>
        </span>
      </button>

      <button
        type="button"
        className="bottom-bar-button-left"
      >
        <span className="cp" title="privacy">
          <a href="/assets/privacy.pdf" target="_blank" className="legal-pdf">
            {t('MENU.PRIVACY')}
          </a>
        </span>
      </button>

      <button
        type="button"
        className="bottom-bar-button-left"
      >
        <span className="cp" title="cookies">
          <a href="/assets/cookielegal.pdf" target="_blank" className="legal-pdf">
            Cookies
          </a>
        </span>
      </button>
    </nav>
  )
}

export default withRouter(Index)

import access from 'safe-access'

type ProductStatus = {
  status: string
  text: string
}

export const strAmountIsZero = (str) => {
  return !str || str === "0" || str ==="0%"
}
export const getProductStatus = (
  productContracts: Contract[]
): ProductStatus => {
  let status = ''
  let text = 'HOME.NON_SIMULATED_PRODUCT'

  if (productContracts.find(item => item.isCart() && item.isMissingDocument())) {
    status = 'missing-document'
    text = 'HOME.MISSING_DOCUMENT'
  } else if (productContracts.find(item => item.isCart())) {
    status = 'pending-completion'
    text = 'HOME.PENDING_CART'
  } else if (
      productContracts.find(
          item =>  ['contracted','pending','cancelled'].indexOf(item.status) >= 0 && !item.isSigned()
      )
  ) {
    status = 'pending-review'
    text = 'HOME.PENDING_SIGNATURE'
  } else if (productContracts.find(item => item.status === 'pending')) {
    status = 'pending-review'
    text = 'HOME.PENDING_REVIEW'
  }  else if (productContracts.find(item => item.status === 'cancelled')) {
    status = 'product-cancelled'
    text = 'HOME.CANCELLED'
  } else if (productContracts.length) {
    status = 'contracted'
    text = 'HOME.CONTRACTED'
  }
  return {
    status,
    text,
  }
}

export const isProductLimitNotReached = (contract, simulation) => {
  const {
    available, availableTop, topBag
  } = simulation
  const isTaxAdvantage = access(contract, 'benefit.isTaxAdvantage')

  return (
    isTaxAdvantage && available >= 0
  ) || (
    topBag && !isTaxAdvantage && availableTop >= 0
  ) || (
    !topBag && !isTaxAdvantage
  )
}

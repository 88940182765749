import { connect } from 'react-redux'

import { getSimulation, getContractItems } from 'store/simulator/reducer'
import BenefitDetail from './BenefitDetail'
import { getFamily } from 'store/families/reducer'

const mapStateToProps = state => ({
  cart: getContractItems(state),
  family: getFamily(state),
  simulation: getSimulation(state),
})

export default connect(mapStateToProps)(BenefitDetail)

import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import FamilyInformation from './FamilyInformation';

const selector = formValueSelector('FamilyInformation');
const mapStateToProps = state => ({
  family: selector(state, 'family'),
});

export default connect(mapStateToProps)(FamilyInformation);

import Home from 'pages/Home'
import Menu from 'pages/Menu'

import CompanyDocuments from 'pages/Documents/CompanyDocuments'
import UserDocuments from 'pages/Documents/UserDocuments'
import Profile from 'pages/Profile'
import Cart from 'pages/Cart'
import Compensation from 'pages/Compensation'

import ProductsPage from 'pages/Products'
import HealthInsurance from 'pages/Products/HealthInsurance'

import PrivacyPolicy from 'pages/PrivacyPolicy'

const routes = [

  {
    path: '/',
    component: Menu,
    exact: true,
  },
  {
    path: '/flexible',
    component: Home,
    exact: true,
  },
  {
    path: '/other',
    component: Home,
    exact: true,
  },
  {
    path: '/documents',
    component: CompanyDocuments,
    exact: true,
  },
  {
    path: '/documents/user',
    component: UserDocuments,
    exact: true,
  },
  {
    path: '/profile',
    component: Profile,
  },
  {
    path: '/cart',
    component: Cart,
  },

  {
    path: '/products/health-insurance',
    component: HealthInsurance,
  },
  {
    path: '/products/:category',
    component: ProductsPage,
  },

  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/compensation',
    component: Compensation,
    exact: true,
    },
]

export default routes

import React from 'react'
import GuestLayout from './GuestLayout'

export function withLayout(pageName, InnerComponent) {
  return props => (
    <GuestLayout
      {...props}
      pageName={pageName}
      innerComponent={InnerComponent}
    />
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import { replace } from 'connected-react-router'
import { reduxForm, Field, SubmissionError } from 'redux-form'
import { required, confirmation, length } from 'redux-form-validators'
import qs from 'qs'

import { resetPassword } from 'store/auth/actions'
import InputField from 'components/forms/InputField'
import './ResetPasswordPage.scss'

class ResetPasswordPage extends React.Component {
  params = { token: '' }
  constructor(props) {
    super(props)

    this.params = qs.parse(props.location.search.slice(1))
    if (!this.params.token || this.params.token.length < 10) {
      this.props.dispatch(replace(`/login`))
    }
  }

  onSubmit = values => {
    return this.props
      .dispatch(resetPassword(this.params.token, values))
      .then(() => {
        this.setState({
          success: true,
        })
      })
      .catch(res => {
        const message =
          res.status === 400
            ? 'Sorry, we can not find your email address'
            : 'Sorry. there was a problem on connecting the server'
        throw new SubmissionError({
          _error: message,
        })
      })
  }

  render() {
    const { handleSubmit, error, t } = this.props

    return (
      <div className="login-form text-center">
        <form id="form_login" onSubmit={handleSubmit(this.onSubmit)}>
          <div className="ui stacked segment">
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="form-group">
              <Field
                type="password"
                component={InputField}
                icon="entypo-key"
                name="newPassword"
                placeholder="New Password"
                validate={[required(), length({ min: 8 })]}
              />
            </div>

            <div className="form-group">
              <Field
                type="password"
                component={InputField}
                icon="entypo-key"
                name="confirmPassword"
                placeholder="Confirm Password"
                validate={confirmation({
                  field: 'newPassword',
                  message: 'Password does not match',
                })}
              />
            </div>

            <button className="btn" type="submit">
              {t('buttons.reset_password')}
            </button>
          </div>
        </form>
      </div>
    )
  }
}

ResetPasswordPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

export default reduxForm({
  form: 'ForgotPasswordForm',
})(ResetPasswordPage)

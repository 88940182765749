import React from 'react'
import access from 'safe-access'

import { formatCurrency } from 'utils/number'

type Props = {
  contract: Contract
}

const LotteryDetail: React.FC<Props> = ({ contract }) => {
  return (
    <div className="mt-5">
      <h4>{`${access(
        contract,
        'details.ticketCount'
      )} tickets (${formatCurrency(contract.price)})`}</h4>
    </div>
  )
}

export default LotteryDetail

import React, { FC } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
// import SelectField from "../forms/SelectField"
import LoadingButton from "../LoadingButton"
import { reduxForm, Field } from 'redux-form'
import { required } from 'redux-form-validators'
import UploadFieldWithName from "../forms/UploadFIleWithName"

export const DOCUMENTS_FORM_NAME = "DocumentForm"

type Props = {
    isOpen: boolean,
    toggle(): void
}

/**
 *
 * @param handleSubmit
 * @param isOpen
 * @param toggle
 * @param submitting
 * @returns {JSX.Element}
 * @constructor
 */
const DocumentForm: FC<Props & FormProps> = ({ handleSubmit, isOpen, toggle, submitting }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} backdrop='static'>
            <ModalHeader toggle={toggle}>New Document</ModalHeader>
            <form onSubmit={handleSubmit}>
                <ModalBody>
                    <div className='form-group'>
                        <Field
                            component={UploadFieldWithName}
                            name='file'
                            validate={required()}
                            props={{
                                accept: '.pdf'
                            }}
                        />
                    </div>

                    {/*<div className='form-group'>*/}
                    {/*    <Field component={SelectField} name='type' validate={required()}>*/}
                    {/*        <option value='Contracts'>Contracts</option>*/}
                    {/*        <option value='Plan'>Plan</option>*/}
                    {/*        <option value='Status'>Status</option>*/}
                    {/*        <option value='Template'>Template</option>*/}
                    {/*    </Field>*/}
                    {/*</div>*/}
                </ModalBody>

                <ModalFooter className='justify-content-center'>
                    <LoadingButton type='submit' isLoading={submitting} color='primary'>
                        Submit
                    </LoadingButton>
                    <Button type='button' color='link' onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    )
}

export default reduxForm({
    form: DOCUMENTS_FORM_NAME,
})(DocumentForm)

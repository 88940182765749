import React from 'react'

type Props = {
  user: User
}

const PrivacyPolicy: React.FC<Props> = props => {
  const {
    user: { company },
  } = props

  return <div>{company.privacyDetail}</div>
}

export default PrivacyPolicy

import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import access from 'safe-access'

import { formatCurrency } from 'utils/number'
import MonthRow from './MonthRow'
import ContractPrice from "components/BenefitDetail/ContractPrice"

type Props = {
  contract: FoodVoucherContract
  simulation: Simulation
  onChange(): void
}

class FoodVoucherInfo extends React.Component<Props & WithTranslation> {
  getMaxPrice = () => {
    const {
      contract: { benefit },
      simulation: { flexibleBag, topBag },
    } = this.props
    const { isTaxAdvantage } = benefit

    if (isTaxAdvantage) {
      return Math.min(flexibleBag, benefit.workingPrice)
    } else if (!isTaxAdvantage) {
      return topBag ? Math.min(topBag, benefit.workingPrice) : benefit.workingPrice
    }
  }

  handleChange = (month, face, count) => {
    const {
      contract,
      simulation: { available, availableTop, fixedSalary, topBag },
    } = this.props
    const isTaxAdvantage = access(contract, 'benefit.isTaxAdvantage')
    let availableMoney = isTaxAdvantage ? available : availableTop
    if (!isTaxAdvantage && !topBag) {
      availableMoney = fixedSalary
    }

    const restFacesPrice =
      contract.getMonthContractPrice(month) -
      contract.getMonthFacePrice(month, face)

    const maxPrice = Math.min(
      this.getMaxPrice() - restFacesPrice,
      availableMoney + contract.getMonthFacePrice(month, face)
    )

    const maxCount =
      contract.benefit.getFacePrice(face) > 0
        ? Math.floor(maxPrice / contract.benefit.getFacePrice(face))
        : 0

    contract.setMonthFaceCount(month, face, Math.min(maxCount, count))

    this.props.onChange()
  }

  render() {
    const { contract, t } = this.props
    const { benefit } = contract
    const maxPrice = this.getMaxPrice()

    const months = []
    let errorFormat = false

    for (let month = 0; month <= 11; month += 1) {

      if(contract.getMonthContract(month) && contract.getMonthContract(month).details === null) {
        //this is not a food voucher contract
        errorFormat = true
        break
      }

      months.push(
        <MonthRow
          key={month}
          month={month}
          maxPrice={maxPrice}
          benefit={benefit}
          item={contract.getMonthContract(month)}
          onChange={this.handleChange}
          disabled={!benefit.monthsAvailability[month]}
        />
      )
    }

    const faceValues = []
    for (let i = 1; i <= benefit.numberOfFace; i += 1) {
      faceValues.push(
        <div className="row no-gutters text-right d-block" key={i}>
          <span className="badge px-4 py-2">
            Vales de {formatCurrency(benefit[`faceValue${i}`])}
          </span>
        </div>
      )
    }

    return (
      <React.Fragment>
        <div>
          {t('products.month_text')}
          <span className="ml-2 bold">
            ({t('products.max_available')}: {formatCurrency(maxPrice)})
          </span>
        </div>
        <div className="panel panel-primary mb-0">
          <div className="panel-body">
            <div className="food-voucher-row mb-3">
              <label />
              {faceValues}
              <div className="total" />
            </div>

            {!errorFormat ? months : <div className="alert alert-danger" role="alert">
              Error de Formato. El contrato no tiene formato de vale de comida. Por favor contacta con el administrador
            </div>}

          </div>
        </div>
        <div className="text-center my-3">

          <ContractPrice price={contract.price} discount={contract.userBag}/>

        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation()(FoodVoucherInfo)

import * as api from 'utils/api'
import { showNotify, alertError } from 'utils/notify'

export const types = {
  CART_GET: 'CART_GET',
  CART_ADD: 'CART_ADD',
  CART_UPDATE: 'CART_UPDATE',
  CART_REMOVE: 'CART_REMOVE',
  CART_REMOVE_ITEMS: 'CART_REMOVE_ITEMS',
}

export const addItem = (details, benefit, supplementaries = []) => dispatch => {
  return api.post(`/contracts`, details).then(
    res => {
      dispatch({
        type: types.CART_ADD,
        payload: {
          ...res,
          benefit,
          supplementaries,
        },
      })
      showNotify('Producto añadido a la cesta')

      return res
    },
    err => {
      alertError(err.error)
    }
  )
}

export const updateItem = (cartId, details) => dispatch => {
  return api.put(`/contracts/${cartId}`, details).then(
    res => {
      dispatch({
        type: types.CART_UPDATE,
        payload: res,
      })
      showNotify('Producto de la cesta actualizado')
    },
    err => {
      alertError(err.error)
    }
  )
}

export const removeItem = cartId => dispatch => {
  return api.del(`/contracts/${cartId}`).then(
    res => {
      dispatch({
        type: types.CART_REMOVE,
        payload: cartId,
      })
      showNotify('Producto eliminado de la cesta')
    },
    err => {
      alertError(err.error)
    }
  )
}

export const purchaseCart = cartItems => dispatch => {
  const ids = cartItems.map(item => item.id)

  return api.post(`/contracts/purchase`, { ids }).then(
    res => {
      dispatch({
        type: 'CONTRACT_ADD',
        payload: res.map(contract => {
          const cartItem = cartItems.find(
            item => item.benefitId === contract.benefitId
          )

          return {
            ...contract,
            benefit: cartItem.benefit,
          }
        }),
      })
      dispatch({
        type: types.CART_REMOVE_ITEMS,
        payload: ids,
      })
      showNotify(
        'Abre el email y acepta las condiciones para activar el contrato'
      )
    },
    err => {
      alertError(err.error)
    }
  )
}

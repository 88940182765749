import { connect } from 'react-redux'

import { getFamily } from 'store/families/reducer'
import { getSimulatorItems } from 'store/simulator/reducer'
import CalcDetail from './CalcDetail'
import { selectProfile } from 'store/profile/reducer'

const mapStateToProps = state => ({
  user: selectProfile(state),
  children: getFamily(state, 'child'),
  family:getFamily(state),
  simulatorItems: getSimulatorItems(state),
})

export default connect(mapStateToProps)(CalcDetail)

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getTranslate } from 'helpers/translate'
import { getMessages } from 'store/company/actions'
import { selectProfile } from 'store/profile/reducer'
import { getMessages as getMessagesSelector } from 'store/company/reducer'
import Panel from 'components/Panel'
import './News.scss'

type Props = {}

const News: React.FC<Props> = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector(selectProfile)
  const messages: CompanyMessage[] = useSelector(getMessagesSelector)

  useEffect(() => {
    dispatch(getMessages())
  }, [dispatch, user.collectiveId, user.companyId])

  return (
    <Panel
      title={t('HOME.NEWS')}
      color={user.company.colorTitles}
      bodyClass="px-0"
    >
      {!messages.length && <div className="text-center">No news posted</div>}

      <ul className="news-list">
        {messages.map(m => (
          <li
            key={m.id}
            dangerouslySetInnerHTML={{
              __html: getTranslate(m, 'text', i18n.language),
            }}
          ></li>
        ))}
      </ul>
    </Panel>
  )
}

export default News

import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import access from 'safe-access'

import { formatCurrency, strToFloat } from 'utils/number'
import { isProductLimitNotReached } from 'utils/contracts'
import { showNotify } from 'utils/notify'
import { getTable } from 'store/company/actions'
import MonthSelect from 'components/BenefitDetail/MonthSelect'
import ContractPrice from "../../../components/BenefitDetail/ContractPrice";

interface Props {
  contract: Contract
  editable?: boolean
  simulation: Simulation
  user: User
  table: InsuranceTable
  onChange: () => void
  dispatch: (action: any) => Promise<any> | void
}

class LifeInsurance extends React.Component<Props & WithTranslation> {
  static defaultProps = {
    editable: true,
  }
  private month: any;

  constructor(props) {
    super(props)
    this.props.dispatch(getTable('life'))
  }

  componentDidUpdate(prevProps) {
    if( prevProps.table &&
        (prevProps.table.death !== this.props.table.death
            || prevProps.table.disability !== this.props.table.disability)) {
      this.calculatePrice('startDate', this.month)
      return true
    }
    return false
  }

  private calculatePrice = (name, value) => {
    const {
      contract,
      simulation: { flexibleBag, topBag, fixedSalary },
    } = this.props
    const isTaxAdvantage = access(contract, 'benefit.isTaxAdvantage')
    let contractBag = isTaxAdvantage ? flexibleBag : topBag
    if (!isTaxAdvantage && !topBag) {
      contractBag = fixedSalary
    }

    let { table } = this.props
    const oldDetails = contract.details
    const updatedDetails = {
      ...contract.details,
      [name]: value,
    }
    if (!this.props.editable) return
    updatedDetails.price = updatedDetails.death
        ? (strToFloat(updatedDetails.disability) * strToFloat(table.disability)) / 1000 +
        (strToFloat(updatedDetails.death) * strToFloat(table.death)) / 1000
        : 0

    contract.updateDetails(updatedDetails)
    if (contract.price > contractBag) {
      contract.updateDetails(oldDetails)
      showNotify('El precio ha excedido la cantidad disponible')
    } else {
      this.props.onChange()
    }
  }

  handleChange = (name: string, value: any) => {
    this.month = value
    if (name === 'startDate') {
      this.props.dispatch(getTable( 'life',{ startMonth: value }))
    }
    this.calculatePrice(name, value)
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.handleChange(e.target.name, e.target.value)
  }

  render() {
    const { contract, editable, table, simulation, t } = this.props
    const { benefit, details } = contract

    const isLimitNotReached = isProductLimitNotReached(contract, simulation)

    return isLimitNotReached ? (
      <React.Fragment>

        <div className="contract-body-alt">
            <div className="form-group row mt-5">
              <label className="col-lg-6">{t('products.death_capital')}</label>
              <div className="col-lg-6">
                <div className="input-group embed">
                  <input
                    type="number"
                    className="form-control"
                    name="death"
                    value={access(contract, 'details.death') || 0}
                    onChange={this.handleInputChange}
                    disabled={!table || !editable}
                  />
                  <span className="input-group-addon">€</span>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-6">{t('products.capital_disability')}</label>
              <div className="col-lg-6">
                <div className="input-group embed">
                  <input
                    type="number"
                    className="form-control"
                    name="disability"
                    value={access(contract, 'details.disability') || 0}
                    onChange={this.handleInputChange}
                    disabled={!table || !editable}
                  />
                  <span className="input-group-addon">€</span>
                </div>
              </div>
            </div>

            <MonthSelect
              availability={benefit.monthsAvailability}
              value={details.startDate}
              onChange={month => {
                this.handleChange('startDate', month)
              }}
              disabled={!editable}
            />


            <div className="d-flex my-3">

                <div className="text-center">
                      <span className="benefit-prima-anual">
                        {t('products.yearly_price')}{' '}
                          {formatCurrency(access(contract, 'details.price'))}
                      </span>
                </div>

              <ContractPrice price={contract.price} discount={contract.userBag}/>


            </div>
        </div>

      </React.Fragment>
    ) : (
      <div className="alert alert-danger" role="alert">
          {t('HEADER.LIMIT')} {t('HEADER.REACHED')}
      </div>
    )
  }
}

export default withTranslation()(LifeInsurance)

import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

interface Props {
  availability: boolean[]
  disabled?: boolean
  value: number
  onChange(value: number): void
}

const MonthSelect = ({ availability, disabled, value, onChange }: Props) => {
  const { i18n } = useTranslation()

  moment.locale(i18n.language)
  const monthNames = moment.months()

  return (
    <select
      className="form-control w-auto"
      value={value === null ? 13 : value}
      onChange={e => {
        onChange(e.target.value === '' ? null : Number(e.target.value))
      }}
      disabled={disabled}
    >
      <option value="13">--Select--</option>
      {monthNames.map(
        (name, month) =>
          availability[month] && (
            <option key={name} value={month}>
              {name}
            </option>
          )
      )}
    </select>
  )
}

MonthSelect.defaultProps = {
  disabled: false,
}

export default MonthSelect

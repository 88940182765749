import React from 'react'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from 'utils/number'
import { isProductLimitNotReached } from 'utils/contracts'
import ContractEdit from 'components/BenefitDetail/ContractEdit'

interface Props {
  contract: Contract
  simulation: Simulation
  onChange: () => void
}

const GeneralProduct = (props: Props) => {
  const { t } = useTranslation()
  const {
    contract,
    simulation,
  } = props

  const isLimitNotReached = isProductLimitNotReached(contract, simulation)

  return (
    <React.Fragment>
      {contract.productCategory === 'food' && (
        <div>
          {t('products.month_text')}
          <span className="ml-2 bold">
            {`(${t('products.max_available')}: ${formatCurrency(
              Math.min(simulation.flexibleBag, contract.benefit.workingPrice)
            )})`}
          </span>
        </div>
      )}
      {!!contract.benefit.minAmount && (
          <div>
            {t('products.min_amount_text')}:
            <span className="ml-2 bold">
              {formatCurrency(contract.benefit.minAmount)}
          </span>
          </div>
      )}

      {contract.productCategory === 'transport' && (
        <h4>{t('products.transport_max_text')}</h4>
      )}
      {isLimitNotReached ? (
        <ContractEdit {...props} />
      ) : (
        <div className="alert alert-danger" role="alert">
          {t('HEADER.LIMIT')} {t('HEADER.REACHED')}
        </div>
      )}
    </React.Fragment>
  )
}

export default GeneralProduct

import UncompletedInfo from '../pages/UncompletedInfo'
import Profile from 'pages/Profile'

const routes = [
  {
    path: '/',
    component: UncompletedInfo,
    exact: true,
  },
  {
    path: '/profile',
    component: Profile,
  },

]

export default routes

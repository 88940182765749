import objectPath from 'object-path'

import Contract from './GeneralContract'
import ContractItem from './ContractItem'

class FoodVoucherContract extends Contract {
  getMonthFaceCount(month: number, face: number): number {
    const item = this.getMonthContract(month)

    return objectPath.get(item, `details.${face}`) || 0
  }

  getMonthFacePrice(month: number, face: number): number {
    return this.getMonthFaceCount(month, face) * this.benefit.getFacePrice(face)
  }

  // set the month face count
  setMonthFaceCount(month: number, face: number, count: number) {
    let item = this.getMonthContract(month)

    if (!item) {
      item = new ContractItem({ month })
      this.items.push(item)
    }

    // update the details
    item.details = item.details || {}
    item.details[face] = count

    // recalculate the month price
    item.price = 0
    Object.keys(item.details).forEach(key => {
      item.price += this.benefit.getFacePrice(Number(key)) * item.details[key]
    })

    // remove items with price 0
    this.items = this.items.filter(item => !!item.price)
    this.updatePrice()
  }

  getExemptionPrice(tax, family: Family[]): number {
    if (!tax) return this.price

    return Math.min(
      tax.limitDay * tax.numberOfMonths * tax.limitExemption,
      this.price
    )
  }
}

export default FoodVoucherContract

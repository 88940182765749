import React from 'react';
import PropTypes from 'prop-types';

import Dropzone from 'components/Dropzone';
import ServerFileLink from 'components/ServerFileLink';

class DropzoneField extends React.Component {
  handleFileUpload = file => {
    const {
      input: { value, onChange },
    } = this.props;

    onChange([...(value || []), file]);
  };

  handleFileRemove = file => {
    const {
      input: { value, onChange },
    } = this.props;

    let files = value || [];
    files = files.filter(item => item !== file);

    onChange(files.length ? files : null);
  };

  render() {
    const {
      input,
      meta: { error, touched },
    } = this.props;
    const files = input.value || [];

    return (
      <div>
        {files.map(file => (
          <div key={file.name} className="my-2">
            <ServerFileLink src={file.name} className="ml-2 link">
              {file.originalFilename}
            </ServerFileLink>
            <span
              role="button"
              className="text-danger"
              title="Remove"
              onClick={() => {
                this.handleFileRemove(file);
              }}
            >
              <i className="entypo-trash" />
            </span>
          </div>
        ))}

        <Dropzone onChange={this.handleFileUpload} />
        {touched && error && <div className="text-danger">Required</div>}
      </div>
    );
  }
}

DropzoneField.propTypes = {
  input: PropTypes.shape({}),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
};

export default DropzoneField;

import React from 'react';

const ExencionSvg: React.FC<{ className?: string }> = ({ className }) => (
  <svg className={className} width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2_201)">
      <path d="M23.9851 19.9554C23.6207 19.9554 23.3456 20.0967 23.1523 20.3941C22.8847 20.7881 22.7509 21.5613 22.7509 22.7138C22.7509 23.8662 22.8847 24.632 23.1523 25.0186C23.3531 25.316 23.6282 25.4647 23.9851 25.4647C24.3419 25.4647 24.6245 25.3234 24.8178 25.0335C25.0854 24.6394 25.2193 23.8662 25.2193 22.7138C25.2193 21.5613 25.0929 20.7881 24.8326 20.3941C24.6319 20.1041 24.3494 19.9554 23.9851 19.9554Z" fill="white" />
      <path d="M12.0372 10.5874C11.6803 10.5874 11.4052 10.7286 11.2045 11.0186C10.9368 11.4126 10.803 12.1859 10.803 13.3383C10.803 14.4907 10.9368 15.2639 11.2045 15.658C11.4052 15.948 11.6803 16.0967 12.0372 16.0967C12.3941 16.0967 12.684 15.9554 12.8848 15.658C13.145 15.2639 13.2714 14.4907 13.2714 13.3383C13.2714 12.1859 13.145 11.4201 12.8848 11.0335C12.684 10.7361 12.4015 10.5874 12.0372 10.5874Z" fill="white" />
      <path d="M18.1114 -4.45057e-05C8.37907 -0.0372192 0.0519277 7.84382 -0.000116932 17.9702C-0.0521615 27.9405 8.05193 36.0892 18.0148 36.104C28.1709 36.1115 36.1114 27.8141 36.0891 18.104C36.1635 8.20813 28.0296 0.0371302 18.1114 -4.45057e-05ZM9.20435 16.8921C8.50546 16.1338 8.15602 14.9516 8.15602 13.3605C8.15602 11.7695 8.50546 10.5948 9.20435 9.83639C9.90323 9.07802 10.8475 8.69141 12.0371 8.69141C13.2267 8.69141 14.2304 9.07059 14.9293 9.83639C15.6281 10.6022 15.9776 11.7769 15.9776 13.368C15.9776 14.9591 15.6281 16.1263 14.9293 16.8921C14.2304 17.6579 13.2861 18.0371 12.0965 18.0371C10.9069 18.0371 9.90323 17.6579 9.20435 16.8921ZM14.6244 27.3754H12.1114L21.5464 8.69141H23.985L14.6244 27.3754ZM26.8772 26.2602C26.1783 27.026 25.2341 27.4052 24.0445 27.4052C22.8549 27.4052 21.8512 27.026 21.1523 26.2602C20.4534 25.4944 20.104 24.3122 20.104 22.7211C20.104 21.1301 20.4534 19.9553 21.1523 19.197C21.8512 18.4312 22.8029 18.052 24.0073 18.052C25.2118 18.052 26.1783 18.4386 26.8772 19.197C27.5761 19.9628 27.9255 21.1375 27.9255 22.7211C27.9255 24.3048 27.5761 25.4944 26.8772 26.2602Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_2_201">
        <rect width="36.0818" height="36.0967" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ExencionSvg;
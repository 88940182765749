import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from 'utils/number'
import { getFamily } from 'store/families/reducer'

type Props = {
  contract: Contract
}

const KindergartenDetail: React.FC<Props> = ({ contract }) => {
  const { i18n } = useTranslation()

  moment.locale(i18n.language)

  const months = moment.months()
  const family = useSelector(getFamily)
  const children = contract
    .getFamilyIds()
    .map(childId => family.find(item => item.id === Number(childId)))

  const childDetail = (child: Family) => {
    const prices = months.map((name, month) => (
      <div className="row">
        <label className="col-5">{name}</label>
        <div className="col-7">
          {formatCurrency(contract.getMonthContractPrice(month, child.id))}
        </div>
      </div>
    ))

    return (
      <React.Fragment key={child.id}>
        <h4 className="mt-4 mb-2">{child.fullName}</h4>
        <div className="row">
          <div className="col-sm-6">{prices.slice(0, 6)}</div>
          <div className="col-sm-6">{prices.slice(6, 12)}</div>
        </div>
      </React.Fragment>
    )
  }

  return <div>{children.map(child => childDetail(child))}</div>
}

export default KindergartenDetail

import React from 'react'
import { useTranslation } from 'react-i18next'

import { isProductLimitNotReached } from 'utils/contracts'
import FamilySelection from '../HealthInsurance/FamilySelection'
import './HoleYearProduct.scss'

interface Props {
  benefits: Benefit[]
  contract: Contract
  simulation: Simulation
  onChange: () => void
  dispatch: (action: any) => Promise<any> | void
}

const HoleYearProduct: React.FC<Props> = (props) => {
  const {
    contract,
    simulation,
  } = props
  const { t } = useTranslation()
  const isLimitNotReached = isProductLimitNotReached(contract, simulation)

  return isLimitNotReached ? (
    <div className="form-wrap">
      <FamilySelection {...props} />
    </div>
  ) : (
    <div className="alert alert-danger" role="alert">
      {t('HEADER.LIMIT')} {t('HEADER.REACHED')}
    </div>
  )
}

export default HoleYearProduct

import { connect } from 'react-redux'

import { selectProfile } from 'store/profile/reducer'
import { getContractItems } from 'store/simulator/reducer'
import CurrentSituation from './CurrentSituation'

const mapStateToProps = state => ({
  contracts: getContractItems(state),
  user: selectProfile(state),
})

export default connect(mapStateToProps)(CurrentSituation)

import React from 'react'
import moment from 'moment'
import access from 'safe-access'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from 'utils/number'

type Props = {
  contract: Contract
}

const LifeInsuranceDetail: React.FC<Props> = ({ contract }) => {
  const { t, i18n } = useTranslation()

  moment.locale(i18n.language)

  return (
    <div className="mt-5">
      <table className="table">
        <thead>
          <tr>
            <th>{t('contract.month')}</th>
            <th>{t('contract.company')}</th>
            <th>{t('contract.capital_death_case')}</th>
            <th>{t('contract.capital_dis_case')}</th>
          </tr>
        </thead>
        <tbody>
          {contract.items.map(item => (
            <tr key={item.id}>
              <td>{moment.months(item.month)}</td>
              <td>Zurich</td>
              <td>{formatCurrency(access(item, 'details.death'))}</td>
              <td>{formatCurrency(access(item, 'details.disability'))}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default LifeInsuranceDetail

import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

import { isProductLimitNotReached } from 'utils/contracts'
import ContractPrice from "components/BenefitDetail/ContractPrice"

interface Props {
  contract: Contract
  editable?: boolean
  simulation: Simulation
  onChange: () => void
}

class Lottery extends React.Component<Props & WithTranslation> {
  static defaultProps = {
    editable: true,
  }

  handleChange = ticketCount => {
    const {
      contract,
      simulation: { flexibleBag, topBag },
      onChange,
    } = this.props
    const { benefit } = contract
    const { isTaxAdvantage } =  benefit
    const maxPossiblePrice = isTaxAdvantage ? flexibleBag : topBag

    if (!this.props.editable) return

    let maxCount = benefit.defaultPrice
      ? Math.min(ticketCount, maxPossiblePrice / (benefit.defaultPrice || ticketCount))
      : 0

    if (!isTaxAdvantage && !topBag) {
      maxCount = ticketCount
    }

    maxCount = Math.floor(maxCount)

    contract.updateDetails({
      ticketPrice: benefit.defaultPrice,
      ticketCount: maxCount,
    })

    onChange()
  }

  render() {
    const { contract, editable, t, simulation } = this.props
    const { details, benefit } = contract

    const isLimitNotReached = isProductLimitNotReached(contract, simulation)

    return isLimitNotReached ? (
      <React.Fragment>
        <div className="mt-4">
          <div className="form-group row">
            <label className="col-lg-6">{t('products.number_tickets')}</label>
            <div className="col-lg-6">
              <input
                  type="number"
                  className="form-control w-auto"
                  disabled={!editable}
                  value={details.ticketCount}
                  onChange={e => {
                    this.handleChange(e.target.value)
                  }}
                  min="0"
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-6">{t('products.price_tickets')}</label>
            <div className="col-lg-6">
              <div className="input-group embed">
                <input
                    type="text"
                    className="form-control"
                    name="ticketPrice"
                    value={benefit.defaultPrice}
                    disabled
                />
                <span className="input-group-addon">€</span>
              </div>
            </div>
          </div>

          <div className="text-center my-5">

            <ContractPrice price={contract.price} discount={contract.userBag}/>

          </div>
        </div>

      </React.Fragment>
    ) : (
      <div className="alert alert-danger" role="alert">
        {t('HEADER.LIMIT')} {t('HEADER.REACHED')}
      </div>
    )
  }
}

export default withTranslation()(Lottery)

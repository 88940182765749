import React from 'react'
import Slider from 'rc-slider'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'

interface Props {
  year: number
  month: number
  maxPrice: number
  value: number
  disabled: boolean
  onChange: (value: number) => void
}

const MonthRow: React.FC<Props> = props => {
  const { year, month, maxPrice, value, disabled, onChange } = props
  const { i18n } = useTranslation()

  moment.locale(i18n.language)

  return (
    <div className="row month-row form-group align-items-center">
      <label className="col-3 mb-0">
        {moment.monthsShort(month)} {`${year}`.slice(-2)}.
      </label>
      <div className="col-5">
        <Slider
          max={maxPrice}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
      <div className="col-4">
        <div className="input-group embed">

          <NumberFormat
              className="form-control"
              disabled={disabled}
              value={value}
              decimalScale={2}
              allowNegative={false}
              displayType={'input'}
              onChange={e => onChange(Number(e.target.value))}
              thousandSeparator={false}
          />
          <span className="input-group-addon">€</span>
        </div>
      </div>
    </div>
  )
}

MonthRow.defaultProps = {
  value: 0,
}

export default MonthRow

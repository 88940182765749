import { replace } from 'connected-react-router'
import { SubmissionError } from 'redux-form'

import { showNotify } from 'utils/notify'
import * as api from 'utils/api'
import * as storage from 'utils/storage'
import { getProfile } from 'store/profile/actions'

export const types = {
  USER_LOGIN: 'USER_LOGIN',
}


export const login = (form, captcha) => dispatch =>{
    const cred = {
        email: form.email,
        password: api.encrypt(form.password)
    }
    return api
        .post('/auth/login', cred, {
            headers: {
                "captcha": captcha
            }
        })
        .then(res => {
            storage.setToken(res.token)

            return getProfile()(dispatch)
        })
        .then(() => {
            dispatch(replace('/'))
        })
        .catch(err => {
            let message
            if (err.status === 401) {
                message = 'Credenciales incorrectas'
            } else {
                message = err.error
            }

            throw new SubmissionError({
                _error: message,
            })
        })
    
}

export const forgotPassword = (values,capcha) => () =>
  api.post('/auth/forgot-password', values, {
      headers: {
          "captcha": capcha
      }
  })

export const resetPassword = (token, values) => dispatch =>
  api.post(`/users/reset-password?access_token=${token}`, values).then(() => {
    dispatch(replace(`/login`))
    showNotify('Se ha actualizado la contraseña')
  })

export const logout = () => {
  storage.deleteToken()
  // eslint-disable-next-line
  document.location.href = `/login`
}

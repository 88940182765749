import React from 'react'
import { useTranslation } from 'react-i18next'

import { getTranslate } from 'helpers/translate'
import ServerImage from 'components/ServerImage'

interface Props {
  provider: Provider
  benefit: Benefit
}

const ProviderInfo = ({ provider, benefit }: Props) => {
  const { t, i18n } = useTranslation()

  return (
    <React.Fragment>
      <div
        style={{ backgroundImage: `url('/img/health-bg.png')` }}
        className="embed-img featured-image w-100"
      />
      <div className="image-card">
        {provider && provider.logo && (
          <div className="image">
            <ServerImage src={provider.logo} />
          </div>
        )}
        <div className="card-benefit">
          <h1>{t('products.health_insurance')}</h1>
          <div className="my-3">
            {benefit &&
              getTranslate(benefit.product, 'description', i18n.language)}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProviderInfo

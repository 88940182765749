import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { getCompanyDocuments } from 'store/document/actions'
import { getCompanyDocuments as documentSelector } from 'store/document/reducer'
import ServerFileLink from 'components/ServerFileLink'
import DocumentForm from "../../../components/DocumentForm/index.js"
import { createUserDocNew } from 'store/app/actions.js'
import { DOCUMENTS_FORM_NAME } from "../../../components/DocumentForm/DocumentForm"
import "./DocumentPage.scss"

/**
 *
 * @constructor
 */
const DocumentsPage = () => {
    const dispatch = useDispatch()
    const documents = useSelector(documentSelector)
    const userId = useSelector(state => state.profile.id)
    const { t } = useTranslation()

    useEffect(() => {
        dispatch(getCompanyDocuments(userId))
    }, [dispatch, userId])

    const [showModal, setShowModal] = React.useState(false)

    function toggleModal() {
        setShowModal(prev => !prev)
    }

    function handleModalSubmit(values) {
        dispatch(createUserDocNew(userId, { ...values, ...values.file }, DOCUMENTS_FORM_NAME))
            .then(() => {
                setShowModal(false)
            })
    }

    return (
        <div>
            <div className="top-container">
                <ul className="nav nav-tabs no-margin">
                    <li className="active">
                        {/* eslint-disable-next-line */}
                        <a className="cp">{t('DOCUMENT.Documents')}</a>
                    </li>
                    <li>
                        <Link className="cp" to="/documents/user">
                            {t('DOCUMENT.DETAIL_ACTION')}
                        </Link>
                    </li>
                </ul>

                <Button
                    className="mb-1"
                    onClick={toggleModal}
                    color="primary"
                >
                    New document
                </Button>
            </div>

            <div className="scrollme-x">
                <table className="table table-dark">
                    <thead>
                    <tr>
                        <th>{t('DOCUMENT.ID')}</th>
                        <th>{t('DOCUMENT.NAME')}</th>
                        <th>{t('DOCUMENT.CREATED_BY')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {documents.length === 0 && (
                        <tr>
                            <td colSpan={3} className="text-center">
                                {t('DOCUMENT.no_documents')}
                            </td>
                        </tr>
                    )}
                    {documents.map((document, index) => (
                        <tr key={document.id}>
                            <td>{index + 1}</td>
                            <td>
                                <ServerFileLink src={document.file}>
                                    {document.name}
                                </ServerFileLink>
                            </td>
                            <td>
                                {document.userId === userId
                                    ? "Me"
                                    : t('DOCUMENT.Admin')}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <DocumentForm
                isOpen={showModal}
                toggle={toggleModal}
                onSubmit={handleModalSubmit}
            />
        </div>
    )
}

export default DocumentsPage

import React from 'react'
import Slider from 'rc-slider'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from 'utils/number'
import './MonthRow.scss'

interface Props {
  benefit: Benefit
  month: number
  maxPrice: number
  item: any
  onChange: Function
  disabled: boolean
}

const MonthRow: React.FC<Props> = props => {
  const { i18n } = useTranslation()

  moment.locale(i18n.language)

  const { benefit, month, maxPrice, item, disabled, onChange } = props
  const year = benefit.year || ''
  const faceValues = []

  for (let face = 1; face <= benefit.numberOfFace; face += 1) {
    const count = item.details[face] || 0

    faceValues.push(
      <div className="row no-gutters month-row" key={face}>
        <div className="col-7 pl-4 pr-2">
          <Slider
            max={
              benefit.getFacePrice(face)
                ? Math.ceil(maxPrice / benefit.getFacePrice(face))
                : 0
            }
            value={count}
            disabled={disabled}
            onChange={v => {
              onChange(month, face, v)
            }}
          />
        </div>
        <div className="col-5 px-2">
          <input
            type="number"
            className="form-control"
            value={count.toString()}
            onChange={e => {
              onChange(month, face, Number(e.target.value))
            }}
            disabled={disabled}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="food-voucher-row mb-3">
      <label>
        {moment.monthsShort(month)} {year}.
      </label>
      {faceValues}
      <div className="total">{formatCurrency(item.price)}</div>
    </div>
  )
}

MonthRow.defaultProps = {
  disabled: false,
  item: {
    price: 0,
    details: {},
  },
}

export default MonthRow

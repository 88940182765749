import * as api from 'utils/api'
import { showNotify, alertError } from 'utils/notify'

export const types = {
  USER_FAMILY: 'USER_FAMILY',
  USER_FAMILY_MEMBER_UPDATE: 'USER_FAMILY_MEMBER_UPDATE',
}

export const getFamily = () => (dispatch, getState) => {
  // check if family already gotten
  const state = getState()
  if (state.families) {
    return Promise.resolve(state.families)
  }

  return api
    .get(`/families`)
    .then(family => {
      dispatch({
        type: types.USER_FAMILY,
        payload: family,
      })

      return family
    })
    .catch(res => {
      alertError(res.error, 'Sorry, family not found', 'error')
    })
}

export const updateFamily = family => dispatch => {
  return api
    .post(`/families`, family)
    .then(res => {
      dispatch({
        type: types.USER_FAMILY,
        payload: res,
      })
      showNotify('Información de familia actualizada')

      return res
    })
    .catch(res => {
      alertError(res.error, 'Sorry, error occured on deleting family', 'error')
    })
}

export const updateFamilyMember = (familyId, data) => dispatch => {
  return api
    .put(`/families/${familyId}`, data)
    .then(res => {
      dispatch({
        type: types.USER_FAMILY_MEMBER_UPDATE,
        payload: res,
      })
      showNotify('Información de miembro de familia actualizada')
    })
    .catch(res => {
      alertError(res.error, 'Ha habido un error en la actualización, disculpa las molestias', 'error')
    })
}

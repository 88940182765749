import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getTranslate } from 'helpers/translate'
import ServerImage from 'components/ServerImage'
import BenefitDescModal from './BenefitDescModal'
import './BenefitInfo.scss'

type Props = {
  benefit: Benefit
}

const BenefitInfo: React.FC<Props> = ({ benefit }) => {
  const { i18n } = useTranslation()
  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  let desc: string
  if (i18n.language === 'es') {
    desc = benefit.spanishDesc
  } else if (i18n.language === 'ca') {
    desc = benefit.catalanDesc
  } else if (i18n.language === 'pt') {
    desc = benefit.portugueseDesc
  } else {
    desc = benefit.desc
  }

  return (
    <div>
      {benefit.featuredImage && (
        <ServerImage
          src={benefit.featuredImage}
          className="featured-image"
          embed
        />
      )}
      <div className="image-card">
        {benefit.logo && (
          <div className="image bag-box">
            <ServerImage src={benefit.logo} />
          </div>
        )}

        <div className="card-benefit">
          <h2 className="mt-0">
            {getTranslate(benefit, 'name', i18n.language)}
            {benefit.providerImage && (
              <ServerImage
                src={benefit.providerImage}
                className="logo-small ml-2"
              />
            )}
            <span
              className="badge btn-help"
              role="button"
              title="Click to see the details"
              onClick={toggleModal}
            >
              ?
            </span>
          </h2>
          <hr className="m-0" />

          <div className="desc" dangerouslySetInnerHTML={{ __html: desc }} />

        </div>
      </div>

      <BenefitDescModal benefit={benefit} isOpen={modal} toggle={toggleModal} />
    </div>
  )
}

export default BenefitInfo

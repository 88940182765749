import toastr from 'toastr'
import access from 'safe-access'

const SHOW_INTERVAL = 3000

export const showNotify = (
  text,
  type = 'success',
  timeOut = SHOW_INTERVAL,
  clearAll = false
) => {
  var opts = {
    closeButton: true,
    debug: false,
    onclick: null,
    showDuration: '300',
    hideDuration: '1000',
    //positionClass: 'toast-top-center',
    timeOut,
  }
  if (clearAll) {
    toastr.remove()
  }

  if (type === 'info') {
    toastr.info(text, 'Información', opts);
  } else if (type === 'success') {
    toastr.success(text, 'Éxito', opts);
  } else if (type === 'warning') {
    toastr.warning(text, 'Advertencia', opts);
  } else if (type === 'error') {
    toastr.error(text, 'Error', opts);
  }
}

export const alertError = (err, text) => {
  showNotify(
    access(err, 'message') || access(err, 'error.message') || text,
    'error'
  )
}

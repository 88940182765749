import * as api from 'utils/api'
import { alertError } from 'utils/notify'

export const types = {
  COMPANY_INFO: 'COMPANY_INFO',
  COMPANY_LOGINSCREEN: 'COMPANY_LOGINSCREEN',
  COMPANY_BENEFITS: 'COMPANY_BENEFITS',
  COMPANY_MESSAGES: 'COMPANY_MESSAGES',
  COMPANY_DOCUMENTS: 'COMPANY_DOCUMENTS',
  OFFICE_GET: 'OFFICE_GET',
  LEGAL_ENTITY_GET: 'LEGAL_ENTITY_GET',
  COLLECTIVE_GET: 'COLLECTIVE_GET',
  TABLE_GET: 'TABLE_GET',
}

export const getCompanyInfo = () => dispatch => {
  return api.get(`/companies/by-subdomain`).then(res => {
    dispatch({
      type: types.COMPANY_INFO,
      payload: res,
    })
  })
}

export const getBenefitsByCategory = (companyId, category) => dispatch => {
  return api.get(`/companies/${companyId}/categories/${category}`).then(res => {
    dispatch({
      type: types.COMPANY_BENEFITS,
      payload: res,
    })
  })
}

export const getMessages = () => dispatch => {
  return api.get(`/messages`).then(res => {
    dispatch({
      type: types.COMPANY_MESSAGES,
      payload: res,
    })
  })
}

export const getTable = (name, params) => dispatch => {
  const p = {
    ...params,
    name
  }
  return api.get(`/profile/tables`, p).then(
      res => {
        dispatch({
          type: types.TABLE_GET,
          payload: res,
        })
      },
      err => {
        alertError(err, 'Tax not found')
      }
  )

}


import * as api from 'utils/api'
import { showNotify, alertError } from 'utils/notify'

export const types = {
  CONTRACT_GET: 'CONTRACT_GET',
  CONTRACT_ADD: 'CONTRACT_ADD',
  CONTRACT_UPDATE: 'CONTRACT_UPDATE',
  CONTRACT_REMOVE: 'CONTRACT_REMOVE',
}

export const updateContract = (contractId, contract: Contract) => dispatch => {
  console.log("PUT Contract", contract) //todo remove
  return api.put(`/contracts/${contractId}`, contract.toJSON()).then(
    res => {
      if (res.status === 'cart') {
        dispatch({
          type: types.CONTRACT_REMOVE,
          payload: contractId,
        })

        dispatch({
          type: 'CART_ADD',
          payload: {
            ...res,
            benefit: contract.benefit,
          },
        })
      } else {
        dispatch({
          type: types.CONTRACT_UPDATE,
          payload: res,
        })
      }

      showNotify(
        'Revisa tu correo para confirmar los cambios, hasta entonces seguirás con las condiciones anteriores'
      )
    },
    err => {
      alertError(err.error)
    }
  )
}

import { connect } from 'react-redux'

import { selectProfile } from 'store/profile/reducer'
import { getFamily } from 'store/families/reducer'
import Kindergarten from './Kindergarten'

const mapStateToProps = state => ({
  user: selectProfile(state),
  family: getFamily(state, 'child'),
})

export default connect(mapStateToProps)(Kindergarten)

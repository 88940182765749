import * as api from 'utils/api'

export const types = {
  COMPENSATION_USER: 'COMPENSATION_USER',
}

export const getCompensations = () => dispatch => {
  return api.get(`/compensations`).then(res => {
    dispatch({
      type: types.COMPENSATION_USER,
      payload: res,
    })
  })
}

import * as api from 'utils/api'
import * as appActions from 'store/app/actions'

export const types = {
    MENU_ITEMS: 'MENU_ITEMS',
}
export const getMenu = () => dispatch => {
  dispatch(appActions.showSpinner('MENU_ITEMS'))

  return api
    .get('/menu')
    .then(menu => {
      dispatch({
        type: types.MENU_ITEMS,
        payload: menu,
      })
    })
    .finally(() => {
      dispatch(appActions.hideSpinner('MENU_ITEMS'))
    })
}

import Contract from './GeneralContract'
import { uniqBy } from 'lodash'

class KindergartenContract extends Contract {

  addContractItem(contractItem: ContractItem) {
    super.addContractItem(contractItem)

    const childrenIds = this.getFamilyIds()
    const addInfoFamilyIds = Object.keys(this.additionalInfo)
    addInfoFamilyIds.forEach(id => {
      // remove the additional info
      if (childrenIds.indexOf(Number(id)) === -1) {
        delete this.additionalInfo[id]
      }
    })
  }

  getChildrenNumber(): number {
    return uniqBy((this.items || []),'familyId').length
  }

  getExemptionPrice(tax, family: Family[]): number {
    if (!tax) return this.price

    const exemptionPrice =
      tax.exemption === 'total'
        ? tax.limitExemption
        : tax.limitExemption * this.getChildrenNumber()

    return Math.min(exemptionPrice, this.price)
  }

  isMissingDocument(): boolean {
    if(this.disableDocumentation) return false
    const childrenIds = this.getFamilyIds()
    const addInfoFamilyIds = Object.keys(this.additionalInfo)

    return childrenIds.length !== addInfoFamilyIds.length
  }
}

export default KindergartenContract

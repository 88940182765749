import React, { useState, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import access from 'safe-access'
import { formatCurrency } from 'utils/number'
import { getFamily } from 'store/families/reducer'
import BenefitSimple from 'components/benefits/BenefitSimple'
import FamilySummary from 'components/profile/FamilySummary'
import GeneralProduct from 'pages/Products/GeneralProduct'
import FoodVoucherInfo from 'pages/Products/FoodVoucherInfo'
import Gym from 'pages/Products/Gym'
import Kindergarten from 'pages/Products/Kindergarten'
import LifeInsurance from 'pages/Products/LifeInsurance'
import LifeInsuranceAxa from 'pages/Products/LifeInsuranceAxa'
import HoleYearProduct from 'pages/Products/HoleYearProduct'
import Lottery from 'pages/Products/Lottery'
import Renting from 'pages/Products/Renting'
import MonthEditDialog from '../MonthEditDialog'
import AdditionalInfoColumn from '../AdditionalInfoColumn'
import FormationlInfoColumn from '../FormationlInfoColumn'

/**
 *
 */
interface Props {
  cartItem: Contract
  onUpdate(updated: Contract): void
  onInfoUpdate(info: any): void
  onDelete(): void
  user: User
  expirationNode: ReactNode
}

/**
 *
 * @param props
 * @constructor
 */
const OtherProductRow: React.FC<Props> = ({
  onUpdate,
  onDelete,
  cartItem,
  cartItem: {
    benefit,
    product,
  },
  expirationNode,
  onInfoUpdate,
  user
}) => {
  const family = useSelector(getFamily)
  const [isEdit, setEdit] = useState(false)

  /**
   *
   */
  const toggleEdit = () => {
    setEdit(!isEdit)
  }

  /**
   *
   * @param updated
   */
  const handleUpdate = updated => {
    onUpdate(updated)
    toggleEdit()
  }

  /**
   *
   * @param contractMethod
   */
  const getCustomPage = (contractMethod) => {
    if (contractMethod === 'tickets') {
      return Lottery
    } else if (contractMethod === 'hole-year') {
      return HoleYearProduct
    } else {
      return GeneralProduct
    }
  }

  /**
   *
   * @param category
   * @param benefit
   */
  const getStandardPage = (category, benefit) => {
    switch(category) {
      case "food":
        return benefit.numberOfFace
            ? FoodVoucherInfo
            : GeneralProduct

      case "gym":
        return Gym

      case "kindergarten":
        return Kindergarten

      case "life-insurance":
        return LifeInsurance

      case "life-insurance-axa":
        return LifeInsuranceAxa

      case "renting":
        return Renting

      case "lottery":
        return Lottery

      default:
        return GeneralProduct
    }
  }

  /**
   *
   */
  const getEditComponent = () => {
    const isDefault = access(benefit, 'product.category.isDefault')
    const contractMethod = access(benefit, 'contractMethod')


    if (isDefault) {
      return getStandardPage(product, benefit)
    }

    if (contractMethod) {
      return getCustomPage(contractMethod)
    }
  }

  const AddInfoComponent = product === 'formation'
        ? FormationlInfoColumn
        : AdditionalInfoColumn

  return (
    <React.Fragment>
      <tr>
        <td className="col-benefit text-center">
          <BenefitSimple benefit={benefit} />
        </td>

        <td>
          <FamilySummary family={family[0]} />
        </td>

        <td className="text-center">
          <AddInfoComponent
            employeeName={`${family[0].firstName} ${family[0].lastName1 || ''} ${family[0].lastName2 || ''}`}
            benefit={benefit}
            contract={cartItem}
            additionalInfo={cartItem.additionalInfo}
            onUpdate={onInfoUpdate}
            user={user}
          />
        </td>

        <td className="text-center">
          {expirationNode}
        </td>

        <td className="text-center">
          {formatCurrency(cartItem.finalPrice)}
        </td>

        <td className="col-action">
          <span
            role="button"
            className="text-success mr-3"
            title="Edit"
            onClick={toggleEdit}
          >
            <i className="entypo-pencil" />
          </span>

          <span
            role="button"
            className="text-black"
            title="Remove"
            onClick={onDelete}
          >
            <i className="fa fa-trash"></i> {' '}
          </span>
        </td>
      </tr>

      {isEdit && (
        <MonthEditDialog
          cartItem={cartItem}
          toggle={toggleEdit}
          EditComponent={getEditComponent()}
          onUpdate={handleUpdate}
        />
      )}
    </React.Fragment>
  )
}

export default OtherProductRow

import React from 'react';

const LogoSvg: React.FC<{ className?: string }> = ({ className }) => (
  <svg className={className} width="241" height="54" viewBox="0 0 241 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2_181)">
      <path d="M17.0092 11.6133C15.2005 11.6133 13.5049 11.9524 11.9223 12.6382C10.3397 13.324 8.86256 14.3414 7.49851 15.7054V12.3895H0V53.4919H7.49098V39.3013C8.96807 40.703 10.4527 41.7128 11.9449 42.3308C13.437 42.9488 15.0498 43.2578 16.7831 43.2578C20.6718 43.2578 24.0405 41.7505 26.8741 38.736C29.7152 35.7216 31.132 31.9761 31.132 27.492C31.132 23.008 29.7604 19.0364 27.0097 16.0672C24.259 13.0979 20.9205 11.6133 16.9941 11.6133H17.0092ZM21.2521 33.8224C19.677 35.503 17.808 36.3395 15.4266 36.3395C13.0452 36.3395 11.0782 35.5181 9.51068 33.8677C7.94315 32.2172 7.15938 30.0016 7.15938 27.379C7.15938 24.7564 7.94315 22.6387 9.51068 21.0185C11.0782 19.3982 13.0527 18.5843 15.4266 18.5843C17.8005 18.5843 19.7373 19.4057 21.2897 21.0561C22.8422 22.7066 23.6109 24.8016 23.6109 27.4016C23.6109 30.0016 22.8196 32.1419 21.2445 33.8149L21.2521 33.8224Z" fill="white" />
      <path d="M34.2897 12.3895V42.4815H41.8485V12.3895H34.2973H34.2897Z" fill="white" />
      <path d="M38.0729 0C36.7465 0 35.6161 0.467244 34.6816 1.40927C33.7471 2.35129 33.2723 3.42897 33.2723 4.80809C33.2723 6.18722 33.7471 7.3704 34.6891 8.33503C35.6387 9.29966 36.8143 9.78952 38.1256 9.78952C39.4369 9.78952 40.5523 9.31474 41.4868 8.36518C42.4288 7.41562 42.8961 6.28519 42.8961 4.92114C42.8961 3.55708 42.4213 2.39651 41.4793 1.43941C40.5297 0.482316 39.3993 0 38.0729 0Z" fill="white" />
      <path d="M63.4473 11.6133C61.6386 11.6133 59.9429 11.9524 58.3603 12.6382C56.7777 13.324 55.3006 14.3414 53.9366 15.7054V0.776245H46.4456V42.4815H53.9366V39.3013C55.4137 40.703 56.8983 41.7129 58.3905 42.3308C59.8827 42.9488 61.4954 43.2578 63.2287 43.2578C67.1174 43.2578 70.4861 41.7505 73.3197 38.7361C76.1608 35.7216 77.5776 31.9761 77.5776 27.4921C77.5776 23.008 76.206 19.0364 73.4553 16.0672C70.7046 13.0979 67.3661 11.6133 63.4397 11.6133H63.4473ZM67.6977 33.8225C66.1226 35.503 64.1783 36.3396 61.8722 36.3396C59.5661 36.3396 57.5238 35.5181 55.9563 33.8677C54.3888 32.2173 53.605 30.0016 53.605 27.379C53.605 24.7564 54.3888 22.6387 55.9563 21.0185C57.5238 19.3906 59.4983 18.5843 61.8722 18.5843C64.2461 18.5843 66.1829 19.4057 67.7354 21.0561C69.2878 22.7066 70.0565 24.8016 70.0565 27.4016C70.0565 30.0016 69.2652 32.1419 67.6901 33.8149L67.6977 33.8225Z" fill="white" />
      <path d="M106.524 22.3222V29.4288H118.039C116.939 31.7726 115.492 33.4908 113.699 34.5911C111.905 35.6914 109.697 36.2416 107.082 36.2416C103.057 36.2416 99.6737 34.9076 96.938 32.2398C94.2024 29.572 92.8233 26.1958 92.8233 22.4805C92.8233 18.7651 94.1421 15.615 96.7873 13.0225C99.4325 10.4301 102.711 9.12633 106.622 9.12633C111.114 9.12633 115.048 10.9124 118.416 14.477L123.744 9.20923C121.31 6.57909 118.823 4.72519 116.283 3.64751C113.269 2.36636 110.036 1.72578 106.577 1.72578C99.9676 1.72578 94.5943 4.0394 90.4644 8.67416C86.9902 12.5704 85.2494 17.1448 85.2494 22.4051C85.2494 26.241 86.1914 29.8207 88.0755 33.1366C89.9595 36.4526 92.5218 39.0073 95.7548 40.801C98.9879 42.5946 102.673 43.4914 106.795 43.4914C110.654 43.4914 114.008 42.6699 116.879 41.0346C119.743 39.3917 122.079 37.0178 123.88 33.8978C125.681 30.7778 126.585 27.4845 126.585 24.0028L126.533 22.3222H106.532H106.524Z" fill="white" />
      <path d="M143.007 11.6133C141.628 11.6133 140.354 11.9976 139.193 12.7739C138.033 13.5501 137.098 14.6805 136.398 16.1727V12.382H129.932V42.474H137.422V30.303L137.392 28.841C137.392 24.6358 137.792 21.8173 138.598 20.3779C139.397 18.9385 140.384 18.2225 141.545 18.2225C142.118 18.2225 142.841 18.4411 143.73 18.8857L146.082 12.3895C145.011 11.877 143.987 11.6133 143.014 11.6133H143.007Z" fill="white" />
      <path d="M176.505 27.4091C176.505 24.5906 175.804 21.9605 174.403 19.5263C173.001 17.0921 171.057 15.1704 168.555 13.746C166.053 12.3292 163.385 11.6208 160.551 11.6208C156.625 11.6208 153.151 12.9849 150.129 15.713C146.677 18.848 144.959 22.7668 144.959 27.4694C144.959 31.863 146.481 35.5859 149.533 38.6607C152.585 41.7279 156.263 43.2653 160.581 43.2653C163.513 43.2653 166.196 42.572 168.63 41.1778C171.064 39.7836 172.986 37.8543 174.395 35.3824C175.804 32.9106 176.513 30.2352 176.513 27.4167L176.505 27.4091ZM166.55 33.7697C164.975 35.4126 163.015 36.2265 160.649 36.2265C158.283 36.2265 156.331 35.4126 154.786 33.7923C153.241 32.172 152.472 29.979 152.472 27.4317C152.472 24.8845 153.249 22.797 154.816 21.1692C156.376 19.5413 158.328 18.7199 160.672 18.7199C163.076 18.7199 165.043 19.5263 166.588 21.139C168.133 22.7518 168.901 24.8468 168.901 27.4317C168.901 30.0167 168.117 32.1268 166.55 33.7621V33.7697Z" fill="white" />
      <path d="M184.426 41.0421C186.461 42.5192 189.128 43.2578 192.429 43.2578C195.466 43.2578 197.976 42.6247 199.95 41.3662C201.925 40.1001 203.417 38.2914 204.434 35.9326C205.263 33.9958 205.678 30.401 205.678 25.1483V12.397H198.127V27.1378C198.127 29.979 197.968 31.8253 197.659 32.692C197.237 33.8526 196.582 34.7343 195.707 35.3297C194.826 35.925 193.763 36.2265 192.505 36.2265C191.246 36.2265 190.191 35.925 189.332 35.3146C188.473 34.7042 187.847 33.8526 187.463 32.7523C187.071 31.652 186.883 29.6926 186.883 26.8741V12.382H179.248V24.9071C179.248 29.7227 179.542 33.0387 180.13 34.8624C180.959 37.5001 182.391 39.5575 184.433 41.027L184.426 41.0421Z" fill="white" />
      <path d="M226.644 43.2578C230.532 43.2578 233.901 41.7505 236.735 38.736C239.576 35.7216 240.992 32.1419 240.992 27.492C240.992 22.8422 239.621 19.0364 236.87 16.0672C234.127 13.0979 230.788 11.6133 226.862 11.6133C225.053 11.6133 223.358 11.9524 221.775 12.6382C220.193 13.324 218.715 14.3414 217.351 15.7054V12.3895H209.853V53.4919H217.344V39.3013C218.821 40.703 220.306 41.7128 221.798 42.3308C223.29 42.9488 224.903 43.2578 226.636 43.2578H226.644ZM217.027 27.379C217.027 24.7639 217.811 22.6387 219.379 21.0185C220.946 19.3982 222.921 18.5843 225.295 18.5843C227.653 18.5843 229.605 19.4057 231.158 21.0561C232.703 22.7066 233.479 24.8016 233.479 27.4016C233.479 30.0016 232.688 32.1419 231.113 33.8149C229.537 35.4955 227.668 36.332 225.287 36.332C222.906 36.332 220.939 35.5106 219.371 33.8601C217.804 32.2097 217.02 29.9941 217.02 27.3715L217.027 27.379Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_2_181">
        <rect width="241" height="53.4919" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default LogoSvg;
import { connect } from 'react-redux'

import { getFamily } from 'store/families/reducer'
import { getSimulation } from 'store/simulator/reducer'
import { selectProfile } from 'store/profile/reducer'
import FamilySelection from './FamilySelection'

const mapStateToProps = state => ({
  user: selectProfile(state),
  family: getFamily(state),
  simulation: getSimulation(state),
})

export default connect(mapStateToProps)(FamilySelection)

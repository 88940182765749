import React, { FC } from "react"
import InputField from "../forms/InputField"
import { required } from "redux-form-validators"
import { Field } from "redux-form"

interface Props {
    title: string,
    isRequired: boolean
}

/**
 *
 * @param title
 * @param isRequired
 * @constructor
 */

const titleToId = (title) =>{
    if(!title) return title
    return title.replaceAll('.','_')
}
const Question: FC<Props> = ({ title, isRequired }) => {
    return (
        <div className='d-flex flex-column align-items-start mb-4'>
            <label className={`control-label ${isRequired ? 'required' : ''}`}>
                {`${title}: `}
            </label>

            <Field
                type='text'
                component={InputField}
                name={`answer[${titleToId(title)}]`}
                validate={isRequired ? required() : undefined}
            />
        </div>
    )
}

export default Question

import React, { useState } from 'react'
import { reduxForm, Field } from 'redux-form'
import { required, email } from 'redux-form-validators'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PasswordValidator from 'password-validator'
import { updateProfile } from 'store/profile/actions'
import InputField from 'components/forms/InputField'
import './UserInformation.scss'


type Props = {
  user: User
  handleSubmit: any
}

const UserInformation: React.FC<Props> = ({ user, handleSubmit }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const onSubmit = values => {
      if(values && values.password) {
          const repeatValid = values.password === values.repeat_password
          setRepeatPasswordValid(repeatValid)
          if(!repeatValid) return
          const valid = checkPassword(values.password)
          setPasswordValid(valid)
          if(!valid) return
      }
      dispatch(updateProfile(values))
  }
  const [isPasswordValid, setPasswordValid] = useState(true)
  const [isReapeatPasswordValid, setRepeatPasswordValid] = useState(true)


    const checkPassword = (password) => {
        const schema = new PasswordValidator()
        schema
            .is().min(8)                               // Minimum length 8
            .is().max(100)                             // Maximum length 100
            .has().uppercase()                              // Must have uppercase letters
            .has().lowercase()                              // Must have lowercase letters
            .has().digits(1)                         // Must have at least 2 digits
            .has().not().spaces()                           // Should not have spaces
            .is().not().oneOf([])                       // Blacklist these values
        return !!schema.validate(password)
    }

  return (
    <form autoComplete="off" className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>

        {user.isPasswordDeprecated &&
            <div className="alert alert-danger" role="alert">
                {t('profile.password_deprecated')}
            </div>
        }

        <div className="form-group row">
        <label className="col-sm-3 control-label required">
          {t('profile.email')}
        </label>
        <div className="col-sm-5">
          <Field
            type="text"
            component={InputField}
            name="email"
            validate={[required(), email()]}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-3 control-label required">
          {t('profile.first_name')}
        </label>
        <div className="col-sm-5">
          <Field
            type="text"
            component={InputField}
            name="firstName"
            validate={required()}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-3 control-label required">
          {t('profile.last_name')}
        </label>
        <div className="col-sm-5">
          <Field
            type="text"
            component={InputField}
            name="lastName1"
            validate={required()}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-3 control-label">
          {t('profile.last_name_2')}
        </label>
        <div className="col-sm-5">
          <Field type="text" component={InputField} name="lastName2" />
        </div>
      </div>

      {/*trick to make autoComplete = off work on chrome */}
      <input type="password" className="input-field-hiden" autoComplete="new-password" id="__hiden_field_password" name="__hiden_field_password"></input>

      <div className="form-group row">
        <label className="col-sm-3 control-label">
          {t('profile.password')}
        </label>
        <div className="col-sm-5">
          <Field type="password" autoComplete="new-password" component={InputField} name="password" />
            {!isPasswordValid &&
                <small className="password-incorrect">{t('profile.incorrect_password')}</small>
            }

        </div>
      </div>

        <div className="form-group row">
            <label className="col-sm-3 control-label">
                {t('profile.repeat_password')}
            </label>
            <div className="col-sm-5">
                <Field type="password" autoComplete="new-password" component={InputField} name="repeat_password" />
                {!isReapeatPasswordValid &&
                    <small className="password-incorrect">{t('profile.incorrect_repeat_password')}</small>
                }
            </div>
        </div>

      <div className="form-group row">
        <div className="col-sm-offset-3 col-sm-5">
          <button type="submit" className="btn btn-none">
            <i className="fa fa-folder-open-o mr-2"></i>
            {t('buttons.update')}
          </button>
        </div>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'UserInformation',
})(UserInformation)

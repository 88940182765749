import React from 'react'
import { Link } from 'react-router-dom'
import { WithTranslation } from 'react-i18next'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import moment from 'moment'
import access from 'safe-access'
import { orderBy } from 'lodash'
import { getFamily } from 'store/families/actions'
import { generateContract } from 'models/Contract'
import * as api from 'utils/api'

import { getTranslate } from 'helpers/translate'
import Details from './Details'
import './UserDocumentsPage.scss'

type Props = {
  user: User,
  dispatch(action: any): any
}

type States = {
  modal: boolean
  contracts: Contract []
  selectedContract: Contract
}

class UserDocumentsPage extends React.Component<Props & WithTranslation, States> {

  state = { modal: false, contracts: [], selectedContract: null }


  constructor(props) {
    super(props)
    this.props.dispatch(getFamily())
  }

  componentDidMount(){
    /**
     * Retrieves all contracts for user, including old
     */
    return api.get(`my-contracts`)
        .then(res => {
          const contracts =  orderBy(res.map(generateContract),["year"],["desc"])
          this.setState({ contracts })
        })
  }

  componentWillUnmount(){
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return
    }
  }


  showDetail = (contract: Contract) => {
    this.setState({
      selectedContract: contract,
      modal: true,
    })
  }

  toggleModal = () => {
    this.setState({
      modal: false,
    })
  }

  getStatus(contract: Contract) {

    const { t } = this.props

    if (contract.status === 'pending') {
      return (
        <label className="status pending-review">
          {t('HOME.PENDING_REVIEW')}
        </label>
      )
    } else if (!contract.signed) {
      return (
        <label className="status pending-review">
          {t('HOME.PENDING_SIGNATURE')}
        </label>
      )
    } else {
      return <label className="status contracted">{t('HOME.CONTRACTED')}</label>
    }
  }

  getCategoryName(contract, language) {
    const category = access(contract, 'benefit.product.category')
    if (category) {
      return getTranslate(category, 'name', language)
    }
    return contract.productCategory
  }
  
  render() {
    const { t, i18n } = this.props
    const { contracts, selectedContract, modal } = this.state


    return (
      <div className="user-documents">
        <ul className="nav nav-tabs no-margin">
          <li>
            <Link className="cp" to="/documents">
              {t('DOCUMENT.Documents')}
            </Link>
          </li>
          <li className="active">
            <Link className="cp" to="/documents/user">
              {t('DOCUMENT.DETAIL_ACTION')}
            </Link>
          </li>
        </ul>

        <div className="scrollme-x">
          <table className="table table-dark">
            <colgroup>
              <col width="70px" />
              <col width="auto" />
              <col width="auto" />
              <col width="auto" />
              <col width="14%" />
              <col width="14%" />
              <col width="150px" />
            </colgroup>
            <thead>
              <tr>
                <th>{t('DOCUMENT.ID')}</th>
                <th>{t('HOME.PRODUCT')}</th>
                <th>{t('DOCUMENT.YEAR')}</th>
                <th>{t('cart.modality')}</th>
                <th>{t('HOME.Status')}</th>
                <th>{t('DOCUMENT.CREATED_AT')}</th>
                <th>{t('DOCUMENT.LAST_MODIFICATION')}</th>
                <th>{t('DOCUMENT.DETAIL')}</th>
              </tr>
            </thead>
            <tbody>
              {contracts.length === 0 && (
                <tr>
                  <td colSpan={8} className="text-center">
                    {t('DOCUMENT.no_products')}
                  </td>
                </tr>
              )}
              {contracts.map((contract, index) => (
                <tr key={contract["id"]}>
                  <td>{index + 1}</td>
                  <td>{this.getCategoryName(contract, i18n.language)}</td>
                  <td>{contract.year}</td>
                  <td>
                    {getTranslate(contract["benefit"], 'name', i18n.language)}
                  </td>
                  <td>{this.getStatus(contract)}</td>
                  <td>{moment(contract["createdAt"]).format('YYYY-MM-DD')}</td>
                  <td>{moment(contract["updatedAt"]).format('YYYY-MM-DD')}</td>
                  <td>
                    <span
                      className="btn-detail"
                      onClick={() => {
                        this.showDetail(contract)
                      }}
                    >
                      <i className="fa fa-file-text-o" />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {modal && (
          <Modal
            className="cart-edit-modal"
            isOpen={true}
            toggle={this.toggleModal}
          >
            <ModalHeader toggle={this.toggleModal} />
            <ModalBody>
              <div className="heading4 mb-2">
                {this.getCategoryName(selectedContract, i18n.language)}
              </div>
              <Details contract={selectedContract} />
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-default"
                onClick={this.toggleModal}
              >
                {t('buttons.close')}
              </button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    )
  }
}

export default UserDocumentsPage

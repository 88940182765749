import { connect } from 'react-redux'

import { selectProfile } from 'store/profile/reducer'
import { getContracts } from 'store/contracts/reducer'
import Alerts from './Alerts'

const mapStateToProps = state => ({
  contracts: getContracts(state),
  user: selectProfile(state),
})

export default connect(mapStateToProps)(Alerts)

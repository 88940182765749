import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

interface Props {
  company: CompanyInfo
  user: User
}

class UnderMaintenance extends React.Component<Props & WithTranslation> {

  render() {

    return (
        <div className="card" style={{backgroundColor: "black"}}>
          <div className="card-body">
            <h1 className="card-title" style={{backgroundColor: "white"}}>En mantenimiento</h1>
            <h4 className="card-subtitle mb-2 text-muted">Disculpa las molestias</h4>
            <p className="card-text">
                Estamos preparando la renovación de tu plan de retribución flexible.
              </p>
            <p className="card-text">
                  Te comunicaremos en breve el día que volverá a estar disponible para renovar y/o contratar.
              </p>
              <p>Gracias por tu colaboración.</p>
          </div>
        </div>
    )
  }
}

export default withTranslation()(UnderMaintenance)

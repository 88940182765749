export default class ContractItem {
  id: number
  benefitId?: number

  familyId?: number

  month: number

  price: number

  contributedPrice: number

  details?: any

  family?: Family

  constructor(props) {
    this.id = props.id || null
    this.benefitId = props.benefitId || null
    this.familyId = props.familyId || 0
    this.month = props.month
    this.price = props.price || 0
    this.contributedPrice = props.contributedPrice || 0
    this.details = props.details || null
    this.family = props.family || null
  }
}

import { connect } from 'react-redux'

import { getCart } from 'store/cart/reducer'
import { getCalculation, getSimulation } from 'store/simulator/reducer'
import Header from './Header'

const mapStateToProps = state => ({
  cart: getCart(state),
  simulation: getSimulation(state),
  calculation: getCalculation(state),
})

export default connect(mapStateToProps)(Header)

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import MediaQuery from 'react-responsive'

import { updateProfile } from 'store/profile/actions'
import { updateFamilyMember } from 'store/families/actions'
import FamilyEditDlg from './FamilyEditDlg'


type Props = {
  family: Family
  hideRelationship?: boolean
  validate?(values: any): boolean
}

const FamilySummary: React.FC<Props> = ({
  family,
  hideRelationship,
  validate,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isEdit, setEdit] = useState(false)

  const handleSubmit = values => {
    if (validate && !validate(values)) return false

    if (values.id) {
      dispatch(updateFamilyMember(values.id, values)).then(toggleEdit)
    } else {
      dispatch(
        updateProfile({
          ...values,
          id: undefined,
        })
      ).then(toggleEdit)
    }
  }

  const toggleEdit = () => {
    setEdit(!isEdit)
  }

  return (
    <div className="d-flex align-items-center">
      <div>
        <div className="mb-2">
          <label>{t('profile.first_name')}:</label> {family.firstName}
        </div>
        <div className="mb-2">
          <label>{t('profile.last_name')}:</label> {family.lastName1}
        </div>
        <div className="mb-2">
          <label>{t('profile.last_name_2')}:</label> {family.lastName2}
        </div>
        <div>
          <label>{t('profile.birthday')}:</label>{' '}
          {family.birthday && moment(family.birthday).format('YYYY-MM-DD')}
        </div>
          <MediaQuery maxWidth={768}>
                  <button type="button" className="btn btn-default" onClick={toggleEdit}>
                      <i className="entypo-pencil" />
                  </button>
          </MediaQuery>
      </div>
        <MediaQuery minWidth={768}>
          <div className="ml-2">
            <button type="button" className="btn btn-default" onClick={toggleEdit}>
              <i className="entypo-pencil" />
            </button>
          </div>
        </MediaQuery>

      {isEdit && (
        <FamilyEditDlg
          hideRelationship={hideRelationship || !family.id}
          initialValues={family}
          toggle={toggleEdit}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  )
}

export default FamilySummary

import { connect } from 'react-redux'

import { selectProfile } from 'store/profile/reducer'
import Gym from './Gym'

const mapStateToProps = state => ({
  user: selectProfile(state),
})

export default connect(mapStateToProps)(Gym)

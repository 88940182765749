import { types } from './actions'

const initialState = {
    compensations: []
}

export default (appState = initialState, { type, payload }) => {
  switch (type) {
    case types.COMPENSATION_USER:
      return {
        ...appState,
        compensations: payload,
      }
    default:
      return appState
  }
}
export const getCompensations = state => state.compensation

export const types = {
  SIMULATOR_ADD: 'SIMULATOR_ADD',
  SIMULATOR_UPDATE: 'SIMULATOR_UPDATE',
  SIMULATOR_UPDATE_ID: 'SIMULATOR_UPDATE_ID',
  SIMULATOR_REMOVE: 'SIMULATOR_REMOVE',
}

export const addItem = item => {
  return {
    type: types.SIMULATOR_ADD,
    payload: item,
  }
}

export const updateItem = (item) => {
  return {
  type: types.SIMULATOR_UPDATE,
  payload: item,
}}

export const updateItemId = (oldId, newId) => ({
  type: types.SIMULATOR_UPDATE_ID,
  payload: {
    oldId,
    newId,
  },
})

export const removeItem = id => ({
  type: types.SIMULATOR_REMOVE,
  payload: id,
})

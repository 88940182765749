import React from 'react'
import access from 'safe-access'

import GeneralDetail from './GeneralDetail'
import HealthInsuranceDetail from './HealthInsuranceDetail'
import KindergartenDetail from './KindergartenDetail'
import LotteryDetail from './LotteryDetail'
import LifeInsuranceDetail from './LifeInsuranceDetail'
import LifeInsuranceAxaDetail from './LifeInsuranceAxaDetail'

const getCustomDetails = (contractMethod) => {
  if (contractMethod === 'tickets') {
    return LotteryDetail
  } else if (contractMethod === 'hole-year') {
    return HealthInsuranceDetail
  } else {
    return GeneralDetail
  }
}

const getStandartDetails = (contract) => {
  if (contract.productCategory === 'health-insurance') {
    return HealthInsuranceDetail
  } else if (contract.productCategory === 'kindergarten') {
    return KindergartenDetail
  } else if (contract.productCategory === 'life-insurance') {
    return LifeInsuranceDetail
  } else if (contract.productCategory === 'life-insurance-axa') {
    return LifeInsuranceAxaDetail
  } else if (contract.productCategory === 'lottery') {
    return LotteryDetail
  } else {
    return GeneralDetail
  }
}

const Details = ({ contract }) => {
  const isCategoryDefault = access(contract, 'benefit.product.category.isDefault')
  const contractMethod = access(contract, 'benefit.contractMethod')

  let ProductDetail

  if (!isCategoryDefault && contractMethod) {
    ProductDetail = getCustomDetails(contractMethod)
  } else {
    ProductDetail = getStandartDetails(contract)
  }

  return <ProductDetail contract={contract} />
}

export default Details

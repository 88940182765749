import Benefit from 'models/Benefit'
import { types } from './actions'

const initialState = []

export default (appState = initialState, { type, payload }) => {
  switch (type) {
    case types.BENEFITS:
      return payload.res.map(item => new Benefit(item))
    default:
      return appState
  }
}

export const getBenefits = state => state.benefit || []

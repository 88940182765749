import * as api from 'utils/api'
import { showSpinner, hideSpinner } from 'store/app/actions'

export const types = {
  BENEFITS: 'BENEFITS',
}

export const getBenefitsByCategory = category => dispatch => {
  showSpinner()
  return api
    .get(`/modalities/by-category/${category}`)
    .then(res => {
      dispatch({
        type: types.BENEFITS,
        payload: {
          category,
          res,
        },
      })
    })
    .finally(() => {
      hideSpinner()
    })
}

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PieChart from 'react-minimal-pie-chart'
import { getCompensations } from 'store/compensation/actions'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'

import './CompensationPage.scss'

interface Props {
  compensations: any []
  user: User
}

const MyCompensation = (props: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { compensations, user } = props
  const { company } = user


  useEffect(() => {
    dispatch(getCompensations())
  }, [dispatch])

  const countBenefits = (compensations) => {
    return compensations.reduce((sum, compensation) => sum + compensation.value, 0)
  }

  const countSecurityCost = (user) => {
    let salary = user.fixedSalary + user.variableSalary
    if (salary > 50000) {
      salary =50000
    }
    return (salary * 0.3)
  }



  const countTotalCompanyCost = (user, hideSs) => {
    const fix =  user.fixedSalary + user.variableSalary + countBenefits(compensations)
    const ss = hideSs ? 0 : countSecurityCost(user)
    return (fix + ss).toFixed(2)
  }


  const hideSocialSecurity = company && company['hideSocialSecurityInCompensation'] === true

  const chartData = [
    { value: props.user.fixedSalary, color: '#44546A' },
    { value: props.user.variableSalary,  color: '#9DC3E6' },
    { value: countBenefits(compensations), color: '#B4C7E7' }
  ]

  if(!hideSocialSecurity){
    chartData.push({ value: countSecurityCost(props.user), color: '#8497B0' })
  }
  



  return (
      <div>
        <div className='card'>
          <div className='card-header p-0 pl-3'><h3>{t('MENU.COMPENSATION')}</h3></div>
          <div className="card-body">
            <div className={'col-sm-6'}>
              <div className={'card'}>
                <div className="card-body">
                  <div className=""><label>{t('profile.fixed_salary')}</label>
                    <NumberFormat
                        className="pull-right"
                        value={props.user.fixedSalary}
                        decimalScale={2}
                        displayType={'text'}
                        suffix={' €'}
                        thousandSeparator={true}
                    />

                  </div>
                  <div className=""><label>{t("profile.variable_salary")}</label>

                    <NumberFormat
                        className="pull-right"
                        value={props.user.variableSalary}
                        decimalScale={2}
                        displayType={'text'}
                        suffix={' €'}
                        thousandSeparator={true}
                    />

                  </div>
                </div>
              </div>
              <div className={'card'}>
                <div className={'card-header'}><span>{t("COMPENSATION.Benefits")}</span>
                </div>
                <div className="card-body">
                  {
                    compensations.map(comp => comp &&
                        <React.Fragment key={comp.product}>
                          <div>
                            <label>{comp.product}</label>
                            <NumberFormat
                                className="pull-right"
                                value={comp.value}
                                decimalScale={2}
                                displayType={'text'}
                                suffix={' €'}
                                thousandSeparator={true}
                            />
                          </div>
                        </React.Fragment>
                    )
                  }

                  <div className="text-black pb-1"><label>Total</label>

                    <NumberFormat
                        className="pull-right"
                        value={countBenefits(compensations).toFixed(2)}
                        decimalScale={2}
                        displayType={'text'}
                        suffix={' €'}
                        thousandSeparator={true}
                    />

                  </div>
                </div>

              </div>
              <div className={'card'}>
                <div className="card-body">
                  {!hideSocialSecurity && <div className="text-black pb-1"><label>{t("COMPENSATION.TotalSecurityCost")}</label>
                    <NumberFormat
                        className="pull-right"
                        value={countSecurityCost(props.user).toFixed(2)}
                        decimalScale={2}
                        displayType={'text'}
                        suffix={' €'}
                        thousandSeparator={true}
                    />
                  </div>}
                  <div className="text-black pb-1"><label>{t("COMPENSATION.TotalCompensation")}</label>

                    <NumberFormat
                        className="pull-right"
                        value={countTotalCompanyCost(props.user, hideSocialSecurity)}
                        decimalScale={2}
                        displayType={'text'}
                        suffix={' €'}
                        thousandSeparator={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={'col-lg-3 col-md4 chart col-sm-6'}>
              <div className={'card'}>
                <div className="card-body">
                  <div><PieChart
                      style={{
                        height: '250px'
                      }}
                      data={chartData}
                  /></div>
                  <div>
                    <div className="d-flex flex-row bd-highlight flex-wrap mt-2">
                      <div className="p-2 bd-highlight"><span className="badge badge-primary label-fixed-salary">{t("profile.fixed_salary")}</span></div>
                      <div className="p-2 bd-highlight"><span className="badge badge-primary label-variable-salary">{t("profile.variable_salary")}</span></div>
                      { !hideSocialSecurity && <div className="p-2 bd-highlight"><span className="badge badge-primary label-ss">{t("COMPENSATION.TotalSecurityCost")}</span></div> }
                      <div className="p-2 bd-highlight"><span className="badge badge-primary label-benefits">{t("COMPENSATION.Benefits")}</span></div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
  )
}

export default MyCompensation

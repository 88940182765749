import { uniqBy } from 'lodash'

import { generateContract } from 'models/Contract'
import Calculator from 'models/Calculator'
import { types } from './actions'
import { getCart } from '../cart/reducer'
import { strAmountIsZero } from '../../utils/contracts'
import { getContracts } from '../contracts/reducer'
import { getFamily } from '../families/reducer'
import { selectProfile } from 'store/profile/reducer'

const initialState = []

export default (appState = initialState, { type, payload }) => {
  switch (type) {
    case types.SIMULATOR_ADD:
      return [...appState, payload]

    case types.SIMULATOR_UPDATE:
      if(appState.map(item => item.id).indexOf(payload.id) < 0)  {
        return [...appState, payload]
      }else {
        return appState.map(item => {
              return item.id === payload.id
                  ? payload
                  : item
            }
        )
      }

    case types.SIMULATOR_UPDATE_ID:
      return appState.map(item =>
        item.id === payload.oldId
          ? generateContract({
              ...item,
              id: payload.newId,
            })
          : item
      )

    case types.SIMULATOR_REMOVE:
      return appState.filter(item => item.id !== payload)

    default:
      return appState
  }
}

export const getContractItems = state => {
  const cart = getCart(state)
  const contracts = getContracts(state)

  return [...cart, ...contracts]
}

export const getSimulatorItems = state => {
  const cart = getContractItems(state)
  const virtualItems = state.simulator
  const items = uniqBy([...virtualItems, ...cart], 'benefitId')

  return items
}

export const getTicketsPrice = (contractDetails) => {
  if (contractDetails && contractDetails.ticketPrice && contractDetails.ticketCount) {
    return contractDetails.ticketPrice * contractDetails.ticketCount
  }
  return 0
}

// returns the total price
export const getSimulation = state => {
  let { flexibleBag, companyBag, topBag, userBag, fixedSalary, privateUse, renting } = selectProfile(state)
  const items = getSimulatorItems(state)

  userBag = userBag || 0
  let advantagePrice = 0
  let userBagSpent = 0
  let otherPrice = 0
  let contributionById = {}
  let pricesById = {}
  let userBagById = {}

  items.forEach(item => {
    if(item.status === 'cancelled') return
    userBagSpent += parseFloat(item.userBag) || 0
    if(item.benefit){
      pricesById[item.benefit['id']] = item.price
      contributionById[item.benefit['id']] = item.contributionCompany
      userBagById[item.benefit['id']] = item.userBag || 0
    }
    const ticketsPrice = getTicketsPrice(item.details)
    if (item.benefit.isTaxAdvantage) {
      advantagePrice += item.contributedPrice || ticketsPrice
    } else {
      otherPrice += item.contributedPrice || ticketsPrice
    }
  })

  let available = flexibleBag
  
  let netPrice = advantagePrice - userBagSpent

  if (strAmountIsZero(companyBag)) {
    available -= netPrice
  } else if (!companyBag.endsWith('%')) {
    available +=
      Math.max(0, parseInt(companyBag, 10) - otherPrice) - netPrice
  } else {
    available -= netPrice * (1 - parseInt(companyBag, 10) / 100)
  }

  const availableTop = topBag - otherPrice

  const availableUserBag = Math.max(0, userBag -  userBagSpent)


  return {
    fixedSalary,
    flexibleBag,
    topBag,
    userBag,
    userBagById,
    availableUserBag,
    companyBag,
    pricesById,
    contributionById,
    advantagePrice,
    privateUse,
    renting,
    totalPrice: Math.max(0, advantagePrice + otherPrice - userBagSpent),
    available: Math.min(flexibleBag, available),
    availableTop: topBag ? Math.min(topBag, availableTop) : null
  }
}

export const getCalculation = state => {
  const user = selectProfile(state)
  const children = getFamily(state, 'child')
  const family = getFamily(state)
  const simulatorItems = getSimulatorItems(state)
  const calculator = new Calculator(user, children, family)

  let { withFlex, withoutFlex } = calculator.calculate(simulatorItems)
  const saving = withFlex.netAvailableSalary - withoutFlex.netAvailableSalary

  // get max available
  const { flexibleBag } = user

  withoutFlex = calculator.generate(flexibleBag, 0,{}, 0, true)
  withFlex = calculator.generate(flexibleBag, 0,{}, 0)
  const maxSaving = withFlex.netAvailableSalary - withoutFlex.netAvailableSalary

  return {
    saving,
    pending: maxSaving - saving,
  }
}

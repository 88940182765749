export default {
  MENU: {
    HOME: 'Inicio',
    DOCUMENT: 'Documentación',
    PROFILE: 'Información personal',
    ADVANTAGE_PRODUCTS: 'Productos con ventaja fiscal',
    OTHER_PRODUCTS: 'Otros productos',
    LEGAL: 'Aviso legal',
    PRIVACY: 'Política de privacidad',
    COMPENSATION: 'Mi compensación',
    COOKIE_MESSAGE: 'Esta página web usa cookies para mejorar la experiencia',
    FLEXIBLE: 'Retribución Flexible',
    DOCUMENTATION: 'Documentación',
    PERSONAL: 'Información Personal',
    MY_COMPENATION: 'Mi compensación',
    EXPENSE_MANAGEMENT: 'Gestión de gastos',
    CORPORATE_SOCIAL_RESPONSIBILITY: 'Responsabilidad Social Corporativa',
  },
  HEADER: {
    SAVED: 'Ahorrados',
    PENDING_SAVING: 'Posibles de ahorrar',
    LIMIT: 'Límite máximo',
    REACHED: 'Alcanzado',
    LANGUAGE: 'Idioma',
    CHECKOUT: 'Tramitar pedido',
    LOGOUT: 'Cerrar sesión',
    HELP: 'Ayuda',
    CONTACT: 'Contáctanos',
  },
  HOME: {
    MISSING_PROFILE_INFO: 'Información de usuario incompleta: Por favor, revise que tiene rellenado el email/teléfono en su perfil',
    CURRENT_SITUATION: 'Situación',
    NEWS: 'Noticias',
    PRODUCTS_WITH_TAX: 'Productos Con ventaja fiscal',
    ALERTS: 'Alertas',
    CONTRACTED_PRODUCTS: 'Productos contratados',
    PENDING_SIGNATURE: 'Pendiente de firmar',
    PENDING_PRODUCTS: 'Pendientes de contratar',
    SAVING: 'Ahorrados',
    PENDING_SAVING: 'Pendientes ahorrar',
    NON_SIMULATED_PRODUCT: 'No simulado',
    PENDING_SIMULATIONS: 'Simulaciones Pendientes',
    NO_ACTIVE_ALERTS: 'No tiene alertas',
    PRODUCT: 'Producto',
    Status: 'Estado',
    END_DATE: 'Fecha final',
    Expiring: 'Fecha de finalización',
    Go: 'Ir',
    MISSING_DOCUMENT: 'Falta documentación',
    ATTACH_INVOICE: 'Adjuntar factura mes',
    PENDING_CART: 'Pendiente de contratar',
    PENDING_REVIEW: 'Pendiente de revisión',
    CONTRACTED: 'Contratado',
    CANCELLED: 'Cancelado',
  },
  LIFE_AXA: {
    FALLCC: 'Fallecimiento por cualquier causa',
    FALLA: 'Fallecimiento por accidente',
    FALLAC: 'Fallecimiento por accidente de circulación',
    IPAA: 'Incapacidad Permanente Absoluta por accidente',
    IPAAC: 'Incapacidad Permanente Absoluta por accidente de circulación',
    IPACC: 'Incapacidad Permanente Absoluta por cualquier causa',
    GINV: 'Gran Invalidez',
    MANDATORY: 'Obligatorio',
    MANDATORY_2: 'obligatorio para contratar',
    AGE_LIMIT: 'No disponible para tu edad',
    CANNOT_EXCEED: 'no puede exceder',
    CANNOT_LESS_FALLA: 'No podra ser inferior a 35.000 €'
  },
  COMPENSATION:{
    Benefits:"Beneficios Sociales",
    TotalCompensation: "Total Compensación",
    TotalSecurityCost: "Coste de la Seguridad Social"
  },
  DOCUMENT: {
    Documents: 'Documentos',
    DETAIL_ACTION: 'Detalle por producto',
    DETAIL: 'Detalle',
    ID: 'Id',
    YEAR: 'Año',
    NAME: 'Nombre',
    CREATED_BY: 'Creado por',
    CREATED_AT: 'Creado el',
    LAST_MODIFICATION: 'Última modificación',
    Admin: 'Administrador',
    no_documents: 'No hay documentos cargados',
    no_products: 'No tiene productos contratados',
  },
  profile: {
    user_tab: 'Información del usuario',
    employee_tab: 'Información personal',
    family_tab: 'Información familiar',
    office: 'Oficina',
    incorrect_password: 'La contraseña no cumple con las medidas minímas de seguridad: Debe tener un mínimo de 8 carácters, contener numeros y letras, al menos una letra mayúscula/minúscula y sin espacios.',
    incorrect_repeat_password: 'La contraseña no coincide',
    password_deprecated: 'La contraseña ha caducado. Por favor, introduce nueva contraseña',
    collective: 'Colectivo',
    legal_entity: 'Entidad legal',
    email: 'Email',
    password: 'Contraseña',
    repeat_password: 'Repetir contraseña',
    id: 'DNI',
    phone: 'Teléfono móvil',
    bank: 'Número de cuenta bancaria',
    nationality: 'Nacionalidad',
    birthday: 'Fecha de nacimiento',
    Gender: 'Sexo',
    Male: 'Hombre',
    Female: 'Mujer',
    disability: 'Discapacidad',
    No: 'No',
    Yes: 'Si',
    marital_status: 'Estado civil',
    Single: 'Soltero',
    Married: 'Casado',
    Divorced: 'Divorciado',
    Widowed: 'Viudo',
    Address1: 'Dirección 1',
    Address2: 'Dirección 2',
    City: 'Localidad',
    Country: 'País',
    Province: 'Provincia',
    postal_code: 'Código postal',
    fixed_salary: 'Salario Fijo',
    flexible_bag: 'Bolsa Flexible',
    top_bag: 'Bolsa productos top',
    variable_salary: 'Salario Variable',
    number_payments: 'Número de pagas',
    social_security: 'Número de seguridad social',
    employee_number: 'Número de empleado',
    cost_center: 'Centro de coste',
    renting: 'Renting',
    private_use: '% uso privativo',
    relationship: 'Parentesco',
    spouse: 'Cónyuge',
    child: 'Hijo/a',
    domestic_partner: 'Pareja de hecho',
    name: 'Nombre',
    surname: 'Apellido',
    first_name: 'Nombre',
    last_name: 'Apellido',
    last_name_2: 'Segundo Apellido',
    address: 'Dirección',
  },
  product_names: {
    'health-insurance': 'Salud',
    transport: 'Transporte',
    food: 'Comida',
    kindergarten: 'Guardería',
    formation: 'Formación',
    renting: 'Renting',
    'life-insurance': 'Vida',
    'saving-plan': 'Ahorro',
    gym: 'Gimnasio',
    lottery: 'lotería',
    informative: 'Informativo',
  },
  buttons: {
    accept_lower: 'Aceptar',
    consent_lower: 'Entendido',
    accept: 'ACEPTAR',
    close: 'CERRAR',
    add_cart: 'AÑADIR AL CARRITO',
    update_cart: 'ACTUALIZAR CARRITO',
    remove_cart: 'ELIMINAR DEL CARRITO',
    update_contract: 'ACTUALIZAR CONTRATO',
    reset: 'RESET',
    see_details: 'Aceptar la cláusula de privacidad',
    sign: 'FIRMAR',
    back: 'ATRAS',
    update: 'ACTUALIZAR',
    cancel: 'CANCELAR',
    download: 'DESCARGAR',
    click_here: 'CLICK AQUÍ',
    back_simulation: 'VOLVER A SIMULAR',
    next_step: 'CONTRATAR',
    add_child: 'AÑADIR HIJO',
    add_family: 'AÑADIR FAMILIAR',
    select: 'SELECCIONAR',
    delete: 'ELIMINAR',
    add_member: 'AÑADIR MIEMBRO',
    reset_password: 'Restablecer contraseña',
    forgot_password: 'Has olvidado la contraseña?',
    back_to_login: 'Volver a inicio',
    change_language: 'cambiar',
  },
  calculator: {
    header: 'SIMULADOR AHORRO IRPF',
    increase_dis_income: 'Incremento neto disponible',
    equi_salary_increase: 'Incremento salario equivalente',
    not_available:
      'Calculadora no disponible por: Información de salario incompleta',
    edit_profile: 'Editar información de salario en el perfil de los usuarios.',
    fixed_salary: 'Salario fijo',
    variable_salary: 'Salario variable',
    flexible_products: 'Productos flexibles',
    without_remuneration: 'Sin plan de retribución flexible',
    with_remuneration: 'Con plan de retribución flexible',
    non_flexible_products: 'Otros productos',
    total_gross_salary: 'Total salario bruto',
    social_security: 'Seguridad social',
    pit: 'IRPF',
    withholding_account: 'Retención a cuenta',
    net_salary: 'Salario neto',
    net_available_salary: 'Salario neto disponible',
    employee_bag: 'Bolsa flexible',
    employee_top_bag: 'Bolsa productos top',
    employee_user_bag: 'Bolsa usuario',
    company_bag: 'Bolsa empresa',
    contribution_company: 'Contrib. empresa',
    available: 'Disponible',
    spent: 'Gastado',
  },
  products: {
    health_insurance: 'Seguro de Salud',
    month_text: 'Introduzca la cantidad disponible mensual',
    min_amount_text: 'Importe mínimo mensual para contratar',
    max_available: 'Máximo disponible mensual',
    transport_max_text: 'El limite de gasto son 1500€ al año',
    start_date: 'Fecha de inicio',
    privative_use: 'Uso privativo',
    year: 'Año',
    years: 'Años',
    vehicle_value: 'Valor del vehículo',
    with_no_tax: 'Cuota sin IVA',
    with_tax: 'Cuota con IVA',
    number_tickets: 'Número de tickets',
    price_tickets: 'Precio por ticket',
    death_capital: 'Capital por fallecimiento',
    capital_disability: 'Capital por invalidez (opcional)',
    yearly_price: 'Prima anual',
    select_children:
      'Por favor, seleccione los niños que le gustaría añadir para guardería',
    no_children: 'No tiene hijos menores de 3 años',
    person: 'Persona',
    monthly: 'Mensual',
    yearly: 'Anual',
    main_benefit_error: 'Por favor, compruebe antes el principal beneficio',
    benefit_error: 'Debe seleccionar el beneficio',
    price_over_error: 'Supera la cantidad disponible',
  },
  contracts: {
    month: 'Mes',
    company: 'Compañía',
    capital_death_case: 'Capital por fallecimiento',
    capital_dis_case: 'Capital por invalidez',
    pending_text:
      'El producto esta siendo revisado. Le informaremos cuando esté contratado',
    contract_text: 'Ya ha contratado el beneficio',
    zero_message: 'La cantidad total es 0',
    min_unreached: 'No se ha alcanzado la cantidad mínima mensual',
    reset_message: 'La configuración ha sido reseteada',
    update_confirm_message:
      'Vas a cambiar las condiciones de un contrato ya existente, Estás seguro?” En caso afirmativo, revisa tu correo para completar el proceso',
    select_start_month:"selecione el mes de inicio",
    inactive_product:"No está permitido contratar este producto. Comuníquese con el administrador para la activación.",
  },
  cart: {
    data_confirm: 'Confirmación de datos',
    sign_contract: 'Firma de contrato',
    step_company: 'Selección de Compañía',
    step_product: 'Selección de Producto',
    step_family: 'Selección de Familia',
    empty_cart: 'Su carrito está vacío',
    agree_message:
      'Al hacer clic en siguiente paso, usted acepta contratar automáticamente',
    terms: `Firma y <b><u>recibirás un correo electrónico con el contrato de novación 
            adjunto</u></b> y la relación de productos seleccionados que deberás aceptar.
              <br />
              <br />
              Para poder finalizar tu proceso de contratación, <b><u>es necesario que abras
              y aceptes las condiciones del correo</u></b>. Recuerda hacerlo antes del día&nbsp;
              {{cutOffDate}} para disponer del producto el 1 del mes siguiente.`,
    pending: 'PENDIENTE',
    completed: 'COMPLETADO',
    download_doc_text: 'Por favor descargue el documento, rellenelo y fírmelo',
    edit_family: 'Editar información del beneficiario',
    name: 'Nombre',
    surname: 'Apellido',
    address: 'Dirección',
    zip: 'Código postal',
    contact_person: 'Persona de contacto',
    contact_phone: 'Teléfono de contacto',
    center_name: 'Nombre del centro',
    course: 'Curso',
    phone_contact: 'Teléfono de contacto',
    attach_invoice: 'Por favor, adjunte la factura',
    same_kindergarten: 'Es la misma guardería que',
    code_center: 'Código de la guardería',
    dontknow: 'Si no lo sabe, haga click aquí',
    edit_profile: 'Editar perfil',
    modality_info_details: 'Información detallada',
    modality: 'Modalidad',
    beneficiary_info: 'Información de beneficiario',
    additional_info: 'Información adicional',
    total: 'Total',
    total_anual: 'Total Anual',
    total_spent: 'TOTAL GASTADO',
    flexible_bag: 'Bolsa flexible',
    top_bag: 'Bolsa productos top',
    company_bag: 'Bolsa empresa',
    health_provider_noselect:
      'no se puede seleccionar este proveedor porque el usuario ya contrató a otro proveedor',
    no_info: 'No se requiere información',
    expired_products: 'Tiene productos en el carrito con fechas inferiores a la fecha límite de la empresa, elimínelos para poder contratarlos: ',
    expired_warning: '¡CADUCADO! Por favor, elimínalo',
    time_left: 'Tiempo restante',
    days: 'dia(s)',
    remove_products: 'Retire estos productos del carrito antes de contratar',
    no_permission: 'Ya no tienes permiso para contratar este producto',
    payment_frequency: 'Frecuencia de pago'
  },
  prices:{
    price: 'Precio',
    discount: 'Desc.',
    total: 'Total'
  }

}

import moment from 'moment'
import { sumBy, get } from 'lodash'

class Calculator {
  user: User
  children: Family[]
  family: Family[]

  constructor(user, children, family) {
    this.user = user
    this.children = children
    this.family = family
  }

  getIRPF = (table, value) => {
    let total = 0
    table.forEach(item => {
      if (value < item.min) return
      const range = Math.min(item.max, value) - item.min
      total += (range * item.percent) / 100
    })
    return total
  }

  generate = (
      advantageProducts,
      otherProducts,
      contracts,
      holdingAccountPrice: number,
      withoutFlex?: boolean
  ) => {
    const { user, children } = this
    const { employeeTax } = user
    const data: any = {}

    data.fixedSalary = user.fixedSalary
    data.variableSalary = user.variableSalary
    data.advantageProducts = advantageProducts

    const totalSalary = user.fixedSalary + user.variableSalary

    // gross salary
    data.grossSalary =
        user.fixedSalary +
        user.variableSalary -
        (withoutFlex ? 0 : advantageProducts)

    // social security
    data.socialSecurity = (totalSalary * employeeTax.socialSecurity.socialSecurity) / 100

    if (employeeTax.socialSecurity.limitSalary && employeeTax.socialSecurity.limitValue){
      if(totalSalary >= employeeTax.socialSecurity.limitSalary){
        data.socialSecurity = employeeTax.socialSecurity.limitValue
      }
    }

    // net performance
    data.netPerformance =
        data.grossSalary -
        data.socialSecurity -
        employeeTax.socialSecurity.redGeneral

    // calculate IRPF1
    data.IRPF1 = this.getIRPF(employeeTax.retentionScale, data.netPerformance)

    // calculate IRPF2
    let childrenUnder25 = 0
    let childrenUnder3 = 0
    children.forEach(child => {
      const age = moment().diff(child.birthday, 'years')

      if (age < 25) {
        childrenUnder25 += 1
      }
      if (age < 3) {
        childrenUnder3 += 1
      }
    })

    let deduction = employeeTax.socialSecurity.minGeneral
    deduction += employeeTax.childrenDeduction[childrenUnder25] || 0
    deduction += employeeTax.babyDeduction[childrenUnder3] || 0
    data.IRPF2 = this.getIRPF(employeeTax.retentionScale, deduction)

    data.PIT = data.IRPF1 - data.IRPF2

    // calculate withholding account
    data.surpassed = holdingAccountPrice / data.grossSalary
    data.PIT = data.PIT * (1 - data.surpassed)
    //data.holdingOnAccount = holdingAccountPrice * data.surpassed
    data.holdingOnAccount = holdingAccountPrice * (data.PIT / data.grossSalary)

    // net salary
    data.netSalary = data.grossSalary - data.socialSecurity - data.PIT - data.holdingOnAccount

    data.otherProducts = otherProducts

    data.nonFlexibleProducts = (data.otherProducts ? data.otherProducts : 0)


    data.flexibleProducts = withoutFlex ? Math.min(-contracts.totalWithoutFlex, -data.advantageProducts)+ data.nonFlexibleProducts : 0

    // available salary
    data.netAvailableSalary = data.netSalary - otherProducts - (!withoutFlex ? 0 : advantageProducts)
    data.displayNetAvailableSalary =
        data.netSalary - Math.abs(data.flexibleProducts) - Math.abs(data.nonFlexibleProducts)

    return data
  }

  calculate(simulatorItems) {
    const { user, family } = this
    let companyBagValue = user.companyBag ? parseInt(user.companyBag, 10) : 0
    const isCompanyBagPercent = user.companyBag && user.companyBag.endsWith('%')

    const advantageProducts = {}
    const otherProducts = {}
    const contracts ={}
    const contractsWithoutFlex ={}

    let holdingAccountPrice = 0
    simulatorItems.forEach(item => {
      const productTax = user.productTax[item.productName]

      const exemptionPrice = item.getExemptionPrice(productTax, family)

      const isLimitExemptionZero = get(productTax,'limitExemption',null) === 0

      const isTaxAdvantage = item.benefit && item.benefit.isTaxAdvantage && !isLimitExemptionZero

      if(isTaxAdvantage) {
        holdingAccountPrice += item.price - exemptionPrice
      }

      let price = item.contributedPrice
      //let price = Math.min(item.contributedPrice, exemptionPrice)



      if (contracts[item.productCategory]){
        contracts[item.productCategory].price += item.finalPrice
      } else {
        contracts[item.productCategory] = { price: item.finalPrice }
      }

      if (contractsWithoutFlex[item.productCategory]){
        contractsWithoutFlex[item.productCategory].price += item.price
      } else {
        contractsWithoutFlex[item.productCategory] = { price: item.price }
      }

      if (isCompanyBagPercent) {
        price *= 1 - companyBagValue / 100
      } else {
        const deduct = Math.min(price, companyBagValue)
        price -= deduct
        companyBagValue -= deduct
      }
      let finalPrice = Math.max(0, price - item.userBag)

      // add price
      if (isTaxAdvantage) {
        advantageProducts[item.productCategory] =
            (advantageProducts[item.productCategory] || 0) + finalPrice
      } else {
        otherProducts[item.productCategory] =
            (otherProducts[item.productCategory] || 0) + price
      }
      contracts[item.productCategory]['exemption'] = advantageProducts[item.productCategory]

    })

    const advantageProductsTotal = sumBy(Object.values(advantageProducts))
    const otherProductsTotal = sumBy(Object.values(otherProducts))
    contracts['totalExemption'] = sumBy(Object.values(contracts), (item) => item['exemption'])
    contracts['total'] = sumBy(simulatorItems, (item) => item['finalPrice'])
    contracts['totalWithoutFlex'] = sumBy(simulatorItems, (item) => item['price'])

    const withoutFlex = this.generate(
      advantageProductsTotal,
      otherProductsTotal,
      contracts,
      0,
      true
    )
    const withFlex = this.generate(
      advantageProductsTotal,
      otherProductsTotal,
      contracts,
      holdingAccountPrice
    )

    return {
      withFlex,
      withoutFlex,
      advantageProducts,
      otherProducts,
      contracts,
      contractsWithoutFlex
    }
  }
}

export default Calculator

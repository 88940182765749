import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { required } from 'redux-form-validators'
import { useTranslation } from 'react-i18next'

import InputField from 'components/forms/InputField'
import ProvinceSelect from 'components/forms/ProvinceSelect'

interface Props {
  providerWebsite: string
  child: Family
  children: Family[]
  isOpen: boolean
  handleSubmit(onSubmit: any): any
  onFetchChild(e: React.ChangeEvent<HTMLSelectElement>): void
  toggle(): void
}

const KindergartenAddInfo: React.FC<Props> = props => {
  const { t } = useTranslation()
  const {
    isOpen,
    toggle,
    providerWebsite,
    children,
    child,
    onFetchChild,
    handleSubmit,
  } = props

  return (
    <Modal className="cart-edit-modal" isOpen={isOpen} toggle={toggle}>
      <form onSubmit={handleSubmit}>
        <ModalHeader toggle={toggle} />
        <ModalBody>
          <div className="heading4 mb-2">{child.fullName}</div>
          <div className="mb-4">
            {t('cart.same_kindergarten')}
            <select
              className="form-control w-auto d-inline-block ml-2"
              onChange={onFetchChild}
            >
              <option value="">--Choose--</option>
              {Object.values(children).map(
                item =>
                  item.id !== child.id && (
                    <option key={item.id} value={item.id}>
                      {item.fullName}
                    </option>
                  )
              )}
            </select>
            ?
          </div>
          <div className="form-group row">
            <div className="col-md-6">
              <label className="required">{t('cart.code_center')}</label>
              <Field
                type="text"
                component={InputField}
                name="codeCenter"
                validate={required()}
              />
              {providerWebsite && (
                <div>
                  {t('cart.dontknow')}&nbsp;
                  <a
                    href={providerWebsite}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('buttons.click_here')}
                  </a>
                </div>
              )}
            </div>
            <div className="col-md-6">
              <label className="required">{t('cart.center_name')}</label>
              <Field
                type="text"
                component={InputField}
                name="centerName"
                validate={required()}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-6">
              <label className="required">{t('cart.address')}</label>
              <Field
                type="text"
                component={InputField}
                name="address"
                validate={required()}
              />
            </div>
            <div className="col-md-6">
              <label>{t('cart.zip')}</label>
              <Field type="text" component={InputField} name="zip" />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-6">
              <label>{t('profile.City')}</label>
              <Field type="text" component={InputField} name="city" />
            </div>
            <div className="col-md-6">
              <label>{t('profile.Province')}</label>
              <Field component={ProvinceSelect} name="province" />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-6">
              <label>{t('cart.contact_person')}</label>
              <Field type="text" component={InputField} name="contactPerson" />
            </div>
            <div className="col-md-6">
              <label>{t('cart.contact_phone')}</label>
              <Field type="text" component={InputField} name="contactPhone" />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="submit" className="btn btn-success">
            {t('buttons.update')}
          </button>{' '}
          <button type="button" className="btn btn-default" onClick={toggle}>
            {t('buttons.cancel')}
          </button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default reduxForm({
  form: 'KindergartenAddInfo',
})(KindergartenAddInfo)

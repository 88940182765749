export enum ProductCategory {
  HealthInsurance = 'health-insurance',
  Food = 'food',
  Kindergarten = 'kindergarten',
  Formation = 'formation',
  Transport = 'transport',
  LifeInsurance = 'life-insurance',
  LifeInsuranceAxa = 'life-insurance-axa',
  Renting = 'renting',
  SavingPlan = 'saving-plan',
  Gym = 'gym',
  Lottery = 'lottery',
}

export enum ProductPL {
  Exent = 'Exent',
  NoExent = 'Top',
}

export enum QuestionareType {
  Physical = 'physical',
  Telematic = 'telematic',
  Telephone = 'telephone',
  UploadDirectly = 'upload',
}

export enum Relationship {
  Self = 'self',
  Spouse = 'spouse',
  Child = 'child',
}

import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import app from './app/reducer'
import login from './auth/reducer'
import company from './company/reducer'
import benefit from './benefit/reducer'
import families from './families/reducer'
import cart from './cart/reducer'
import contracts from './contracts/reducer'
import simulator from './simulator/reducer'
import document from './document/reducer'
import profile from './profile/reducer'
import compensation from './compensation/reducer'
import menu from './menu/reducer'


const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    app,
    form,
    login,
    menu,
    company,
    benefit,
    families,
    cart,
    contracts,
    simulator,
    document,
    profile,
    compensation,
  })

export default rootReducer

export const getGrowth = (a, b) => {
  if (!b) return 0;
  const growth = Math.round(((a - b) / Math.abs(b)) * 100);

  return growth;
};

export const makeFixNumber = (a) => {
  if (!a || isNaN(a)) return a;

  return Math.round(a);
};

export const getRoundNumber = (a) => {
  if (!a || isNaN(a)) return a;

  return Math.round(a).toLocaleString('en');
};

export const round = (value, decimals) => {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals)
}

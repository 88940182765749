import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getTranslate } from 'helpers/translate'
import { formatCurrency } from 'utils/number'
import { getFamily } from 'store/families/reducer'
import ServerFileLink from 'components/ServerFileLink'
import './ProductsStep.scss'

interface Props {
  benefits: Benefit[]
  onSubmit(benefit: Benefit): void
  onPrev(): void
}

const ProductsStep = ({ benefits, onPrev, onSubmit }: Props) => {
  const { t, i18n } = useTranslation()
  const family = useSelector(getFamily)

  return (
    <div>
      <div className="row product-selection no-gutters">
        {benefits.map(benefit => {
          let longDesc: string
          if (i18n.language === 'es') {
            longDesc = benefit.spanishLongDesc
          } else if (i18n.language === 'ca') {
            longDesc = benefit.catalanLongDesc
          } else if (i18n.language === 'pt') {
            longDesc = benefit.portugueseLongDesc
          } else {
            longDesc = benefit.longDesc
          }
          return (
            !benefit.parentId && (
              <div
                key={benefit.id}
                className="col-12 col-sm-6"
                onClick={() => {
                  onSubmit(benefit)
                }}
              >
                <div className="product">
                  <h4>{getTranslate(benefit, 'name', i18n.language)}</h4>
                  <div className="price">
                    <strong>
                      {formatCurrency(benefit.getUserPrice(family[0]))}
                    </strong>{' '}
                    / mes
                  </div>
                  <div className="category">{benefit.healthType}</div>
                  <div
                    className="long-desc"
                    dangerouslySetInnerHTML={{
                      __html: longDesc,
                    }}
                  />
                  {benefit.label1File && (
                    <ServerFileLink
                      src={benefit.label1File}
                      className="btn btn-info btn-sm btn-document-link"
                    >
                      <i className="entypo-info" />
                    </ServerFileLink>
                  )}
                </div>
                <div className="text-center mt-3">
                  <button type="button" className="btn-none">
                    <i className="fa fa-search"></i>{' '}
                    {t('buttons.select')}
                  </button>
                </div>
              </div>
            )
          )
        })}
      </div>
      <div className="text-center mt-3">
        <button type="button" className="btn-none" onClick={onPrev}>
          <i className='fa fa-arrow-left'></i>{' '}
          {t('buttons.back')}
        </button>
      </div>
    </div>
  )
}

export default ProductsStep

export default {
  MENU: {
    HOME: 'Início',
    DOCUMENT: 'Documentação',
    PROFILE: 'Informações pessoais',
    ADVANTAGE_PRODUCTS: 'Produtos com vantagam fiscal',
    OTHER_PRODUCTS: 'Outros produtos',
    LEGAL: 'Aviso legal',
    PRIVACY: 'Política de privacidade',
    COOKIE_MESSAGE: 'Este site usa cookies para melhorar a experiência',
    COMPENSATION: 'A minha compensação',
    FLEXIBLE: 'Produtos de vantagem fiscal',
    DOCUMENTATION: 'Documentação',
    PERSONAL: 'Informações pessoais',
    MY_COMPENATION: 'Minha compensação',
    EXPENSE_MANAGEMENT: 'Gerenciamento de despesas',
    CORPORATE_SOCIAL_RESPONSIBILITY: 'Responsabilidade social corporativa',
  },
  HEADER: {
    SAVED: 'Poupados',
    PENDING_SAVING: 'Possíveis de poupar',
    LIMIT: 'Limite máximo',
    REACHED: 'Alcançado',
    LANGUAGE: 'Idioma',
    CHECKOUT: 'Tramitar pedido',
    LOGOUT: 'Encerrar sessão',
    HELP: 'Ajuda',
    CONTACT: 'Contacte-nos',
  },
  HOME: {
    MISSING_PROFILE_INFO: 'Informação do utilizador incompleta: Por favor, verifique se preencheu o e-mail/telefone do seu perfil',
    CURRENT_SITUATION: 'Situação',
    NEWS: 'Notícias',
    PRODUCTS_WITH_TAX: 'Produtos com vantagam fiscal',
    ALERTS: 'Alertas',
    CONTRACTED_PRODUCTS: 'Produtos contratados',
    PENDING_SIGNATURE: 'A aguardar assinatura',
    PENDING_PRODUCTS: 'A aguardar contrato',
    SAVING: 'Poupados',
    PENDING_SAVING: 'A aguardar poupança',
    NON_SIMULATED_PRODUCT: 'Não simulado',
    PENDING_SIMULATIONS: 'Simulações Pendentes',
    NO_ACTIVE_ALERTS: 'Não tem alertas',
    PRODUCT: 'Produto',
    Status: 'Estado',
    END_DATE: 'Data final',
    Expiring: 'Data de término',
    Go: 'Ir',
    MISSING_DOCUMENT: 'Falta documentação',
    ATTACH_INVOICE: 'Anexar fatura da mês',
    PENDING_CART: 'A aguardar contrato',
    PENDING_REVIEW: 'A aguardar revisão',
    CONTRACTED: 'Contratado',
    CANCELLED: 'Cancelado',
  },

  LIFE_AXA: {
    FALLCC: 'Morte por qualquer causa',
    FALLA: 'Morte por acidente',
    FALLAC: 'Morte por acidente de trânsito',
    IPAA: 'Incapacidade Permanente Absoluta por Acidente',
    IPAAC: 'Incapacidade Permanente Absoluta por Acidente de Trânsito',
    IPACC: 'Incapacidade Permanente Absoluta por qualquer causa',
    GINV: 'Grande Deficiência',
    MANDATORY: 'Obrigatório',
    MANDATORY_2: 'obrigatório para contratar',
    AGE_LIMIT: 'Não disponível para sua idade',
    CANNOT_EXCEED: 'não pode exceder',
    CANNOT_LESS_FALLA: 'Não pode ser inferior a 35.000 €'
},

  COMPENSATION:{
    Benefits:"Benefícios Sociais",
    TotalCompensation: "Total Compensação",
    TotalSecurityCost: "Custo da Segurança Social"
  },
  DOCUMENT: {
    Documents: 'Documentos',
    DETAIL_ACTION: 'Detalhe por produto',
    DETAIL: 'Detalhe',
    ID: 'Id',
    YEAR: 'Ano',
    NAME: 'Nome',
    CREATED_BY: 'Criado por',
    CREATED_AT: 'Criado em',
    LAST_MODIFICATION: 'Última alteração',
    Admin: 'Administrador',
    no_documents: 'No há documentos carregados',
    no_products: 'Não tem produtos contratados',
  },
  profile: {
    user_tab: 'Informação do utilizador',
    employee_tab: 'Informações pessoais',
    family_tab: 'Informações familiares',
    office: 'Escritório',
    incorrect_password: 'A senha não cumpre as medidas mínimas de segurança: Deve ter no mínimo 8 caracteres, conter números e letras, pelo menos uma letra maiúscula, minúscula, e sem espaços',
    incorrect_repeat_password: 'A senha não corresponde',
    password_deprecated: 'A senha expirou. Por favor, digite a nova senha',
    collective: 'Coletivo',
    legal_entity: 'Entidade legal',
    email: 'E-mail',
    password: 'Palavra-passe',
    repeat_password: 'Repetir palavra-passe',
    id: 'Cartão de cidadão',
    phone: 'Telemóvel',
    bank: 'Número de conta bancária',
    nationality: 'Nacionalidade',
    birthday: 'Data de nascimento',
    Gender: 'Sexo',
    Male: 'Masculino',
    Female: 'Feminino',
    disability: 'Deficiência',
    No: 'Não',
    Yes: 'Sim',
    marital_status: 'Estado civil',
    Single: 'Solteiro',
    Married: 'Casado',
    Divorced: 'Divorciado',
    Widowed: 'Viuvo',
    Address1: 'Endereço 1',
    Address2: 'Endereço 2',
    City: 'Localidade',
    Country: 'País',
    Province: 'Distrito',
    postal_code: 'Código postal',
    fixed_salary: 'Salário Fixo',
    flexible_bag: 'Mala Flexível',
    variable_salary: 'Salário Variável',
    number_payments: 'Número de pagamentos',
    social_security: 'Número de segurança social',
    employee_number: 'Número de funcionário',
    cost_center: 'Centro de custo',
    renting: 'Aluguer',
    private_use: '% uso privado',
    relationship: 'Parentesco',
    spouse: 'Conjuge',
    child: 'Filho/a',
    domestic_partner: 'Casal em união de facto',
    name: 'Nome',
    surname: 'Apelido',
    first_name: 'Nome',
    last_name: 'Apelido',
    last_name_2: 'Segundo Apelido',
    address: 'Endereço',
  },
  product_names: {
    'health-insurance': 'Saúde',
    transport: 'Transporte',
    food: 'Comida',
    kindergarten: 'Jardim de infância',
    formation: 'Formação',
    renting: 'Aluguer',
    'life-insurance': 'Vida',
    'life-insurance-axa': 'Vida',
    'saving-plan': 'Poupança',
    gym: 'Ginásio',
    lottery: 'lotaria',
    informative: 'Informativo',
  },
  buttons: {
    accept: "ACEITAR",
    accept_lower: "Aceitar",
    consent_lower: 'Entendido',
    close: 'FECHAR',
    add_cart: 'ACRESCENTAR AO CARRINHO',
    update_cart: 'ATUALIZAR O CARRINHO',
    remove_cart: 'ELIMINAR DO CARRINHO',
    update_contract: 'ATUALIZAR CONTRATO',
    reset: 'REINICIAR',
    see_details: 'Aceitar a cláusula de privacidade',
    sign: 'ASSINAR',
    back: 'VOLTAR',
    update: 'ATUALIZAR',
    cancel: 'CANCELAR',
    download: 'DESCARREGAR',
    click_here: 'CLIQUE AQUI',
    back_simulation: 'VOLTAR A SIMULAR',
    next_step: 'CONTRATAR',
    add_child: 'ACRESCENTAR FILHO',
    add_family: 'ACRESCENTAR FAMILIAR',
    select: 'SELECIONAR',
    delete: 'ELIMINAR',
    add_member: 'ACRESCENTAR MEMBRO',
    reset_password: 'Repor palavra-passe',
    forgot_password: 'Esqueceu-se da palavra-passe?',
    back_to_login: 'Voltar ao início',
    change_language: 'mudar',
  },
  calculator: {
    header: 'SIMULADOR POUPANÇA IMPOSTO SOBRE O RENDIMENTO',
    increase_dis_income: 'Aumento líquido disponível',
    equi_salary_increase: 'Aumento salário equivalente',
    not_available:
      'Calculadora não disponível por: Informação de salário incompleta',
    edit_profile: 'Editar informação de salário no perfil dos utilizadores.',
    fixed_salary: 'Salário fixo',
    variable_salary: 'Salário variável',
    flexible_products: 'Produtos flexíveis',
    without_remuneration: 'Sem plano de retribuição flexível',
    with_remuneration: 'Com plano de retribuição flexível',
    non_flexible_products: 'Outros produtos',
    total_gross_salary: 'Total salário bruto',
    social_security: 'Segurança social',
    pit: 'IMPOSTO SOBRE O RENDIMENTO',
    withholding_account: 'Retenção de imposto',
    net_salary: 'Salário líquido',
    net_available_salary: 'Salário líquido disponível',
    employee_bag: 'Saco flexível',
    employee_top_bag: 'Saco produtos top',
    employee_user_bag: 'Saco do utilizador',
    company_bag: 'Saco empresa',
    contribution_company: 'Contrib. empresa',
    available: 'Disponível',
    spent: 'Gasto',
  },
  products: {
    health_insurance: 'Seguro de Saúde',
    month_text: 'Indique o montante mensal disponível',
    min_amount_text: 'Valor mínimo mensal a contratar',
    max_available: 'Máximo mensal disponível',
    transport_max_text: 'O limite de despesas é de 1500 € por ano',
    start_date: 'Data de início',
    privative_use: 'Uso privado',
    year: 'Ano',
    years: 'Anos',
    vehicle_value: 'Valor do veículo',
    with_no_tax: 'Percentagem sem IVA',
    with_tax: 'Percentagem com IVA',
    number_tickets: 'Número de tickets',
    price_tickets: 'Preço por ticket',
    death_capital: 'Capital por óbito',
    capital_disability: 'Capital por invalidez (opcional)',
    yearly_price: 'Prémio anual',
    select_children:
      'Por favor, selecione as crianças que gostaria de acrescentar ao jardim-de-infância',
    no_children: 'Não tem filhos menores de 3 anos',
    person: 'Pessoa',
    monthly: 'Mensal',
    yearly: 'Anual',
    main_benefit_error: 'Por favor, verifique primeiro o subsídio principal',
    benefit_error: 'Deve selecionar o subsídio',
    price_over_error: 'Supera o montante disponível',
  },
  contracts: {
    month: 'Mês',
    company: 'Empresa',
    capital_death_case: 'Capital por óbito',
    capital_dis_case: 'Capital por invalidez',
    pending_text:
      'O produto está a ser revisto. Iremos informá-lo quando for admitido',
    contract_text: 'Já se inscreveu para receber o subsídio',
    zero_message: 'O montante total é 0',
    min_unreached: 'A quantidade mínima mensal não foi atingida',
    reset_message: 'A configuração foi reiniciada',
    update_confirm_message:
      'Vai alterar os termos de um contrato existente, tem a certeza?" Em caso afirmativo, verifique o seu e-mail para concluir o processo',
    select_start_month:"selecione o mês inicial",
    inactive_product:"Você não tem permissão para alugar este produto. Entre em contato com o administrador para ativação.",
  },
  cart: {
    data_confirm: 'Confirmação de dados',
    sign_contract: 'Assinatura de contrato',
    step_company: 'Seleção da Empresa',
    step_product: 'Seleção de Produto',
    step_family: 'Seleção de Família',
    empty_cart: 'O seu carrinho está vazio',
    agree_message:
      'Ao clicar no passo seguinte, aceita contratar automaticamente',
    terms: `Assine e <b><u>receberá um e-mail com o contrato de novação 
            em anexo</u></b> e a lista de produtos selecionados que terá de aceitar.
              <br />
              <br />
              Para terminar o seu processo de contratação, <b><u>é necessário abrir
              e aceitar as condições do e-mail</u></b>. Lembre-se de o fazer antes do dia&nbsp;
              {{cutOffDate}} para que o produto esteja disponível no dia 1 do mês seguinte.`,
    pending: 'PENDENTE',
    completed: 'CONCLUÍDO',
    download_doc_text: 'Por favor descarregue o documento, preencha-o e assine-o',
    edit_family: 'Editar Informação do Beneficiário',
    name: 'Nome',
    surname: 'Apelido',
    address: 'Endereço',
    zip: 'Código postal',
    contact_person: 'Pessoa de contacto',
    contact_phone: 'Telefono de contacto',
    center_name: 'Nome do centro',
    course: 'Curso',
    phone_contact: 'Telefono de contacto',
    attach_invoice: 'Por favor, anexe a fatura',
    same_kindergarten: 'É o mesmo jardim de infância que',
    code_center: 'Código do jardim de infância',
    dontknow: 'Se não sabe, clique aqui',
    edit_profile: 'Editar perfil',
    modality_info_details: 'Informação detalhada',
    modality: 'Modalidade',
    beneficiary_info: 'Informação do beneficiário',
    additional_info: 'Informação adicional',
    total: 'Total',
    total_anual: 'Total Anual',
    total_spent: 'TOTAL GASTO',
    flexible_bag: 'Saco flexível',
    company_bag: 'Saco empresa',
    health_provider_noselect:
      'este fornecedor não pode ser selecionado porque o utilizador já assinou um contrato com outro fornecedor',
    no_info: 'Não são necessárias informações',
    expired_products: 'Tem produtos no carrinho com datas inferiores ao prazo da empresa, retire-os para os poder contratar: ',
    expired_warning: 'CADUCADO! Por favor, retire-o',
    time_left: 'Tempo restante',
    days: 'dia(s)',
    remove_products: 'Retire estes produtos do carrinho antes de adquirir',
    no_permission: 'Já não está autorizado a adquirir este produto',
    payment_frequency: 'Frequência de Pagamento'
  },
  prices:{
    price: 'Preço',
    discount: 'Desc.',
    total: 'Total'
  }

}
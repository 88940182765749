import React from 'react'
import { getData } from 'country-list'

import SelectField from './SelectField'

const countries = getData()

const CountrySelect = props => (
  <SelectField {...props}>
    <option value="">--Choose--</option>
    {countries.map(item => (
      <option key={item.code} value={item.code}>
        {item.name}
      </option>
    ))}
  </SelectField>
)

export default CountrySelect

import React from 'react'
import PropTypes from 'prop-types'

const ServerImage = ({ alt, src, className, embed }) =>
  embed ? (
    <div
      style={{ backgroundImage: `url(/api/storage/images/${src})` }}
      className={`embed-img ${className}`}
    />
  ) : (
    <img src={`/api/storage/images/${src}`} alt={alt} className={className} />
  )

ServerImage.defaultProps = {
  alt: 'server',
  className: '',
  embed: false,
}

ServerImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  embed: PropTypes.bool,
}

export default ServerImage

import React from 'react'
import * as storage from 'utils/storage'

type Props = {
  src: string
  auth?: boolean //if true, append auth token to url
  container?: string
  className?: string
}


const formatUrl = (container,src,auth) => {
  let token = storage.getCryptedToken()
  return `/api/storage/${container}/${src}${auth ? `?token=${encodeURIComponent(token)}`: ''}`
}

const ServerFileLink: React.FC<Props> = ({
  container,
  src,
  auth,
  className,
  children,
}) => (
  <a
    href={formatUrl(container,src,auth)}
    target="__blank"
    className={className}
  >
    {children}
  </a>
)

ServerFileLink.defaultProps = {
  auth: true,
  className: '',
  container: 'document',
}

export default ServerFileLink

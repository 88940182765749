import React from 'react';
import PropTypes from 'prop-types';

const InputField = props => {
  const {
    input,
    meta: { touched, error, warning },
  } = props;

  return (
    <React.Fragment>
      <input {...input} {...props} autoComplete="off" />
      {touched &&
        ((error && <span className="text-danger">{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </React.Fragment>
  );
};

InputField.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({}),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
};
InputField.defaultProps = {
  className: 'form-control',
};

export default InputField;

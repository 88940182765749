import { generateContract } from 'models/Contract'
import { types } from './actions'

const initialState = []

export default (appState = initialState, { type, payload }) => {
  switch (type) {
    case types.CART_GET:
      return payload.map(item => generateContract(item))

    case types.CART_ADD:
      return [...appState, generateContract(payload)]
    case types.CART_UPDATE:
      return appState.map(item =>
        item.id === payload.id
          ? generateContract({
              ...item,
              ...payload,
            })
          : item
      )
    case types.CART_REMOVE:
      return appState.filter(item => item.id !== payload)
    case types.CART_REMOVE_ITEMS:
      return appState.filter(item => payload.indexOf(item.id) < 0)

    default:
      return appState
  }
}

export const getCart = state => state.cart

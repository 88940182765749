import React from 'react';
import PropTypes from 'prop-types';

const Panel = ({ title, color, bodyClass, children }) => (
  <div className={`panel panel-dark-blue`} >
    <div className="panel-heading" style={{ background:color }}>{title}</div>
    <div className={`panel-body bordered ${bodyClass}`}>{children}</div>
  </div>
);

Panel.defaultProps = {
  color: 'black',
  bodyClass: '',
};

Panel.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  bodyClass: PropTypes.string,
  children: PropTypes.node,
};

export default Panel;

import React from 'react'
import { push } from 'connected-react-router'
import { WithTranslation } from 'react-i18next'

import { getFamily } from 'store/families/actions'
import { purchaseCart } from 'store/cart/actions'
import CartEditStep from './CartEditStep'
import SignatureStep from './SignatureStep'
import { getTranslate } from 'helpers/translate'
import { showNotify } from 'utils/notify'

type Props = {
  cart: Contract[]
  family: Family[]
  simulation: Simulation
  user: User
  dispatch(action: any): Promise<any>
}

type States = {
  currentStep: number
}

class Checkout extends React.Component<Props & WithTranslation, States> {
  state = {
    currentStep: 0,
  }
  purchaseCartItems = []

  constructor(props) {
    super(props)

    this.props.dispatch(getFamily())
  }

  joinProductNames = (products) => {
    const { i18n } = this.props
    return `${products
      .map(item => getTranslate(item.benefit, 'name', i18n.language))
      .join(',')} product(s)`
  }

  moveNext = (params?: any) => {
    const { cart, t } = this.props
    const { currentStep } = this.state
    const otherProducts = cart.filter(item => !item.benefit.isTaxAdvantage)
    const completedInfoCart = cart.filter(c => !c.isMissingDocument())
    const incompletedInfoCart = cart.filter(c => c.isMissingDocument())

    if (
      otherProducts.length &&
      !window.confirm(
        `${t('cart.agree_message')} ${this.joinProductNames(otherProducts)}`
      )
    ) {
      return
    }

    if (incompletedInfoCart.length) {
      showNotify(
        `Algunos contratos no se han podido generar: ${this.joinProductNames(incompletedInfoCart)}`,
        'warning'
      )
    }

    if (currentStep === 1) {
      console.log(completedInfoCart)
      this.props
        .dispatch(
          purchaseCart(completedInfoCart)
        )
        .then(() => {
          this.props.dispatch(push('/home'))
        })
    } else {
      this.purchaseCartItems = params
      this.setState({
        currentStep: currentStep + 1,
      })
    }
  }

  handleBack = () => {
    this.setState({
      currentStep: 0,
    })
  }

  render() {
    const { cart, t } = this.props
    const { currentStep } = this.state

    const steps = [
      {
        title: t('cart.data_confirm'),
        component: CartEditStep,
      },
      {
        title: t('cart.sign_contract'),
        component: SignatureStep,
      },
    ]

    return (
      <div className="form-wizard pt-5 mw-1000px">
        <ul>
          {steps.map((step, index) => {
            let className = ''
            if (currentStep > index) {
              className = 'completed'
            } else if (currentStep === index) {
              className = 'active'
            }
            return (
              <li key={step.title} className={className}>
                {/* eslint-disable-next-line*/}
                <a>
                  <span>{index + 1}</span>
                  {step.title}
                </a>
              </li>
            )
          })}
        </ul>
        <div className="mt-5" />

        {!cart.length && (
          <div className="alert alert-default text-center">
            {t('cart.empty_cart')}
          </div>
        )}

        {cart.length > 0 && currentStep === 0 && (
          <CartEditStep
            {...this.props}
            joinProductNames={this.joinProductNames}
            onBack={this.handleBack}
            onSubmit={this.moveNext}
          />
        )}

        {cart.length > 0 && currentStep === 1 && (
          <SignatureStep
            user={this.props.user}
            onBack={this.handleBack}
            onSubmit={this.moveNext}
          />
        )}
      </div>
    )
  }
}

export default Checkout

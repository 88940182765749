import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from 'utils/number'
import { getFamily } from 'store/families/actions'
import { getCalculation, getContractItems } from 'store/simulator/reducer'
import CurrentSituation from './CurrentSituation'
import News from './News'
import Alerts from './Alerts'
import PendingSimulations from './PendingSimulations'
import MediaQuery from 'react-responsive'
import './HomePage.scss'

type Props = {
  user: User
}

const HomePage: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isExentProducts = user && user.products.find(product => product.PL === 'Exent')

  useEffect(() => {
    dispatch(getFamily())
  })

  const { saving, pending } = useSelector(getCalculation)
  const contractItems = useSelector(getContractItems)
  const contracted = contractItems.filter(
    (item: Contract) => item.status === 'contracted'
  )
  const signed = contracted.filter((item: Contract) => item.isSigned())

  return (
    <div className="home-page">

      <div className="stats">
        <div className="stats-wrapper">
          <div className="tile-stats contracted-products">
            <img src="/img/tools.svg" height="30px" alt="" />
            <div className="num">{signed.length}</div>
            <h3>{t('HOME.CONTRACTED_PRODUCTS')}</h3>
          </div>
        </div>

        <div className="stats-wrapper">
          <div className="tile-stats pending-products">
            <img src="/img/write.svg" width="30px" alt="" />
            <div className="num">{contracted.length - signed.length}</div>
            <h3>{t('HOME.PENDING_SIGNATURE')}</h3>
          </div>
        </div>

        <div className="stats-wrapper">
          <div className="tile-stats pending-products">
            <img src="/img/schedule.svg" height="30px" alt="" />
            <div className="num">
              {contractItems.length - contracted.length}
            </div>
            <h3>{t('HOME.PENDING_PRODUCTS')}</h3>
          </div>
        </div>

        {isExentProducts && (
          <>
            <div className="stats-wrapper">
              <div className="tile-stats saving">
                <img src="/img/saving-plan.svg" height="30px" alt="" />
                <div className="num">{formatCurrency(saving)}</div>
                <h3>{t('HOME.SAVING')}</h3>
              </div>
            </div>

            <div className="stats-wrapper">
              <div className="tile-stats pending-saving">
                <img src="/img/graph.svg" height="30px" alt="" />
                <div className="num">{formatCurrency(pending)}</div>
                <h3>{t('HOME.PENDING_SAVING')}</h3>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="row mt-3">
        <div className="col-lg-7">
          <CurrentSituation />
        </div>
        <div className="col-lg-5">
          <News />
          <div className="mt-3" />
          <Alerts />
          <PendingSimulations />
        </div>
      </div>
    </div>
  )
}

export default HomePage

import React, { FC } from "react"
import { required } from "redux-form-validators"
import { Field } from "redux-form"
import UploadFieldWithName from "../forms/UploadFIleWithName"

interface Props {
    title: string
}

/**
 *
 * @param title
 * @constructor
 */
const Document: FC<Props> = ({ title }) => {
    return (
        <div className='d-flex flex-column align-items-start'>
            <label className="control-label required">
                {`${title}: `}
            </label>

            <Field
                component={UploadFieldWithName}
                name='file'
                validate={required()}
            />
        </div>
    )
}

export default Document

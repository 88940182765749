import React from 'react'
import access from 'safe-access'

import ContractItem from 'models/Contract/ContractItem'
import MonthRow from './MonthRow'
import ContractPrice from './ContractPrice'

interface Props {
  editable?: boolean
  familyId?: number
  contract: Contract
  simulation: Simulation
  onChange: () => void
}

class ContractEdit extends React.Component<Props> {
  static defaultProps = {
    editable: true,
    familyId: 0,
  }

  getMaxPrice = () => {
    const {
      contract,
      simulation: { flexibleBag, topBag, fixedSalary },
    } = this.props
    const isTaxAdvantage = access(contract, 'benefit.isTaxAdvantage')
    let possibleMaxPrice = isTaxAdvantage ? flexibleBag : topBag
    let isNoLimit = false;
    if (!isTaxAdvantage && !topBag) {
      possibleMaxPrice = fixedSalary
      isNoLimit = true
    }

    if (!isNoLimit && contract.productCategory === 'food' && contract.benefit.workingPrice) {
      return Math.min(possibleMaxPrice, contract.benefit.workingPrice)
    } else {
      return possibleMaxPrice
    }
  }

  onCostChange = (month: number, cost: number) => {
    const {
      familyId,
      contract,
      onChange,
      simulation: { available, availableTop, topBag, fixedSalary },
    } = this.props

    const isTaxAdvantage = access(contract, 'benefit.isTaxAdvantage')
    let availableMoney = isTaxAdvantage ? available : availableTop
    if (!isTaxAdvantage && !topBag) {
      availableMoney = fixedSalary
    }

    if (!this.props.editable) return

    const maxPrice = this.getMaxPrice()
    const oldPrice = contract.getMonthContractPrice(month, familyId)
    let newPrice = Math.min(cost, availableMoney + oldPrice, maxPrice)
    const totalPrice = contract.price - oldPrice + newPrice

    // transport is 1500/year max
    if (contract.product === 'transport' && totalPrice > 1500) {
      newPrice -= totalPrice - 1500
    }

    contract.addContractItem(
      new ContractItem({
        familyId,
        month,
        price: newPrice
      })
    )

    onChange()
  }

  render() {
    const { contract, familyId } = this.props
    const maxPrice = this.getMaxPrice()
    const months = []

    for (let month = 0; month <= 11; month += 1) {
      months.push(
        <MonthRow
          key={month}
          year={contract.year}
          month={month}
          maxPrice={maxPrice}
          value={contract.getMonthContractPrice(month, familyId)}
          onChange={v => {
            this.onCostChange(month, v)
          }}
          disabled={!contract.benefit.monthsAvailability[month]}
        />
      )
    }

    return (
      <div>
        <div className="panel panel-primary mt-4 mb-0">
          <div className="panel-body">
            <div className="row">
              <div className="col-lg-6">{months.slice(0, 6)}</div>
              <div className="col-lg-6">{months.slice(6)}</div>
            </div>
          </div>
        </div>

        <div className="text-center my-3">
          <ContractPrice price={contract.getFamilyTotalPrice(familyId)} discount={contract.userBag}/>
        </div>
      </div>
    )
  }
}

export default ContractEdit

import Contract from './GeneralContract'

class LotteryContract extends Contract {
  constructor(props) {
    super(props)
    this.details = this.details || {
      ticketNumber: 0,
      ticketPrice: 0,
    }
  }

  getTotalPrice(): number {
    return this.benefit.defaultPrice * this.details.ticketCount
  }
}

export default LotteryContract

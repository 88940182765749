import React, { FC } from "react"
import './MenuItem.scss'
// import ClubSvg from "components/svg/ClubSvg"
import CompensacionSvg from "components/svg/CompensacionSvg"
import DocSvg from "components/svg/DocSvg"
import ExencionSvg from "components/svg/ExencionSvg"
import InfoSvg from "components/svg/InfoSvg"
import OtrosSvg from "components/svg/OtrosSvg"
import RetribucionSvg from "components/svg/RetribucionSvg"

interface Props {
    number?: number,
    title: string,
    icon?: string
}


const MenuItem: FC<Props> = ({ number, title, icon }) => {
    const getIconSvg = () => {
        switch (icon) {
            case 'address-card-o':
                return <InfoSvg className="menu-item-icon" />;
            case 'paperclip':
                return <DocSvg className="menu-item-icon" />;
            case 'yelp':
                return <RetribucionSvg className="menu-item-icon" />;
            case 'shopping-basket':
                return <OtrosSvg className="menu-item-icon" />;
            case 'pie-chart':
                return <CompensacionSvg className="menu-item-icon" />;
            case 'hand-pointer-o':
                return <ExencionSvg className="menu-item-icon" />;
        }
        return <InfoSvg />;
    }


    const getBackground = () => {
        switch (icon) {
            case 'address-card-o':
                return 'img/menu/info.jpg';
            case 'paperclip':
                return 'img/menu/doc.jpg';
            case 'yelp':
                return 'img/menu/retribucion.jpg';
            case 'shopping-basket':
                return 'img/menu/otros.jpg';
            case 'pie-chart':
                return 'img/menu/compensacion.jpg';
            case 'hand-pointer-o':
                return 'img/menu/exencion.jpg';
        }
        return 'img/menu/info.jpg';
    }



    return (
        <div className="menu-item" style={{ backgroundImage: `url(${getBackground()})` }}>
            
            {icon && getIconSvg()}

            <div className="menu-item-text">
                {number &&
                    <div className="menu-item-text-number">
                        {number.toString().padStart(2, '0')}.
                    </div>
                }
                <div className="menu-item-text-title">
                    {title}
                </div>
            </div>
        </div>
    )
}

export default MenuItem

import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getTranslate } from 'helpers/translate'
import access from 'safe-access'

import { getProductStatus } from 'utils/contracts'
import './ProductStatus.scss'

type Props = {
  contracts: Contract[]
  product: Product
  lite: boolean
}

const ProductStatus: React.FC<Props> = ({
  product,
  contracts,
  lite = false,
}) => {
  const { t } = useTranslation()
  const { i18n } = useTranslation()

  const productContracts = contracts.filter(
    contract => contract.product === product.category.alias
  )

  const status = getProductStatus(productContracts)

  const getImagePath = () => {
    if (product.category.alias === 'informative' || !product.category.isDefault) {
      return 'informative'
    } else {
      return product.category.alias
    }
  }

  return (
    <Link
      to={`/products/${access(product, 'category.alias')}`}
      className={`product-status${lite ? '-mobile' : ''}`}
    >
      <div className="name bordered p-1">{getTranslate(product.category, 'name', i18n.language)}</div>
      <div className='product-status-icon'>
        {/* product.category.alia */}
        <img src={`/img/${getImagePath()}.svg`} alt={getImagePath()} />
      </div>
      <label className={`status ${status.status}`}>{t(status.text)}</label>
    </Link>
  )
}

export default ProductStatus

import React from 'react'
import { useTranslation } from 'react-i18next'
import './CartButtons.scss'

type Props = {
  isUpdate: boolean
  isNotValid?: boolean
  onClick(action: string): void
}

const CartButtons: React.FC<Props> = ({ onClick, isUpdate }) => {
  const { t } = useTranslation()

  return (

    <div>
      <div className="grey-bar">{t('buttons.select')}</div>
      <div className="bag-box">


        {!isUpdate ? (
          <React.Fragment>
            <button
              type="button"
              className="btn-none mr-4"
              onClick={() => {
                onClick('add')
              }}
            >
              <img src="/img/cart.svg" alt="cart" width="25" style={{filter: 'brightness(0)'}} />{' '}
              {t('buttons.add_cart')}
            </button>
            <button
              type="button"
              className="btn-none mr-4"
              onClick={() => {
                onClick('reset')
              }}
            >
              <i className='fa fa-refresh mr-2'></i>
              {t('buttons.reset')}
            </button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <button
              type="button"
              className="btn-none mr-4"
              onClick={() => {
                onClick('update')
              }}
            >
              <img src="/img/cart.svg" alt="cart" width="25"  style={{filter: 'brightness(0)'}}/>{' '}
              {t('buttons.update_cart')}
            </button>
            <button
              type="button"
              className="btn-none"
              onClick={() => {
                onClick('remove')
              }}
            >
              <i className='fa fa-refresh mr-2'></i>
              {t('buttons.remove_cart')}
            </button>
          </React.Fragment>
        )}

      </div>
    </div>
  )
}

export default CartButtons

import uniqid from 'uniqid'
import access from 'safe-access'
import { sumBy, uniq } from 'lodash'
import { strAmountIsZero } from '../../utils/contracts'
import { round } from '../../utils/formula'


import { ProductCategory } from 'helpers/enum'
import Benefit from '../Benefit'
import ContractItem from './ContractItem'

export enum Status {
  Cart = 'cart',
  Pending = 'pending',
  Contracted = 'contracted',
  Cancelled = 'cancelled',
}

class GeneralContract implements Contract {
  id: string | number = 0
  product: ProductCategory
  details?: any | HealthInsuranceFamilyData
  additionalInfo?: any
  price: number
  userBag:number
  year: number
  status: Status
  signed: boolean
  createdAt: string
  updatedAt: string
  disableDocumentation: boolean
  benefit?: Benefit
  items: ContractItem[] = []
  simulation?: Simulation

  constructor(props) {
    this.id = props.id
    this.simulation = props.simulation
    this.year = props.year || (props.benefit && props.benefit.year) || new Date().getFullYear()
    this.price = props.price || 0
    this.userBag = props.userBag || 0
    this.status = props.status || Status.Cart
    this.benefit =
      props.benefit instanceof Benefit
        ? props.benefit
        : new Benefit(props.benefit)
    this.product = props.product || this.productCategory

    this.additionalInfo = props.additionalInfo
      ? JSON.parse(JSON.stringify(props.additionalInfo))
      : {}
    this.details = props.details
      ? JSON.parse(JSON.stringify(props.details))
      : undefined
    this.items = props.items
      ? props.items.map(item => new ContractItem(item))
      : []
    this.signed = props.signed || false
    this.disableDocumentation = props.disableDocumentation || false
  }

  get benefitId(): number {
    return this.benefit.id
  }

  /**
   * The total contract price discount by contribution company
   */
  get contributionCompany(): number {
    return  Math.max(
        0,
        this.price - this.contributedPrice
    )
  }

  /**
   * The final contract price after contribution company is applied
   */
  get contributedPrice(): number {
    if(!this.items.length) return this._calculateContributedContractPrice(this)
    let ret = 0
    this.items.forEach(item => {
      ret += this._calculateContributedItemPrice(item)
    })
    return ret
  }

  /**
   * The final contract price after discounts are applied (user bag, etc...)
   */
  get finalPrice(): number {
    return Math.max(0, this.price - this.userBag)
  }


  /**
   * Returns the total price for a contract item after contribution company is applied
   * @param item Contract item
   * @private
   */
  _calculateContributedItemPrice(item: ContractItem): number {
    const contributionCompany = access(this, 'benefit.contributionCompany')

    let ret = 0
    // check if contribution is applied to family
    if (strAmountIsZero(contributionCompany)) {
      ret = item.price
    } else if (item.familyId && this.benefit.contributionTarget !== 'both') {
      ret = item.price
    } else if (contributionCompany.endsWith('%')) {
      ret = item.price * (1 - parseFloat(contributionCompany) / 100)
    } else {
      ret = Math.max(
          0,
          item.price - parseFloat(contributionCompany)
      )
    }
    return ret
  }

  _calculateContributedContractPrice(contract: Contract): number {
    const contributionCompany = access(this, 'benefit.contributionCompany')

    let ret = 0
    // check if contribution is applied to family
    if (strAmountIsZero(contributionCompany)) {
      ret = contract.price
    } else if (contributionCompany.endsWith('%')) {
      ret = contract.price * (1 - parseFloat(contributionCompany) / 100)
    } else {
      ret = Math.max(
          0,
          contract.price - parseFloat(contributionCompany)
      )
    }
    return ret
  }


  get productCategory(): ProductCategory {
    return access(this, 'benefit.product.category.alias')
  }

  get productName(): string {
    return access(this, 'benefit.product.name')
  }

  getFamilyIds(): Array<number> {
    return uniq(this.items.map(item => item.familyId))
  }

  // generate new id for simulation
  generateId() {
    this.id = uniqid()
  }

  // return the exemption price
  getExemptionPrice(tax, family: Family[]): number {
    if (!tax) return this.price

    return Math.min(tax.limitExemption, this.price)
  }

  getTotalPrice(): number {
    return sumBy(this.items, 'price')
  }

  userBagAvailable(): boolean {
    return this.benefit && this.benefit.userBag
  }

  _updateUserBag(simulation: Simulation): number {
    if(!simulation) return
    if(!this.userBagAvailable()) return
    const contractUserbag = this.benefit ? simulation.userBagById[this.benefit['id']] || 0 : 0 //cart
    this.userBag = Math.min(((simulation.availableUserBag || 0) + contractUserbag), this.contributedPrice)
  }

  updatePrice() {
    this.items.forEach(item => item.contributedPrice = round(this._calculateContributedItemPrice(item),2))
    this.price = this.getTotalPrice()
    this._updateUserBag(this.simulation)
  }

  isMissingDocument(): boolean {
    return (
      !this.disableDocumentation &&
      this.benefit.isInfoRequired() &&
      (!this.additionalInfo || !Object.values(this.additionalInfo).length)
    )
  }

  isCart(): boolean {
    return this.status === 'cart'
  }

  isSigned(): boolean {
    return this.status !== 'cart' && this.signed
  }

  isCancelled(): boolean {
    return this.status === 'cancelled' && this.signed
  }

  getMonthContract(month: number, familyId: number = 0): ContractItem {
    return this.items.find(
      item => item.month === month && item.familyId === familyId
    )
  }

  getMonthContractPrice(month: number, familyId: number = 0): number {
    const item = this.getMonthContract(month, familyId)

    return item ? item.price : 0
  }

  getFamilyTotalPrice(familyId: number = 0): number {
    return sumBy(this.items.filter(item => item.familyId === familyId), 'price')
  }

  /*
  generate the new contracts
  */
  addContractItem(contractItem: ContractItem) {
    const item = this.getMonthContract(
      contractItem.month,
      contractItem.familyId
    )

    if (!item) {
      this.items = [...this.items, contractItem]
    } else {
      this.items = this.items.map(item =>
        item.month === contractItem.month &&
        item.familyId === contractItem.familyId
          ? contractItem
          : item
      )
    }
    // remove items with price 0
    this.items = this.items.filter(item => !!item.price)

    this.updatePrice()
  }

  updateDetails(details: any) {
    this.details = details
    this.updatePrice()
  }

  updateItems(items: ContractItem[]): void {
    this.items = []
    for (const item of items) {
      this.addContractItem(item)
    }
  }

  getStatusText(): string {
    if (this.status === 'cancelled' && this.signed) {
      return 'contracts.cancel_text'
    } else if (this.status === 'pending') {
      return 'contracts.pending_text'
    } else {
      return 'contracts.contract_text'
    }
  }

  toJSON() {
    return {
      year: this.year,
      product: this.productCategory,
      benefitId: this.benefitId,
      price: this.price,
      userBag: this.userBag,
      contributedPrice: this.contributedPrice,
      details: this.details,
      additionalInfo: this.additionalInfo,
      items: this.items,
    }
  }
}

export default GeneralContract

import * as api from 'utils/api'
import { showNotify,  alertError } from 'utils/notify'
import { filterNestedObjects } from 'utils/api'

import * as appActions from 'store/app/actions'

export const types = {
  PROFILE_INFO: 'PROFILE_INFO',
  PROFILE_INFO_UPDATE: 'PROFILE_INFO_UPDATE',
}
export const getProfile = () => dispatch => {
  dispatch(appActions.showSpinner('PROFILE_INFO'))

  return api
    .get('/profile')
    .then(user => {
      dispatch({
        type: types.PROFILE_INFO,
        payload: user,
      })

      dispatch({
        payload: user.cart,
        type: 'CART_GET',
      })

      dispatch({
        payload: user.contracts,
        type: 'CONTRACT_GET',
      })
    })
    .finally(() => {
      dispatch(appActions.hideSpinner('PROFILE_INFO'))
    })
}

export const updateProfile = values => dispatch => {
    //filtering nested objects

    const body = filterNestedObjects(values)

    return api.post('/profile', body).then(res => {
        dispatch({
            type: types.PROFILE_INFO_UPDATE,
            payload: res,
        })
        showNotify('La información de perfil ha sido actualiza')
    }).catch(err => {
        const { error } = err
        alertError(new Error(error || "Error updating profile, please review info and ensure email is unique"))
    })

}

export const updateProfileDetail = values => dispatch =>
  api.post('/profile/detail', values).then(res => {
    dispatch({
      type: types.PROFILE_INFO_UPDATE,
      payload: { detail: res },
    })
    showNotify('La información de perfil ha sido actualiza')
  })

export const checkEmailExists = (email, id) =>
  api.post('/users/email-exists', { email, id })

import React from 'react'
import { Button, Spinner } from 'reactstrap'

interface Props {
  isLoading?: boolean
  [x: string]: any
}

const LoadingButton: React.FC<Props> = ({
  isLoading,
  children,
  ...restProps
}) => (
  <Button {...restProps} disabled={isLoading}>
    {isLoading && <Spinner size='sm' className='mr-1' />}
    {children}
  </Button>
)

LoadingButton.defaultProps = {
  isLoading: false,
}

export default LoadingButton

import React, { FC } from "react"
import Question from "./Question"

interface Props {
    list: ProductQuestion[]
}

/**
 *
 * @param list
 * @constructor
 */
const Questions: FC<Props> = ({ list }) => {
    return (
        <>
            {list.map(item => (
                <Question
                    key={item.title}
                    isRequired={item.isRequired}
                    title={item.title}
                />
            ))}
        </>
    )
}

export default Questions

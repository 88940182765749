import React from 'react'
import { useTranslation } from 'react-i18next'

import ServerImage from 'components/ServerImage'
import { getTranslate } from 'helpers/translate'

type Props = {
  benefit: Benefit
}

const BenefitSimple: React.FC<Props> = ({ benefit }) => {
  const { i18n } = useTranslation()
  return (
    <div>
      <h4 className="benefit-name">
        {getTranslate(benefit, 'name', i18n.language)}
      </h4>
      {benefit.logo && (
        <ServerImage className="mw-100 img-benefit" src={benefit.logo} />
      )}
    </div>
  )
}

export default BenefitSimple

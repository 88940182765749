import { types } from './actions'
import { selectProfile } from 'store/profile/reducer'

const initialState = null

export default (appState = initialState, { type, payload }) => {
  switch (type) {
    case types.USER_FAMILY:
      return payload

    case types.USER_FAMILY_MEMBER_UPDATE:
      return (appState || []).map(item =>
        item.id === payload.id ? payload : item
      )
    default:
      return appState
  }
}

export const getFamily = (state, relationship = null) => {
  const user = selectProfile(state)
  const family = [
    {
      id: 0,
      firstName: user.firstName,
      lastName1: user.lastName1,
      lastName2: user.lastName2,
      fullName: user.fullName,
      birthday: user.birthday,
      relationship: 'you',
    },
    ...(state.families || []),
  ]

  return relationship
    ? family.filter(item => item.relationship === relationship)
    : family
}

import React from 'react'
import { initialize as initializeForm } from 'redux-form'
import { WithTranslation } from 'react-i18next'
import qs from 'qs'
import access from 'safe-access'

import * as actions from 'store/families/actions'
import { updateProfile } from 'store/profile/actions'
import ImageUploadField from 'components/forms/ImageUploadField'
import UserInformation from './UserInformation'
import EmployeeLegalData from './EmployeeLegalData'
import FamilyInformation from './FamilyInformation'
import './ProfilePage.scss'

type Props = {
  user: User
  location: {
    search: string
  }
  dispatch: any
}

type States = {
  active: number
}

class ProfilePage extends React.Component<Props & WithTranslation, States> {
  file: any = null
  state = {
    active: 0,
  }

  constructor(props) {
    super(props)

    const {
      location: { search },
    } = this.props
    const params = qs.parse(search.slice(1))

    if (params.tab === 'family') {
      this.state.active = 2
    } else if (params.tab === 'employee') {
      this.state.active = 1
    }
  }

  componentDidMount() {
    const { user } = this.props

    this.props.dispatch(
      initializeForm('UserInformation', user)
    )

    this.props.dispatch(initializeForm('EmployeeLegalData', user))

    this.props.dispatch(actions.getFamily()).then(families => {
      this.props.dispatch(
        initializeForm('FamilyInformation', { family: families })
      )
    })
  }

  handleFileChange = picture => {
    this.props.dispatch(updateProfile({ picture }))
  }

  triggerUpload = () => {
    this.file.click()
  }

  toggle = tab => {
    this.setState({
      active: tab,
    })
  }

  render() {
    const { user, t } = this.props
    const { active } = this.state
    const tabs = [
      { title: 'profile.user_tab', Component: UserInformation },
      { title: 'profile.employee_tab', Component: EmployeeLegalData },
      { title: 'profile.family_tab', Component: FamilyInformation },
    ]

    return (
      <div className="profile-env">
        <header className="row">
          <div className="col-sm-2">
            {/* eslint-disable-next-line*/}
            <a className="profile-picture">
              <ImageUploadField
                aspectRatio={1}
                defaultImage="profile.jpg"
                input={{
                  value: user.picture,
                  onChange: this.handleFileChange,
                }}
                rounded
              />
            </a>
          </div>
          <div className="col-sm-7">
            <ul className="profile-info-sections">
              <li>
                <div className="profile-name">
                  <strong>{user.fullName}</strong>
                  <span>{access(user, 'company.name')}</span>
                </div>
              </li>
            </ul>
          </div>
        </header>

        <section className="profile-info-tabs">
          <div className="row">
            <div className="col-sm-offset-2 col-sm-10">
              <ul className="nav nav-tabs">
                {tabs.map(({ title }, index) => (
                  <li key={title} className={index === active ? 'active' : ''}>
                    {/* eslint-disable-next-line*/}
                    <a
                      className="cp"
                      onClick={() => {
                        this.toggle(index)
                      }}
                    >
                      {t(title)}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        <section className="profile-feed">
          {tabs.map(({ title, Component }, index) => (
            <div key={title} className={`pt-4 ${index !== active && 'd-none'}`}>
              <Component {...this.props} />
            </div>
          ))}
        </section>
      </div>
    )
  }
}

export default ProfilePage

import React from 'react'
import { reduxForm, FieldArray } from 'redux-form'

import { updateFamily } from 'store/families/actions'
import Family from './Family'

type Props = {
  user: User
  cart: Contract[]
  family: Family[]
  handleSubmit: any
  dispatch(action: any): void
}

class FamilyInformation extends React.Component<Props> {
  onSubmit = values => {
    this.props.dispatch(updateFamily(values.family))
  }

  render() {
    const { handleSubmit, family, user, cart } = this.props
    //obtaining all family members having contracts
    const idsFamilyWithContracts = []

    const contracts = (user.contracts || []).concat(cart || [])

    for(const contract of contracts) {
      if(contract.items){
        for(const item of contract.items){
          if(item.familyId && !idsFamilyWithContracts.includes(item.familyId)) {
            idsFamilyWithContracts.push(item.familyId)
          }
        }
      }
    }

    return (
      <form className="form-horizontal" onSubmit={handleSubmit(this.onSubmit)}>
        <FieldArray name="family" component={Family} family={family} idsFamilyWithContracts={idsFamilyWithContracts} />
      </form>
    )
  }
}

export default reduxForm({
  form: 'FamilyInformation',
})(FamilyInformation)

import React from 'react'
import PropTypes from 'prop-types'

const SelectField = ({
  input,
  children,
  className,
  meta: { touched, error, warning },
  disabled,
  placeholderValue,
  placeholderText
}) => (
  <React.Fragment>
    <select
      {...input}
      value={(input.value === 0) ? '0' : input.value || placeholderValue}
      className={`form-control ${className}`}
      autoComplete="off"
      disabled={disabled}
    >
      <option value={placeholderValue}>{placeholderText}</option>

      {children}
    </select>
    {touched &&
      ((error && <span className="text-danger">{error}</span>) ||
        (warning && <span>{warning}</span>))}
  </React.Fragment>
)

SelectField.defaultProps = {
  disabled: false,
  placeholderText: '-- Select --',
  placeholderValue: '',
}

SelectField.propTypes = {
  input: PropTypes.shape({}),
  type: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};

export default SelectField

import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { reduxForm, Field } from 'redux-form'
import { required } from 'redux-form-validators'
import { useTranslation } from 'react-i18next'

import InputField from 'components/forms/InputField'
import DateField from 'components/forms/DateField'
import SelectField from 'components/forms/SelectField'

interface Props {
  hideRelationship?: boolean
  handleSubmit(onSubmit: any): any
  toggle(): void
}

const FamilyEditDlg: React.FC<Props> = ({
  hideRelationship,
  handleSubmit,
  toggle,
}) => {
  const { t } = useTranslation()

  return (
    <Modal className="cart-edit-modal" isOpen={true} toggle={toggle}>
      <form onSubmit={handleSubmit}>
        <ModalHeader toggle={toggle} />
        <ModalBody>
          <div className="heading4 mb-4">{t('cart.edit_family')}</div>
          {!hideRelationship && (
            <div className="form-group row">
              <label className="col-sm-3 control-label required">
                {t('profile.relationship')}
              </label>
              <div className="col-sm-9">
                <Field component={SelectField} name={`relationship`}>
                  <option value="spouse">{t('profile.spouse')}</option>
                  <option value="child">{t('profile.child')}</option>
                  <option value="domestic_partner">
                    {t('profile.domestic_partner')}
                  </option>
                </Field>
              </div>
            </div>
          )}

          <div className="form-group row">
            <label className="col-sm-3 control-label required">
              {t('profile.first_name')}
            </label>
            <div className="col-sm-9">
              <Field
                component={InputField}
                name={`firstName`}
                validate={required()}
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 control-label required">
              {t('profile.last_name')}
            </label>
            <div className="col-sm-9">
              <Field
                component={InputField}
                name={`lastName1`}
                validate={required()}
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 control-label required">
              {t('profile.last_name_2')}
            </label>
            <div className="col-sm-9">
              <Field
                component={InputField}
                name={`lastName2`}
                validate={required()}
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 control-label required">
              {t('profile.birthday')}
            </label>
            <div className="col-sm-9">
              <Field
                component={DateField}
                name={`birthday`}
                validate={required()}
                className="form-control"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="submit" className="btn btn-success">
            {t('buttons.update')}
          </button>{' '}
          <button type="button" className="btn btn-default" onClick={toggle}>
            {t('buttons.cancel')}
          </button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default reduxForm({
  form: 'FamilyEditDlg',
})(FamilyEditDlg)

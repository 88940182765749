import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class DateField extends React.Component {
  handleChange = e => {
    const {
      input: { onChange },
    } = this.props;

    onChange(e.target.value);
  };

  render() {
    const {
      input: { value },
      placeholder,
      className,
      meta: { touched, error, warning },
    } = this.props;

    return (
      <React.Fragment>
        <input
          type="date"
          className={className}
          value={value ? moment(value).format('YYYY-MM-DD') : value}
          placeholder={placeholder}
          min="1900-01-01"
          onChange={this.handleChange}
        />
        <div>
          {touched &&
            ((error && <span className="text-danger">{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </div>
      </React.Fragment>
    );
  }
}

DateField.propTypes = {
  input: PropTypes.shape({}),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
};

export default DateField;

import React from 'react'
import { groupBy } from 'lodash'

import { getBenefitsByCategory } from 'store/benefit/actions'
import * as simulatorActions from 'store/simulator/actions'
import CalcDetail from 'components/CalcDetail'
import BagInfo from 'components/BenefitDetail/BagInfo'
import BenefitInfo from 'components/BenefitDetail/BenefitInfo'
import BenefitDetail from 'components/BenefitDetail'
import ProviderInfo from './ProviderInfo'
import ProvidersStep from './ProvidersStep'
import ProductsStep from './ProductsStep'
import FamilySelection from './FamilySelection'
import './HealthInsurancePage.scss'
import { withTranslation, WithTranslation } from 'react-i18next'

interface Props {
  benefits: Benefit[]
  contract: Contract[]
  simulation: Simulation
  onChange: () => void
  dispatch: (action: any) => Promise<any> | void
}

interface States {
  price: number
  details: object
  currentStep: number
  selectedProvider: Provider
  selectedBenefit: Benefit
}

class HealthInsurancePage extends React.Component<Props & WithTranslation, States> {
  contract: Contract = null
  state = {
    price: 0,
    details: {},
    currentStep: 0,
    selectedProvider: null,
    selectedBenefit: null,
  }

  constructor(props) {
    super(props)

    this.props.dispatch(getBenefitsByCategory('health-insurance'))
  }



  handleCompanySelect = (provider: Provider) => {
    // move next
    this.setState({
      currentStep: this.state.currentStep + 1,
      selectedProvider: provider,
    })
  }

  handlePrevStep = () => {
    this.setState({
      currentStep: Math.max(0, this.state.currentStep - 1),
    })
    if (this.contract) {
      this.props.dispatch(simulatorActions.removeItem(this.contract.id))
      this.contract = null
    }
  }

  handleBenefitSelect = (benefit: Benefit) => {
    this.setState({
      currentStep: this.state.currentStep + 1,
      selectedBenefit: benefit,
    })
  }



  render() {
    const { benefits, t, simulation } = this.props
    const { currentStep, selectedProvider, selectedBenefit } = this.state



    const groupedBenefits = groupBy(benefits, 'providerId')
    const providers = Object.values(groupedBenefits).map(
      item => item[0].provider
    )


    const steps =
      providers.length > 1
        ? ['cart.step_company', 'cart.step_product', 'cart.step_family']
        : ['cart.step_product', 'cart.step_family']

    return (
      <div className="health-insurance-page">
        <div className="product-container">
          <div className="product-col">
            {currentStep === 2 ? (
              <BenefitInfo benefit={selectedBenefit} />
            ) : (
              <ProviderInfo provider={selectedProvider} benefit={benefits[0]} />
            )}

            <BagInfo benefit={selectedBenefit} />

            {simulation.available > 0 ?

              <div className="form-wizard">
                <ul>
                  {steps.map((step, index) => {
                    let className = ''
                    if (currentStep > index) {
                      className = 'completed'
                    } else if (currentStep === index) {
                      className = 'active'
                    }
                    return (
                      <li key={step} className={className}>
                        {/* eslint-disable-next-line*/}
                        <a>
                          <span>{index + 1}</span>
                          {t(step)}
                        </a>
                      </li>
                    )
                  })}
                </ul>
                <div className="mt-5" />

                {currentStep === 0 && (
                  <ProvidersStep
                    providers={providers}
                    onSubmit={this.handleCompanySelect}
                  />
                )}

                {currentStep === 1 && (
                  <ProductsStep
                    benefits={groupedBenefits[selectedProvider.id] || []}
                    onPrev={this.handlePrevStep}
                    onSubmit={this.handleBenefitSelect}
                  />
                )}

                {currentStep === 2 && (
                  <BenefitDetail
                    benefit={selectedBenefit}
                    EditComponent={FamilySelection}
                    onPrev={this.handlePrevStep}
                    showBack
                    hideBagInfo
                  />
                )}
              </div>
            :   <div className="alert alert-danger" role="alert">
                  {t('HEADER.LIMIT')} {t('HEADER.REACHED')}
                </div>
            }


          </div>
          <div className="calc-col">
            <CalcDetail {...this.props} />
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(HealthInsurancePage)

import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { required, length, numericality } from 'redux-form-validators'
import { useTranslation } from 'react-i18next'
import access from 'safe-access'

import { formatCurrency } from 'utils/number'
import { updateProfile } from 'store/profile/actions'
import InputField from 'components/forms/InputField'
import SelectField from 'components/forms/SelectField'
import CountrySelect from 'components/forms/CountrySelect'
import ProvinceSelect from 'components/forms/ProvinceSelect'
import DateField from 'components/forms/DateField'

type Props = {
  user: User
  disabilityType: any
  renting: any
  handleSubmit(action: any): any
  dispatch: Function
}

const EmployeeLegalData: React.FC<Props> = props => {
  const { t } = useTranslation()
  const onSubmit = values => {
    props.dispatch(updateProfile(values))
  }

  const { user, disabilityType, handleSubmit } = props

  return (
    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        {/* first column */}
        <div className="col-lg-4">
          <div className="form-group row">
            <label className="col-md-6 control-label pt-0">
              {t('profile.office')}
            </label>
            <div className="col-md-6">{access(user, 'office.name')}</div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label pt-0">
              {t('profile.legal_entity')}
            </label>
            <div className="col-md-6">{access(user, 'legalEntity.name')}</div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label pt-0">
              {t('profile.collective')}
            </label>
            <div className="col-md-6">{access(user, 'collective.name')}</div>
          </div>

          <div className="form-group row">
            <label
              className="col-md-6 control-label"
              dangerouslySetInnerHTML={{ __html: t('profile.id') }}
            />
            <div className="col-md-6">
              <Field
                type="text"
                disabled
                component={InputField}
                name="personalID"
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label required">
              {t('profile.phone')}
            </label>
            <div className="col-md-6">
              <Field
                type="text"
                component={InputField}
                name="phone"
                validate={[
                  required(),
                  numericality({ allowBlank: true })
                ]}
              />
            </div>
          </div>

          <div className="form-group row">
            <label
              className="col-md-6 control-label"
              dangerouslySetInnerHTML={{ __html: t('profile.bank') }}
            />
            <div className="col-md-6">
              <Field
                type="text"
                component={InputField}
                name="BAN"
                validate={length({ max: 24 })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label required">
              {t('profile.nationality')}
            </label>
            <div className="col-md-6">
              <Field component={CountrySelect} name="nationality" />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label required">
              {t('profile.birthday')}
            </label>
            <div className="col-md-6">
              <Field
                component={DateField}
                name="birthday"
                className="form-control"
                validate={required()}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label">
              {t('profile.Gender')}
            </label>
            <div className="col-md-6">
              <Field component={SelectField} name="gender">
                <option value="1">{t('profile.Male')}</option>
                <option value="0">{t('profile.Female')}</option>
              </Field>
            </div>
          </div>
        </div>

        {/* second column */}
        <div className="col-lg-4">
          <div className="form-group row">
            <label className="col-md-6 control-label text-capitalize">
              {t('profile.disability')}
            </label>
            <div className="col-md-6">
              <Field component={SelectField} name="disabilityType">
                <option value="false">{t('profile.No')}</option>
                <option value="true">{t('profile.Yes')}</option>
              </Field>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label">
              % {t('profile.disability')}
            </label>
            <div className="col-md-6">
              <div className="input-group mb-3">
                <Field
                  type="number"
                  parse={value => Number(value)}
                  component={InputField}
                  name="disability"
                  min="0"
                  max="100"
                  disabled={`${disabilityType}` !== 'true'}
                />
                <span className="input-group-addon">%</span>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label required">
              {t('profile.Address1')}
            </label>
            <div className="col-md-6">
              <Field
                type="text"
                component={InputField}
                name="address1"
                validate={required()}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label">
              {t('profile.Address2')}
            </label>
            <div className="col-md-6">
              <Field type="text" component={InputField} name="address2" />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label required">
              {t('profile.City')}
            </label>
            <div className="col-md-6">
              <Field
                type="text"
                component={InputField}
                name="city"
                validate={required()}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label required">
              {t('profile.postal_code')}
            </label>
            <div className="col-md-6">
              <Field
                type="text"
                component={InputField}
                name="postalCode"
                validate={[required(), numericality({ allowBlank: true })]}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label">
              {t('profile.Province')}
            </label>
            <div className="col-md-6">
              <Field type="text" component={ProvinceSelect} name="province" />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label">
              {t('profile.Country')}
            </label>
            <div className="col-md-6">
              <Field component={CountrySelect} name="country" />
            </div>
          </div>
        </div>

        {/* third column */}
        <div className="col-lg-4">
          <div className="form-group row">
            <label className="col-md-6 control-label">
              {t('profile.fixed_salary')}
            </label>
            <div className="col-md-6">
              <Field
                parse={value => Number(value)}
                type="number"
                component={InputField}
                name="fixedSalary"
                validate={numericality({ allowBlank: true })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label">
              {t('profile.variable_salary')}
            </label>
            <div className="col-md-6">
              <Field
                parse={value => Number(value)}
                type="number"
                component={InputField}
                name="variableSalary"
                validate={numericality({ allowBlank: true })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label pt-0">
              {t('profile.flexible_bag')}
            </label>
            <div className="col-md-6">{formatCurrency(user.flexibleBag)}</div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label pt-0">
              {t('profile.top_bag')}
            </label>
            <div className="col-md-6">{formatCurrency(user.topBag)}</div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label pt-0">
              {t('calculator.company_bag')}
            </label>
            <div className="col-md-6">{formatCurrency(user.userBag)}</div>
          </div>


          <div className="form-group row">
            <label className="col-md-6 control-label">
              {t('profile.number_payments')}
            </label>
            <div className="col-md-6">
              <Field
                parse={value => Number(value)}
                type="number"
                component={InputField}
                name="paymentCount"
                validate={numericality({ allowBlank: true })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label">
              {t('profile.social_security')}
            </label>
            <div className="col-md-6">
              <Field
                type="text"
                component={InputField}
                name="securityNumber"
                validate={[
                  numericality({ allowBlank: true }),
                  length({ max: 12 }),
                ]}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label">
              {t('profile.employee_number')}
            </label>
            <div className="col-md-6">
              <Field type="text" component={InputField} name="employeeNumber" />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-md-6 control-label">
              {t('profile.cost_center')}
            </label>
            <div className="col-md-6">
              <Field type="text" component={InputField} name="costCenter" />
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div className="row">
        <div className="col-lg-offset-2 col-lg-4">
          <button type="submit" className="btn-none">
            <i className="fa fa-folder-open-o mr-2"></i>
            {t('buttons.update')}
          </button>
        </div>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'EmployeeLegalData',
})(EmployeeLegalData)

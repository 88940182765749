import { types } from './actions'

const initialState = null

export default (appState = initialState, { type, payload }) => {
  switch (type) {
    case types.USER_LOGIN:
      return payload
    case types.USER_LOGOUT:
      return null
    case 'USER_UPDATE':
      return {
        ...(appState || {}),
        firstName: payload.firstName,
        lastName: payload.lastName,
        birthday: payload.birthday,
        picture: payload.picture,
        fixedSalary: payload.fixedSalary,
        variableSalary: payload.variableSalary,
      }
    default:
      return appState
  }
}

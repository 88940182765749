import { connect } from 'react-redux'

import { selectProfile } from 'store/profile/reducer'
import { getTable } from 'store/company/reducer'
import LifeInsurance from './LifeInsurance'

const mapStateToProps = state => ({
  user: selectProfile(state),
  table: getTable(state),
})

export default connect(mapStateToProps)(LifeInsurance)

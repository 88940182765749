import { types } from './actions';

const initialState = {
  company: [],
  user: [],
};

export default (appState = initialState, { type, payload }) => {
  switch (type) {
    case types.DOCUMENT_COMPANY:
      return {
        ...appState,
        company: payload,
      };
    case types.DOCUMENT_USER:
      return {
        ...appState,
        user: payload,
      };
    default:
      return appState;
  }
};

export const getCompanyDocuments = state => state.document.company;
export const getUserDocuments = state => state.document.user;

import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { groupBy, get } from 'lodash'
import access from 'safe-access'
import { useTranslation } from 'react-i18next'

import { getTranslate } from 'helpers/translate'
import Panel from 'components/Panel'

type Props = {
  user: User
  contracts: Contract[]
}

const Alerts: React.FC<Props> = props => {
  const { t, i18n } = useTranslation()
  const {
    user: { company },
    contracts,
  } = props

  const expiredContracts = contracts.filter(contract =>
    moment()
      .add(company.alertDays || 0, 'd')
      .isAfter(contract.benefit.endDate)
  )

  const monthlyInvoiceContracts = contracts.filter(contract =>{
        const freq = get(contract, 'additionalInfo.paymentFreq')
        return freq && freq === 'monthly'
  })
  
  const grouped = groupBy(expiredContracts, 'product')
  return (
    <Panel
      title={t('HOME.ALERTS')}
      color={company.colorTitles}
      bodyClass="px-0 scrollme-x"
    >
      {expiredContracts.length === 0 && monthlyInvoiceContracts.length === 0 ? (
        <div className="text-center">{t('HOME.NO_ACTIVE_ALERTS')}</div>
      ) : (
        <table className="table table-dark">
          <colgroup>
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="50px" />
          </colgroup>
          <thead>
            <tr>
              <th>{t('HOME.PRODUCT')}</th>
              <th>{t('HOME.Status')}</th>
              <th>{t('HOME.END_DATE')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {Object.keys(grouped).map(product => {
              return (
                <tr key={product}>
                  <td>
                    {getTranslate(
                      access(grouped[product], '[0].benefit.product.category'),
                      'name',
                      i18n.language
                    )}
                  </td>
                  <td>
                    <label className="label label-warning">
                      {t('HOME.Expiring')}
                    </label>
                  </td>
                  <td>
                  {access(grouped[product], '[0].benefit.endDate') &&
                    moment(
                      access(grouped[product], '[0].benefit.endDate')
                    ).format('YYYY-MM-DD')}
                  </td>
                  <td>
                    <Link
                      to={`/products/${product}`}
                      className="btn btn-success btn-sm"
                    >
                      {t('HOME.Go')}
                    </Link>
                  </td>
                </tr>
              )
            })}
            {monthlyInvoiceContracts.map(contract => {
              return (
                  <tr key={contract.id}>
                    <td>
                      {getTranslate(
                          access(contract, 'benefit.product.category'),
                          'name',
                          i18n.language
                      )}
                    </td>
                    <td>
                      <label className="label label-warning">
                        {t('HOME.ATTACH_INVOICE')}
                      </label>
                    </td>
                    <td>
                      {moment().format('YYYY-MM-DD')}
                    </td>

                    <td>
                      <Link
                          to={`/documents`}
                          className="btn btn-success btn-sm"
                      >
                        {t('HOME.Go')}
                      </Link>
                    </td>

                  </tr>
              )
            })}

          </tbody>
        </table>
      )}
    </Panel>
  )
}

export default Alerts

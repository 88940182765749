import { types } from './actions'

const initialState = {
  info: null,
  loginScreen: {},
  benefits: [],
  messages: [],
  documents: [],
  offices: [],
  legalEntities: [],
  collectives: [],
  table: null,
}

export default (appState = initialState, { type, payload }) => {
  switch (type) {
    case types.COMPANY_INFO:
      return {
        ...appState,
        info: payload,
      }

    case types.COMPANY_BENEFITS:
      return {
        ...appState,
        benefits: payload,
      }
    case types.COMPANY_LOGINSCREEN:
      return {
        ...appState,
        loginScreen: payload,
      }

    case types.COMPANY_MESSAGES:
      return {
        ...appState,
        messages: payload,
      }

    case types.COMPANY_DOCUMENTS:
      return {
        ...appState,
        documents: payload,
      }

    case types.OFFICE_GET:
      return {
        ...appState,
        offices: payload,
      }

    case types.LEGAL_ENTITY_GET:
      return {
        ...appState,
        legalEntities: payload,
      }

    case types.COLLECTIVE_GET:
      return {
        ...appState,
        collectives: payload,
      }

    case types.TABLE_GET:
      return {
        ...appState,
        table: payload,
      }

    default:
      return appState
  }
}

export const getCompanyInfo = state => state.company.info
export const getBenefits = state => state.company.benefits
export const getMessages = state => state.company.messages
export const getDocuments = state => state.company.documents
export const getTable = state => state.company.table

import React, { useState } from 'react'
import Turnstile from "react-turnstile"

interface Props {
    onTimeout: () => void
    onExpire: () => void
    onError: () => void
    onVerify: (token: string) => void
}



const TurnstileComponent: React.FC<Props> = props => {
    const { onVerify, onError, onExpire, onTimeout } = props
    const [error, setError] = useState(null)
    const enable = process.env.REACT_APP_DISABLE_CAPTCHA !== '1'

    return (
        enable && (
            <div className="d-flex flex-column justify-content-center">

                {error && (
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                )}

                <Turnstile
                    sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY || '0x00'}
                    onVerify={ onVerify }
                    onError={ () => {
                        setError('Estamos temporalmente en mantenimiento. ' +
                                'Es posible que algunas funciones estén deshabilitadas.')
                        onError()
                    }}
                    onExpire={ onExpire }
                    onTimeout={ onTimeout }
                />
            </div>
        )
    )
}


export default TurnstileComponent

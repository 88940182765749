import React, { FC } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { withTranslation, WithTranslation } from "react-i18next"
import { reduxForm } from "redux-form"
import Questions from "./Questions"
import Document from "./Document"

interface Props {
    toggle(): void

    userId: number
    product: Product
}

/**
 *
 * @param toggle
 * @param t
 * @param product
 * @param handleSubmit
 * @constructor
 */
const ProductQuestions: FC<Props & WithTranslation & FormProps> = ({ toggle, t, product, handleSubmit }) => {
    return (
        <Modal
            className="cart-edit-modal"
            isOpen={true}
            toggle={toggle}
        >
            <form onSubmit={handleSubmit}>
                <ModalHeader toggle={toggle}/>

                <ModalBody>
                    {product.isQuestionsRequired && product.questions && (
                        <Questions list={product.questions} />
                    )}

                    {product.isDocumentRequired && (
                        <Document title={product.documentName} />
                    )}
                </ModalBody>

                <ModalFooter>
                    <button
                        type="submit"
                        className="btn btn-success mr-3"
                    >
                        {"ACTUALIZAR"}
                    </button>

                    <button
                        type="button"
                        className="btn btn-default"
                        onClick={toggle}
                    >
                        {t('buttons.cancel')}
                    </button>
                </ModalFooter>
            </form>
        </Modal>
    )
}

export default reduxForm({
    form: 'ProductAnswers',
})(withTranslation()(ProductQuestions))

import { connect } from 'react-redux'

import { getSimulation } from 'store/simulator/reducer'
import MonthEditDialog from './MonthEditDialog'

const mapStateToProps = state => ({
  simulation: getSimulation(state),
})

export default connect(mapStateToProps)(MonthEditDialog)

import { types } from './actions'

const initialState = null

export default (appState = initialState, { type, payload }) => {
  switch (type) {
    case types.MENU_ITEMS:
      return payload
    default:
      return appState
  }
}

export const selectMenu = state => state.menu

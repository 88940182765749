import React from 'react'

import Panel from 'components/Panel'
import BagInfo from 'components/BenefitDetail/BagInfo'
import ProductStatus from './ProductStatus'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

type Props = {
  user: User
  contracts: Contract[]
}

const CurrentSituation: React.FC<Props> = ({ user, contracts }) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const { products } = user
  const { company } = user
  const advantageProducts = products.filter(product => product.PL === 'Exent')
  const otherProducts = products.filter(product => product.PL === 'Top')

  return (
    <Panel title={t('HOME.CURRENT_SITUATION')} color={company.colorTitles}>
      <BagInfo showEmployBag={!!advantageProducts.length} showTopBag={!!otherProducts.length} />

      <div className="mt-2" />
      {advantageProducts.length > 0 && (
        <Panel title={t('HOME.PRODUCTS_WITH_TAX')} color={company.colorTitles}>
          <div className="products-container">
            {advantageProducts.map(product => (
              <ProductStatus
                key={product.name}
                product={product}
                contracts={contracts}
                lite={isMobile}
              />
            ))}
          </div>
        </Panel>
      )}

      {otherProducts.length > 0 && (
        <Panel title={t('MENU.OTHER_PRODUCTS')} color={company.colorTitles}>
          <div className="products-container">
            {otherProducts.map(product => (
              <ProductStatus
                key={product.name}
                product={product}
                contracts={contracts}
                lite={isMobile}
              />
            ))}
          </div>
        </Panel>
      )}
    </Panel>
  )
}

export default CurrentSituation

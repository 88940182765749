import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import access from 'safe-access'
import { groupBy } from 'lodash'
import moment from 'moment'
import { selectProfile } from 'store/profile/reducer'

import { getContractItems } from 'store/simulator/reducer'
import { getProductStatus } from 'utils/contracts'
import Panel from 'components/Panel'
import { getTranslate } from 'helpers/translate'

const PendingSimulations = () => {
  const { t, i18n } = useTranslation()
  const contracts = useSelector(getContractItems)
  const grouped = groupBy(contracts, 'product')
  const user = useSelector(selectProfile)

  if (!contracts.length) return null

  let groupedPending = {}
  Object.keys(grouped).forEach(product => {
    const status = getProductStatus(grouped[product])
    if (
      ['missing-document', 'pending-completion', 'pending-review'].includes(
        status.status
      )
    ) {
      groupedPending[product] = grouped[product]
    }
  })

  return (
    <Panel
      title={t('HOME.PENDING_SIMULATIONS')}
      color={user.company.colorTitles}
      bodyClass="px-0 scrollme-x"
    >
      {!Object.keys(groupedPending).length ? (
        <div className="text-center">No Active Alerts</div>
      ) : (
        <table className="table table-dark">
          <colgroup>
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="50px" />
          </colgroup>
          <thead>
            <tr>
              <th>{t('HOME.PRODUCT')}</th>
              <th>{t('HOME.Status')}</th>
              <th>{t('HOME.END_DATE')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {Object.keys(groupedPending).map(product => {
              const status = getProductStatus(grouped[product])

              return (
                <tr key={product}>
                  <td>
                    {getTranslate(
                      access(groupedPending[product], '[0].benefit.product.category'),
                      'name',
                      i18n.language
                    )}
                  </td>
                  <td>
                    <label className={`label ${status.status}`}>
                      {t(status.text)}
                    </label>
                  </td>
                  <td>
                    {access(groupedPending[product], '[0].benefit.endDate') &&
                      moment(
                        access(groupedPending[product], '[0].benefit.endDate')
                      ).format('YYYY-MM-DD')}
                  </td>
                  <td>
                    {status.status === 'pending-completion' && (
                      <Link to={`/cart`} className="btn btn-success btn-sm">
                        {t('HOME.Go')}
                      </Link>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </Panel>
  )
}

export default PendingSimulations

import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from 'utils/number'
import './ContractPrice.scss'

type Props = {
  price: number,
  unit?: string,
  discount: number
}

const ContractPrice: React.FC<Props> = ({ price, discount , unit }) => {
  const { t } = useTranslation()

  const _disc = formatCurrency(discount)
  const _price = formatCurrency(price)
  const _total = formatCurrency(Math.max(0, (price || 0) - (discount || 0)))

  return (
      discount ?
          <div className="d-flex justify-content-center">
          <span className="benefit-price">
              {t("prices.price")} {_price}
          </span>
              <span className="benefit-discount">
              {t("prices.discount")} {_disc}
          </span>
              <span className="benefit-price-total">
              {t("prices.total")} {_total}
              {unit && <small>/ {unit}</small> }
          </span>

          </div>

          :
      <span className="benefit-price">
            {t("prices.total")} {_total}
            {unit && <small>/ {unit}</small> }
      </span>

  )
}

export default ContractPrice


import React from 'react'
type Props = {}

const CookiesMessage: React.FC<Props> = () => {
    return (
        <div>
                <div className="d-flex justify-content-center">
                    <h3>Aviso de privacidad</h3>
                </div>

                <div className="heading4 mb-2 cookies-body">
                    <p>
                        Este sitio web puede utilizar cookies técnicas (pequeños archivos de
                        información que el servidor envía al ordenador de quien accede a la página)
                        para llevar a cabo determinadas funciones que son consideradas
                        imprescindibles para el correcto funcionamiento y visualización del sitio.
                    </p>
                    <p>
                        Las cookies utilizadas tienen, en todo caso, carácter temporal, con la única finalidad
                        de hacer más eficaz la navegación, y desaparecen al terminar la sesión del
                        usuario.
                    </p>
                    <p>
                        En ningún caso, estas cookies proporcionan por sí mismas datos de
                        carácter personal y no se utilizarán para la recogida de los mismos.
                    </p>
                    <p>
                        Mediante el uso de cookies también es posible que el servidor donde se
                        encuentra la web reconozca el navegador utilizado por el usuario con la
                        finalidad de que la navegación sea más sencilla, permitiendo, por ejemplo, el
                        acceso de los usuarios que se hayan registrado previamente a las áreas,
                        servicios, promociones o concursos reservados exclusivamente a ellos sin tener
                        que registrarse en cada visita.
                    </p>

                </div>

        </div>


    )
}

export default CookiesMessage

import React from 'react'
import PropTypes from 'prop-types'
import { showNotify } from "../../utils/notify"

class NumberField extends React.Component {
  handleChange = e => {
    const { onChange, returnEvent, name, min, max } = this.props

    const val = Number(e.target.value)

    if(max != null && val>max){
      showNotify(`El valor del campo ${name} ha superado el máximo (${max})`, 'error')
      e.target.value = max.toString()
      return
    }
    if(min != null && val<min){
      showNotify(`El valor del campo ${name} es inferior al mínimo (${min})` , 'error')
      e.target.value = min.toString()
      return
    }
    e.target.value = val
    onChange(returnEvent ? e : e.target.value)
  };

  render() {
    const {
      input,
      placeholder,
      name,
      className,
      min,
      max,
      disabled
    } = this.props

    return (
      <React.Fragment>
        <input
          type="number"
          name={name}
          className={className}
          value={input}
          placeholder={placeholder}
          min={min}
          max={max}
          disabled={disabled}
          onChange={this.handleChange}
        />
      </React.Fragment>
    )
  }
}

NumberField.propTypes = {
  returnEvent: PropTypes.bool,
  onChange: PropTypes.func,
  input: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool
}

export default NumberField

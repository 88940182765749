import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import ServerImage from 'components/ServerImage'
import { getTranslate } from 'helpers/translate'

type Props = {
  benefit: Benefit
  isOpen: boolean
  toggle(): void
}

const BenefitDescModal: React.FC<Props> = ({ benefit, isOpen, toggle }) => {
  const { i18n } = useTranslation()

  let longDesc: string
  if (i18n.language === 'es') {
    longDesc = benefit.spanishLongDesc
  } else if (i18n.language === 'ca') {
    longDesc = benefit.catalanLongDesc
  } else if (i18n.language === 'pt') {
    longDesc = benefit.portugueseLongDesc
  } else {
    longDesc = benefit.longDesc
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className="p-3">
        <button className="close" onClick={toggle} title="Close">
          ×
        </button>
      </div>
      <ModalBody>
        <div className="image-card">
          {benefit.logo && (
            <div className="image">
              <ServerImage src={benefit.logo} />
            </div>
          )}

          <div className="card-benefit">
            <h2 className="mt-0">
              {getTranslate(benefit, 'name', i18n.language)}
              {benefit.providerImage && (
                <ServerImage
                  src={benefit.providerImage}
                  className="logo-small ml-2"
                />
              )}
            </h2>
          </div>
        </div>

        <div dangerouslySetInnerHTML={{ __html: longDesc }} />

        {benefit.providerWebsite && (
          <div className="mt-3 provider-website">
            <a
              href={benefit.providerWebsite}
              target="_blank"
              rel="noopener noreferrer"
            >
              {benefit.providerWebsite}
            </a>
          </div>
        )}

        {benefit.featuredImage && (
          <ServerImage
            src={benefit.featuredImage}
            className="featured-image mt-3"
            embed
          />
        )}
      </ModalBody>
    </Modal>
  )
}

export default BenefitDescModal

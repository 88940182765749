import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import en from './en'
import es from './es'
import ca from './ca'
import pt from './pt'


if(process.env.REACT_APP_DISABLE_LANGUAGE === '1') {
    i18n
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            lng: 'es',
            debug: false,
            resources: {
                es: {
                    translation: es,
                },
            },
        })
}else{
    i18n
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            whitelist: ['es', 'en', 'ca','pt'],
            fallbackLng: 'en',
            debug: true,
            resources: {
                en: {
                    translation: en,
                },
                es: {
                    translation: es,
                },
                ca: {
                    translation: ca,
                },
                pt: {
                    translation: pt,
                },
            },
        })
}

export default i18n

import React from 'react'
import access from 'safe-access'

import { isProductLimitNotReached } from 'utils/contracts'
import ContractItem from 'models/Contract/ContractItem'
import MonthSelect from 'components/BenefitDetail/MonthSelect'
import { withTranslation, WithTranslation } from 'react-i18next'
import ContractPrice from "components/BenefitDetail/ContractPrice"

interface Props {
  contract: Contract
  editable?: boolean
  simulation: Simulation
  user: User
  onChange: () => void
}

class Gym extends React.Component<Props & WithTranslation> {
  static defaultProps = {
    editable: true,
  }

  getMaxPrice = () => {
    const {
      contract: { benefit },
    } = this.props

    let maxPrice = benefit.defaultPrice
    if (benefit.isPercentContribute()) {
      maxPrice = (maxPrice * (100 - benefit.contributionPrice)) / 100
    } else {
      maxPrice -= benefit.contributionPrice
    }

    return maxPrice
  }

  handleMonthChange = (startMonth: number) => {
    const {
      contract,
      onChange,
      simulation: { flexibleBag, topBag, fixedSalary }
    } = this.props
    const isTaxAdvantage = access(contract, 'benefit.isTaxAdvantage')
    let availableMaxPrice = isTaxAdvantage ? flexibleBag : topBag
    if (!isTaxAdvantage && !topBag) {
      availableMaxPrice = fixedSalary
    }
    
    if (!this.props.editable) return

    const maxPrice = this.getMaxPrice()

    const items = []
    for (let month = startMonth; month <= 11; month += 1) {
      items.push(
        new ContractItem({
          familyId: 0,
          month,
          price: Math.min(maxPrice, availableMaxPrice),
        })
      )
    }

    contract.updateItems(items)

    onChange()
  }

  render() {
    const { contract, editable, simulation, t } = this.props
    const isLimitNotReached = isProductLimitNotReached(contract, simulation)

    const monthsContract =  contract.items.map(el=>el.month)

    let startMonth = monthsContract.length ? Math.min.apply(null,monthsContract) : 13

    return isLimitNotReached ? (
      <React.Fragment>
          <div className="panel panel-primary mt-4 mb-0">
            <div className="panel-body">
              <div className="d-flex align-items-center">
                <label className="mr-2">{t("contracts.select_start_month")}</label>
                <div>
                  <MonthSelect
                    availability={contract.benefit.monthsAvailability}
                    value={startMonth}
                    disabled={!editable}
                    onChange={this.handleMonthChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="text-center my-3">
            <ContractPrice price={contract.price} discount={contract.userBag}/>

          </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className="alert alert-danger" role="alert">
          {t('HEADER.LIMIT')} {t('HEADER.REACHED')}
        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation()(Gym)

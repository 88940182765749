import * as api from 'utils/api'

export const types = {
  DOCUMENT_COMPANY: 'DOCUMENT_COMPANY',
  DOCUMENT_USER: 'DOCUMENT_USER',
}

export const getCompanyDocuments = (userId) => dispatch => {
  return api.get(`/users/${userId}/documents/all`).then(res => {
    dispatch({
      type: types.DOCUMENT_COMPANY,
      payload: res,
    })
  })
}

export const getUserDocuments = () => dispatch => {
  return api.get(`/documents`).then(res => {
    dispatch({
      type: types.DOCUMENT_USER,
      payload: res,
    })
  })
}

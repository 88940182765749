import React from 'react'

import { uploadFile } from 'store/app/actions'

interface Props {
    container?: string
    accept?: string
}

class UploadFieldWithName extends React.Component<Props & FieldProps> {
    static defaultProps = {
        container: 'document',
    }

    file = null

    state = {
        uploading: false,
        progress: 0,
    }

    handleFileChange = e => {
        if (!e.target.files.length) {
            return
        }

        const {
            input: { onChange },
            container,
        } = this.props

        this.setState({ uploading: true })
        uploadFile(
            e.target.files[0],
            progress => {
                this.setState({ progress })
            },
            container
        )
            .then(uploadedFile => {
                onChange({
                    file: uploadedFile.name,
                    name: uploadedFile.originalFilename,
                    // type: uploadedFile.type,
                })
            })
            .finally(() => {
                this.setState({ uploading: false })
            })
    }

    triggerUpload = () => {
        this.file.click()
    }

    clearUpload = () => {
        const {
            input: { onChange },
        } = this.props
        onChange(null)
    }

    render() {
        const {
            container,
            input: { value },
            meta: { touched, error },
            accept
        } = this.props
        const { uploading, progress } = this.state

        return (
            <div>
                <input
                    ref={c => {
                        this.file = c
                    }}
                    type='file'
                    name='file'
                    className='d-none'
                    onChange={this.handleFileChange}
                    accept={accept}
                />
                {uploading && (
                    <div className='progress'>
                        <div
                            className='progress-bar'
                            role='progressbar'
                            style={{ width: `${progress}%` }}
                        />
                    </div>
                )}

                {!value && (
                    <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={this.triggerUpload}
                    >
                        Upload file
                    </button>
                )}

                {value && (
                    <div>
                        <a
                            href={`/api/storage/${container}/${value.file}`}
                            target='__blank'
                        >
                            {value.name}
                        </a>
                        <span
                            role='button'
                            className='cp btn-icon'
                            onClick={this.triggerUpload}
                        >
            <i className='fa fa-edit ml-2'/>
                </span>
                        <span
                            role='button'
                            className='cp btn-icon ml-2'
                            onClick={this.clearUpload}
                        >
            <i className='fa fa-close'/>
                </span>
                    </div>
                )}
                <div>
                    {touched && error && <span className='text-danger'>{error}</span>}
                </div>
            </div>
        )
    }
}

export default UploadFieldWithName

import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'

import EmployeeLegalData from './EmployeeLegalData'

const selector = formValueSelector('EmployeeLegalData')
const mapStateToProps = state => ({
  disabilityType: selector(state, 'disabilityType'),
  renting: selector(state, 'renting'),
})

export default connect(mapStateToProps)(EmployeeLegalData)

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { withLayout } from 'pages/Layout'
import CompensationPage from './CompensationPage'
import { getCompensations } from "../../store/compensation/reducer"

const mapStateToProps = state => ({
  compensations: getCompensations(state).compensations
})

export default withLayout('CompensationPage', withRouter(connect(mapStateToProps)(CompensationPage)))

import { connect } from 'react-redux'

import { getCart } from 'store/cart/reducer'
import { getFamily } from 'store/families/reducer'
import { getSimulation } from 'store/simulator/reducer'
import { withLayout } from 'pages/Layout'
import Cart from './Cart'

const mapStateToProps = state => ({
  cart: getCart(state),
  family: getFamily(state),
  simulation: getSimulation(state),
})

export default withLayout('Cart', connect(mapStateToProps)(Cart))

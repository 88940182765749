import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect, Switch, withRouter } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import reduce from 'lodash/reduce'
import 'moment/locale/es'
import 'moment/locale/ca'

import { getSpinner } from 'store/app/reducer'
import { selectProfile } from 'store/profile/reducer'
import ScrollToTop from 'components/ScrollToTop'
import authRoutes from './routes/auth'
import guestRoutes from './routes/guest'
import incompletedRoutes from './routes/incompletedInfo'

import './scss/app.scss'

interface Props {
  isLoading: boolean
  user: User
  dispatch(action: any): any
}

class App extends React.Component<Props> {
  render() {
    const { user, isLoading } = this.props

    if (isLoading) {
      return (
        <div className="spinner-container">
          <Spinner color="info" />
        </div>
      )
    }

    let routes
    if (!user) {
      routes = guestRoutes
    } else {
      if(user.company && user.company.maintenance){
        routes = guestRoutes
      }else if(!user.email || !user.phone || user.isPasswordDeprecated){
        routes = incompletedRoutes
      }else{
        routes = authRoutes
      }
    }

    // extract children
    const spreadRoutes = reduce(
      routes,
      (result, value) => {
        if (value.children) {
          return [...result, ...value.children]
        } else {
          return [...result, value]
        }
      },
      []
    )

    return (
      <ScrollToTop>
        <Switch>
          {spreadRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              component={() => <route.component {...this.props} />}
              exact={route.exact}
            />
          ))}

          <Redirect to={routes[0].path} />
        </Switch>
      </ScrollToTop>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: getSpinner('PROFILE_INFO')(state),
  user: selectProfile(state),
})

export default withRouter(connect(mapStateToProps)(App))

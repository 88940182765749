import * as api from 'utils/api'
import { showNotify } from "../../utils/notify"
import { getCompanyDocuments } from "../document/actions"
import { reset } from "redux-form"

export const types = {
  SHOW_SPINNER: 'LAYOUT_SHOW_SPINNER',
  HIDE_SPINNER: 'HIDE_SPINNER',
}

export const showSpinner = (id = 'app') => ({
  type: types.SHOW_SPINNER,
  payload: id,
})

export const hideSpinner = (id = 'app') => ({
  type: types.HIDE_SPINNER,
  payload: id,
})

export const createUserDocNew = (userId, params, formName) => dispatch => {
  return api.post(`/users/${userId}/documents`, params).then(res => {
    showNotify('El documento ha sido creado')
    dispatch(getCompanyDocuments(userId))
    dispatch(reset(formName))
  })
}

export const createProductDoc = (userId, params) => () => {
  return api.post(`/users/${userId}/documents`, {
    file: params.file,
    name: params.name,
    type: "User file",
    public: false
  })
}

export const createProductAnswers = (contractId, userId, productId, params) => () => {
  return api.post(`/answers/add`, {
    contractId,
    userId,
    productId,
    isCompleted: true,
    answers: { ...params.answer, document: params.file || null }
  })
}

export const uploadFile = (file, onProgress, container = 'default') => {
  const formData = new FormData()
  formData.append('file', file, file.name)

  return api.post(`/storage/${container}`, formData, {
    onUploadProgress(progressEvent) {
      if (!onProgress) {
        return
      }

      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      )
      onProgress(percentCompleted)
    },
  })
}

export const createUserDoc = (userId, res) => {
  const params = {
    file: res.name,
    name: res.originalFilename,
    public: false,
    type: "User file"
  }

  api.post(`/users/${userId}/documents`, params)
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, withRouter } from 'react-router-dom'

type Props = {
  user: User
}

const UncompletedInfo: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation()


  return (
    <div className="home-page">


        <div className="alert alert-warning" role="alert">


            { user.isPasswordDeprecated ? t('profile.password_deprecated') : t('HOME.MISSING_PROFILE_INFO')}

            <Link to="/profile">
                <i className="entypo-user" />
                <span className="title">{t('MENU.PROFILE')}</span>
            </Link>

        </div>



    </div>

  )
}

export default withRouter(UncompletedInfo)

/* global FileReader */
import React from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'

import { showNotify } from 'utils/notify'
import { uploadFile } from 'store/app/actions'
import classes from './ImageUploadField.module.scss'
import LoadingButton from 'components/LoadingButton'

interface Props {
  aspectRatio?: number
  className?: string
  defaultImage?: string
  rounded?: boolean
  title?: string
  width?: number
}

interface States {
  modal: boolean
  imageSrc: any
  isLoading: boolean
}

class ImageUploadField extends React.Component<Props & FieldProps, States> {
  static defaultProps = {
    className: '',
    defaultImage: null,
    rounded: false,
    aspectRatio: null,
    title: 'Upload image',
    width: 300,
  }

  fileName = ''
  cropper: any = null
  file: any = null

  state = {
    modal: false,
    imageSrc: '',
    isLoading: false,
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.setState({
          imageSrc: reader.result,
          modal: true,
        })
        this.file.value = null
      })
      reader.readAsDataURL(e.target.files[0])
      this.fileName = e.target.files[0].name
    }
  }

  triggerUpload = () => {
    this.file.click()
  }

  toggleModal = () => {
    this.setState({ modal: !this.state.modal })
  }

  cropImage = () => {
    this.setState({ isLoading: true })
    return this.cropper
      .getCroppedCanvas({
        width: this.props.width,
        imageSmoothingQuality: 'high',
      })
      .toBlob(blob => {
        blob.name = this.fileName
        return uploadFile(blob, null, 'images').then(
          res => {
            const {
              input: { onChange },
            } = this.props
            onChange(res.name)
            this.setState({ isLoading: false, modal: false })
          },
          () => {
            showNotify('Error en la carga', 'error')
            this.setState({ isLoading: false })
          }
        )
      })
  }

  clearUpload = () => {
    const {
      input: { onChange },
    } = this.props
    onChange(null)
  }

  render() {
    const {
      input: { value },
      className,
      title,
      aspectRatio,
      rounded,
      defaultImage,
    } = this.props
    const { modal, imageSrc } = this.state

    return (
      <div className={className}>
        <input
          ref={c => {
            this.file = c
          }}
          type="file"
          name="file"
          className="d-none"
          onChange={this.onSelectFile}
          accept="image/x-png,image/gif,image/jpeg"
        />
        {value || defaultImage ? (
          <span
            className={`${classes.uploaded} ${rounded && 'rounded-circle'}`}
          >
            <img
              src={
                value ? `/api/storage/images/${value}` : `/img/${defaultImage}`
              }
              className="mw-100"
              alt="logo"
              width="120"
            />
            <div className={classes.tools}>
              <span role="button" title="Edit" onClick={this.triggerUpload}>
                <i className="mdi mdi-square-edit-outline" />
              </span>
              <span
                role="button"
                title="Remove"
                className="ml-2"
                onClick={this.clearUpload}
              >
                <i className="mdi mdi-trash-can" />
              </span>
            </div>
          </span>
        ) : (
          <button
            type="button"
            className="btn btn-secondary"
            onClick={this.triggerUpload}
          >
            {title}
          </button>
        )}

        <Modal isOpen={modal} toggle={this.toggleModal}>
          <ModalBody>
            <div>
              <Cropper
                style={{ height: 400 }}
                aspectRatio={aspectRatio}
                preview={`.${classes.preview}`}
                guides={false}
                src={imageSrc}
                ref={cropper => {
                  this.cropper = cropper
                }}
              />
            </div>
            <div className="text-center mt-2">
              <span
                className={`${classes.preview} ${
                  classes.previewMD
                } mr-2  ${rounded && 'rounded-circle'}`}
                style={{ width: 120, height: 115 }}
              />
              <span
                className={`${classes.preview} ${
                  classes.previewSM
                }  ${rounded && 'rounded-circle'}`}
                style={{ width: 40, height: 40 }}
              />
            </div>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <LoadingButton
              type="button"
              color="primary"
              onClick={this.cropImage}
            >
              {'Crop & Save'}
            </LoadingButton>
            <Button type="button" color="link" onClick={this.toggleModal}>
              {'Cancel'}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default ImageUploadField

import React from 'react';

import SelectField from './SelectField';

export const provinces = [
  { name: 'Álava', short: 'VI', country: 'ES' },
  { name: 'Albacete', short: 'AB', country: 'ES' },
  { name: 'Alicante', short: 'A', country: 'ES' },
  { name: 'Almeria', short: 'AL', country: 'ES' },
  { name: 'Asturias', short: 'AS', country: 'ES' },
  { name: 'Ávila', short: 'AV', country: 'ES' },
  { name: 'Badajoz', short: 'BA', country: 'ES' },
  { name: 'Barcelona', short: 'B', country: 'ES' },
  { name: 'Burgos', short: 'BU', country: 'ES' },
  { name: 'Castellón', short: 'CS', country: 'ES' },
  { name: 'Cantabria', short: 'CT', country: 'ES' },
  { name: 'Ceuta', short: 'CE', country: 'ES' },
  { name: 'Ciudad Real', short: 'CR', country: 'ES' },
  { name: 'Cuenca', short: 'CU', country: 'ES' },
  { name: 'Cáceres', short: 'CC', country: 'ES' },
  { name: 'Cádiz', short: 'CA', country: 'ES' },
  { name: 'Córdoba', short: 'CO', country: 'ES' },
  { name: 'Gerona', short: 'GI', country: 'ES' },
  { name: 'Granada', short: 'GR', country: 'ES' },
  { name: 'Guadalajara', short: 'GU', country: 'ES' },
  { name: 'Guipúzcoa', short: 'SS', country: 'ES' },
  { name: 'Huelva', short: 'H', country: 'ES' },
  { name: 'Huesca', short: 'HU', country: 'ES' },
  { name: 'Islas Baleares', short: 'I', country: 'ES' },
  { name: 'Jaén', short: 'J', country: 'ES' },
  { name: 'La Coruña', short: 'C', country: 'ES' },
  { name: 'La Rioja', short: 'LO', country: 'ES' },
  { name: 'Las Palmas', short: 'GC', country: 'ES' },
  { name: 'León', short: 'LE', country: 'ES' },
  { name: 'Lugo', short: 'LU', country: 'ES' },
  { name: 'Lérida', short: 'L', country: 'ES' },
  { name: 'Madrid', short: 'M', country: 'ES' },
  { name: 'Murcia', short: 'MU', country: 'ES' },
  { name: 'Málaga', short: 'MA', country: 'ES' },
  { name: 'Navarra', short: 'N', country: 'ES' },
  { name: 'Orense', short: 'OU', country: 'ES' },
  { name: 'Palencia', short: 'P', country: 'ES' },
  { name: 'Pontevedra', short: 'PO', country: 'ES' },
  { name: 'Salamanca', short: 'SA', country: 'ES' },
  { name: 'Segovia', short: 'SG', country: 'ES' },
  { name: 'Sevilla', short: 'SE', country: 'ES' },
  { name: 'Soria', short: 'SO', country: 'ES' },
  { name: 'Tarragona', short: 'T', country: 'ES' },
  { name: 'Tenerife', short: 'TF', country: 'ES' },
  { name: 'Teruel', short: 'TE', country: 'ES' },
  { name: 'Toledo', short: 'TO', country: 'ES' },
  { name: 'Valencia', short: 'V', country: 'ES' },
  { name: 'Valladolid', short: 'VA', country: 'ES' },
  { name: 'Vizcaya', short: 'BI', country: 'ES' },
  { name: 'Zamora', short: 'ZA', country: 'ES' },
  { name: 'Zaragoza', short: 'Z', country: 'ES' },
]

const ProvinceSelect = props => (
  <SelectField {...props}>
    <option value="">--Choose--</option>
    {provinces.map(item => (
      <option key={item.name} value={item.name}>
        {item.name}
      </option>
    ))}
  </SelectField>
);

export default ProvinceSelect;

import moment from 'moment'
import access from 'safe-access'
import { ProductPL, QuestionareType } from 'helpers/enum'

class Benefit implements Benefit {
  id: number
  type: number
  parentId?: number
  name: string
  logo: string
  userBag: boolean
  ageGroup: AgeGroup[]
  ageGroupForFamily: AgeGroup[]
  allSupplementary?: string
  contributionCompany: string
  contributionTarget: string
  cutOffDate: number = 0
  doc: any
  endDate: number = 0
  defaultPrice: number = 0
  healthType?: string
  label1File?: string
  numberOfFace: number = 0
  priceForFamily: number = 0
  providerWebsite: string
  product: Product
  provider: Provider
  spanishLongDesc?: string
  portugueseLongDesc?: string
  startDate: string = ''
  workingDays: number = 0
  whoAffect: string
  periodOptions: string
  modalityId: number
  supplementaries: Benefit[] = []
  questionare: string = ''
  typeOfQuestionare?: QuestionareType

  monthsAvailability: boolean[]

  constructor(props) {
    Object.assign(this, props)

    this.ageGroup = this.ageGroup || []
    this.ageGroupForFamily = this.ageGroupForFamily || []
    this.defaultPrice = this.defaultPrice ? Number(this.defaultPrice) : 0
    this.monthsAvailability = this.monthsAvailability || []
    this.numberOfFace = this.numberOfFace ? Number(this.numberOfFace) : 0
    this.priceForFamily = this.priceForFamily ? Number(this.priceForFamily) : 0
    this.workingDays = this.workingDays ? Number(this.workingDays) : 0
    this.whoAffect = this.whoAffect || 'both'
    this.contributionTarget = this.contributionTarget || 'both'
    this.periodOptions = this.periodOptions || 'same'

    if (props.supplementaries) {
      this.supplementaries = props.supplementaries.map(
        item => new Benefit(item)
      )
    }
  }

  get isTaxAdvantage(): boolean {
    return access(this, 'product.PL') === ProductPL.Exent
  }

  get workingPrice(): number {
    return this.defaultPrice * this.workingDays
  }

  get contributionPrice(): number {
    const price = parseFloat(this.contributionCompany)

    return isNaN(price) ? 0 : price
  }

  isPercentContribute(): boolean {
    return this.contributionCompany && this.contributionCompany.endsWith('%')
  }

  isBenefit(): boolean {
    return !this.isSupplementary()
  }

  isSupplementary(): boolean {
    return this.type === 1
  }

  isAllSupplementary(): boolean {
    return this.allSupplementary === 'yes'
  }

  getUserPrice(family: Family): number {
    if (!family) return this.defaultPrice

    const ageGroup = family.id ? this.ageGroupForFamily : this.ageGroup
    const age = moment().diff(family.birthday, 'years')

    for (let i = 0; i < ageGroup.length; i += 1) {
      if (age >= ageGroup[i].from && age <= ageGroup[i].to)
        return Number(ageGroup[i].price)
    }

    if (family.id && this.priceForFamily) return this.priceForFamily

    return this.defaultPrice
  }

  getFacePrice(faceNumber: number): number {
    return this[`faceValue${faceNumber}`] || 0
  }

  getStartMonth = () => {
    const startMonth = this.startDate
      ? Number(moment(this.startDate).format('M'))
      : 1
    const cutOffDate = this.cutOffDate || 1

    const month = new Date().getMonth() + 1
    const date = new Date().getDate()

    const availableMonth = date < cutOffDate ? month + 1 : month + 2

    return Math.max(
      availableMonth > 12 ? availableMonth % 12 : availableMonth,
      startMonth
    )
  }

  getEndMonth = () => {
    return this.endDate ? Number(moment(this.endDate).format('M')) : 12
  }

  isInfoRequired(): boolean {
    return (
      access(this, 'product.category.alias') === 'formation' ||
      (this.questionare === 'yes' &&
        this.typeOfQuestionare !== QuestionareType.Telephone)
    )
  }
}

export default Benefit

export default {
  MENU: {
    HOME: 'Home',
    DOCUMENT: 'Documentation',
    PROFILE: 'Personal information',
    ADVANTAGE_PRODUCTS: 'Products with tax advantage',
    OTHER_PRODUCTS: 'Other products',
    LEGAL: 'Legal warning',
    PRIVACY: 'Privacy policies',
    COOKIE_MESSAGE: 'This website uses cookies to improve the experience',
    COMPENSATION: 'My Compensation',
    FLEXIBLE: 'Tax advantage products',
    DOCUMENTATION: 'Documentation',
    PERSONAL: 'Personal information',
    MY_COMPENATION: 'My compensation',
    EXPENSE_MANAGEMENT: 'Expense management',
    CORPORATE_SOCIAL_RESPONSIBILITY: 'Corporate social responsability',
  },
  HEADER: {
    SAVED: 'Saved',
    PENDING_SAVING: 'Possible savings',
    LIMIT: 'Expense limit',
    REACHED: 'Reached',
    LANGUAGE: 'Language',
    CHECKOUT: 'Checkout',
    LOGOUT: 'Log Out',
    HELP: 'Help',
    CONTACT: 'Contact us',
  },
  HOME: {
    MISSING_PROFILE_INFO: 'Incomplete user information: Please check that you have filled in the email / phone in your profile',
    CURRENT_SITUATION: 'Current Situation',
    NEWS: 'News',
    PRODUCTS_WITH_TAX: 'Products With Tax Advantage',
    ALERTS: 'Alerts',
    CONTRACTED_PRODUCTS: 'Contracted products',
    PENDING_SIGNATURE: 'Pending signature',
    PENDING_PRODUCTS: 'Pending products',
    SAVING: 'Saving',
    PENDING_SAVING: 'Pending saving',
    NON_SIMULATED_PRODUCT: 'Non-simulated product',
    PENDING_SIMULATIONS: 'Pending Simulations',
    NO_ACTIVE_ALERTS: 'No Active Alerts',
    PRODUCT: 'Product',
    Status: 'Status',
    END_DATE: 'End date',
    Expiring: 'Expiring',
    Go: 'Go',
    MISSING_DOCUMENT: 'Missing document',
    ATTACH_INVOICE: 'Attach invoice month',
    PENDING_CART: 'Pending cart',
    PENDING_REVIEW: 'Pending review',
    CONTRACTED: 'Contracted',
    CANCELLED: 'Cancelled',
  },

  LIFE_AXA: {
    FALLCC: 'Death from any cause',
    FALLA: 'Death due to accident',
    FALLAC: 'Death due to traffic accident',
    IPAA: 'Absolute Permanent Disability due to accident',
    IPAAC: 'Absolute Permanent Disability due to traffic accident',
    IPACC: 'Absolute Permanent Disability from any cause',
    GINV: 'Great Disability',
    MANDATORY: 'Mandatory',
    MANDATORY_2: 'mandatory to hire',
    AGE_LIMIT: 'Not available for your age',
    CANNOT_EXCEED: 'cannot exceed',
    CANNOT_LESS_FALLA: 'It cannot be less than 35.000 €'
  },

  COMPENSATION:{
    Benefits:"Social Benefits",
    TotalCompensation: "Total Compensation",
    TotalSecurityCost: "Social Security Cost"
  },
  DOCUMENT: {
    Documents: 'Documents',
    DETAIL_ACTION: 'Detail action',
    DETAIL: 'Detail',
    ID: 'Id',
    YEAR: 'Year',
    NAME: 'Name',
    CREATED_BY: 'Created by',
    CREATED_AT: 'Created at',
    LAST_MODIFICATION: 'Last modification',
    Admin: 'Admin',
    no_documents: 'No documents uploaded',
    no_products: 'No products contracted',
  },
  product_names: {
    'health-insurance': 'Health Insurance',
    transport: 'Transport',
    food: 'Food',
    kindergarten: 'Kindergarden',
    formation: 'Training',
    renting: 'Renting',
    'life-insurance': 'Life Insurance',
    'life-insurance-axa': 'Life Insurance',
    'saving-plan': 'Saving Plan',
    gym: 'Gym',
    lottery: 'Lottery',
    informative: 'Informative',
  },
  profile: {
    user_tab: 'User information',
    employee_tab: 'Employee legal data',
    family_tab: 'Family information',
    office: 'Office',
    incorrect_password: 'The password does not meets the minimum requirements: It must have a minimum of 8 characters, contain numbers and letters, at least one upper/lower case character, and no spaces',
    incorrect_repeat_password: 'Password does not match',
    password_deprecated: 'Password is too old. Please reset password',
    collective: 'Collective',
    legal_entity: 'Legal entity',
    email: 'Email',
    password: 'Password',
    repeat_password: 'Repeat password',
    id: 'Identifier<small>(DNI,NIE,Passports)</small>',
    phone: 'Phone number',
    bank: 'Bank number<small>(BAN)</small>',
    nationality: 'Nationality',
    birthday: 'Birthday',
    Gender: 'Gender',
    Male: 'Male',
    Female: 'Female',
    disability: 'disability',
    No: 'No',
    Yes: 'Yes',
    marital_status: 'Marital Status',
    Single: 'Single',
    Married: 'Married',
    Divorced: 'Divorced',
    Widowed: 'Widowed',
    Address1: 'Address1',
    Address2: 'Address2',
    City: 'City',
    Country: 'Country',
    Province: 'Province',
    postal_code: 'Postal code',
    fixed_salary: 'Fixed Salary',
    flexible_bag: 'Flexible Bag',
    top_bag: 'Top Bag',
    variable_salary: 'Variable Salary',
    number_payments: 'Number of payments',
    social_security: 'Social Security Number',
    employee_number: 'Employee number',
    cost_center: 'Cost center',
    renting: 'Renting',
    private_use: 'Renting Private Use',
    relationship: 'Relationship',
    spouse: 'Spouse',
    child: 'Child',
    domestic_partner: 'Domestic Partner',
    name: 'Name',
    surname: 'Surname',
    first_name: 'First name',
    last_name: 'Last name',
    last_name_2: 'Second Last name',
    address: 'Address',
  },
  buttons: {
    accept: 'ACCEPT',
    accept_lower: 'Accept',
    consent_lower: 'Understood',
    close: 'CLOSE',
    add_cart: 'ADD TO CART',
    update_cart: 'UPDATE CART',
    remove_cart: 'REMOVE FROM CART',
    update_contract: 'UPDATE CONTRACT',
    reset: 'RESET',
    see_details: 'See details',
    sign: 'SIGN',
    back: 'BACK',
    update: 'UPDATE',
    cancel: 'CANCEL',
    download: 'DOWNLOAD',
    click_here: 'CLICK HERE',
    back_simulation: 'BACK TO SIMULATION',
    next_step: 'NEXT STEP',
    add_child: 'ADD CHILD',
    add_family: 'ADD FAMILY',
    select: 'SELECT',
    delete: 'DELETE',
    add_member: 'ADD MEMBER',
    reset_password: 'Reset password',
    forgot_password: 'Have you forgot the password?',
    back_to_login: 'Back to login',
    change_language: 'change',
  },
  calculator: {
    header: 'TAX SAVINGS SIMULATOR',
    increase_dis_income: 'Increase in disposable income',
    equi_salary_increase: 'Equivalent salary increase',
    not_available: 'calculator not available: cause: salary info incomplete',
    edit_profile: 'edit salary info in the users profile',
    fixed_salary: 'Fixed Salary',
    variable_salary: 'Variable Salary',
    flexible_products: 'Flexible products',
    without_remuneration: 'Without flexible payment plan',
    with_remuneration: 'With flexible payment plan',
    non_flexible_products: 'Non Flexible products',
    total_gross_salary: 'Total Gross Salary',
    social_security: 'Social security',
    pit: 'PIT',
    withholding_account: 'Withholding on account',
    net_salary: 'Net Salary',
    net_available_salary: 'Net Available Salary',
    employee_bag: 'Employee Bag',
    employee_top_bag: 'Top Bag',
    employee_user_bag: 'User Bag',
    company_bag: 'Company Bag',
    contribution_company: 'Contribution company',
    available: 'Available',
    spent: 'Spent',
  },
  products: {
    health_insurance: 'Healh Insurance',
    month_text: 'Enter the amount available each month',
    min_amount_text: 'Min monthly amount to contract this contract',
    max_available: 'Maximum available monthly',
    transport_max_text: 'The amount limit is € 1500 per year',
    start_date: 'Start date',
    privative_use: 'Privative use',
    year: 'year',
    years: 'Years',
    vehicle_value: 'Vehicule value',
    with_no_tax: 'Quota without TAX',
    with_tax: 'Quota with TAX',
    number_tickets: 'Number of tickets',
    price_tickets: 'Price per ticket',
    death_capital: 'Death capital',
    capital_disability: 'Capital Disability (optional)',
    yearly_price: 'Yearly Price',
    select_children:
      'Please, select the children you would like to add for Kindergarden',
    no_children: 'You have no children above 3 years old',
    person: 'Person',
    monthly: 'Monthly',
    yearly: 'Yearly',
    main_benefit_error: 'Please check the main benefit first',
    benefit_error: 'You must select the benefit',
    price_over_error: 'You exceed the available amount',
  },
  contracts: {
    month: 'Month',
    company: 'Company',
    capital_death_case: 'Capital in case of death',
    capital_dis_case: 'Capital in case disability',
    pending_text:
      'The product is in pending review. We will update you when is contracted',
    cancel_text: 'The benefit is cancelled',
    contract_text: 'You have contracted the benefit already',
    zero_message: 'Total amount is 0',
    min_unreached: 'The minimum monthly amount has not been reached',
    cancel_confirm: 'You are about to cancel the contract. Are you sure?',
    reset_message: 'The settings has been reset',
    update_confirm_message:
      'You are about to change the contract conditions, are you sure?” En that case, please check your mailbox, we will send you a confirmation email',
    select_start_month:"select start month",
    inactive_product:"You are not allowed to hire this product. Please contact administrator for activation.",
  },
  cart: {
    data_confirm: 'Data confirmation',
    sign_contract: 'Signing contract',
    step_company: 'Company Selection',
    step_product: 'Product Selection',
    step_family: 'Family Selection',
    empty_cart: 'Your cart is empty',
    agree_message:
      'By Clicking on Next Step you agree to contract automatically',
    terms: `Firma y recibirás un correo electrónico con el contrato de novación
      adjunto y la relación de productos seleccionados que deberás aceptar.
      <br />
      <br />
      Para poder finalizar tu proceso de contratación, es necesario que abras
      y aceptes las condiciones del correo. Recuerda hacerlo antes del día&nbsp;
      {{cutOffDate}} para disponer del producto el 1 del mes siguiente.`,
    pending: 'PENDING',
    completed: 'COMPLETED',
    download_doc_text: 'Please download the document, fill it out and sign it',
    edit_family: 'Edit family member',
    name: 'Name',
    surname: 'Surname',
    address: 'Address',
    zip: 'Zip',
    contact_person: 'Contact person',
    contact_phone: 'Contact phone',
    center_name: 'Center name',
    course: 'Course',
    phone_contact: 'Phone contact',
    attach_invoice: 'Please attach the invoice',
    same_kindergarten: 'Is the same kindergarten with',
    code_center: 'Code center',
    dontknow: "If you don't know,",
    edit_profile: 'Edit profile',
    modality_info_details: 'Modality Information Details',
    modality: 'Modality',
    beneficiary_info: 'Beneficiary Info',
    additional_info: 'Aditional Info',
    total: 'Total',
    total_anual: 'Total Year',
    total_spent: 'TOTAL SPENT',
    flexible_bag: 'Flexible Bag',
    top_bag: 'Top Bag',
    company_bag: 'Company Bagg',
    health_provider_noselect:
      'cannot select this provider because user already hired other provider',
    no_info: 'No information required',
    expired_products: 'You have products in the cart with dates lower than company’s cut off date, please remove them to be able to hire: ',
    expired_warning: 'EXPIRED! Please, remove it',
    time_left: 'Time left',
    days: 'day(s)',
    remove_products: 'Please remove these products from the cart before contracting',
    no_permission: 'You don\'t have permission to contract this product anymore',
    payment_frequency: 'Payment frequency'
  },
  prices:{
    price: 'Price',
    discount: 'Disc.',
    total: 'Total'
  }

}

import Contract from './GeneralContract'

class LifeInsuranceContract extends Contract {
  constructor(props) {
    super(props)
    this.details = this.details || {
      disability: 0,
      death: 0,
    }
  }

  updateDetails(details) {
    const monthlyPrice = details.price / 12

    // add months
    const items = []
    if (details.startDate != null) {
      for (let month = details.startDate; month < 12; month += 1) {
        this.benefit.monthsAvailability[month] &&
          items.push({
            familyId: 0,
            month,
            price: monthlyPrice,
          })
      }
    }
    this.details = details
    this.items = items

    this.updatePrice()
  }
}

export default LifeInsuranceContract

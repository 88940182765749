export const getTranslate = (obj, fieldName, lang): string => {
  let value = obj[fieldName]
  let langName = fieldName[0].toUpperCase() + fieldName.slice(1)
  if (lang === 'es') {
    value = obj[`es${langName}`] || value
  } else if (lang === 'ca') {
    value = obj[`ca${langName}`] || value
  } else if (lang === 'pt') {
    value = obj[`port${langName}`] || value
  }
  return value
}

import { sumBy } from 'lodash'
import Contract from './GeneralContract'
import { get } from 'lodash'

class RentingContract extends Contract {
  constructor(props) {
    super(props)
    const privateUse = get(props,"simulation.privateUse",0)
    const active = get(props,"simulation.renting",false)
    

    this.details = this.details || {
      feeWithoutVAT: 0,
      feeWithVAT: 0,
      vehicleValue: 0,
      years: 2, //min value
      privateUse,
      active
    }
  }

  getMonthlyPrice(): number {

    const feeWithoutVAT = this.getFeeWithoutVAT()
    const feeWithVAT = this.getFeeWithVAT()
    const privateUse = this.getPrivateUsePct()

    const monthPrice = feeWithoutVAT + (feeWithVAT - feeWithoutVAT) * (privateUse / 100)
    return Number(monthPrice)
  }


  getTotalPrice(): number {
    const yearPrice = sumBy(this.items, 'price')
    return Number(yearPrice)
  }

  getFeeWithVAT() {
    return Number(get(this.details,'feeWithVAT',0))
  }

  getFeeWithoutVAT() {
    return Number(get(this.details,'feeWithoutVAT',0))
  }

  getYears() {
    return Number(get(this.details,'years',0))
  }

  getVehicleValue() {
    return  Number(get(this.details,'vehicleValue',0))
  }

  getPrivateUsePct() {
    return  Number(get(this.details,'privateUse',0))
  }


  getExemptionPrice(tax, family: Family[]): number {
    const monthsInContract = (this.items||[]).length
    const vehicleValue = this.getVehicleValue()
    const privateUsePct = this.getPrivateUsePct()
    const yearPrivateAvailale = vehicleValue * 0.2 * privateUsePct/100
    const monthPrivateAvailable = yearPrivateAvailale / 12
    const privateUseAmount = monthPrivateAvailable * monthsInContract
    return Math.max(this.price - privateUseAmount,0)


  }

  updateDetails(details) {
    this.details = details
    const monthlyPrice = this.getMonthlyPrice()
    const items = []

    // add months
    if (details.startDate || details.startDate === 0) {
      for (let month = details.startDate; month < 12; month += 1) {
        this.benefit.monthsAvailability[month] &&
          items.push({
            familyId: 0,
            month,
            price: monthlyPrice,
          })
      }
    }
    this.items = items
    this.updatePrice()
  }
}

export default RentingContract

import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { withTranslation, WithTranslation } from 'react-i18next'

import { showNotify } from 'utils/notify'
import { generateContract } from 'models/Contract'
import * as simulatorActions from 'store/simulator/actions'

interface Props {
  simulation: Simulation
  cartItem: Contract
  EditComponent: any
  toggle(): void
  dispatch(action: any): void
  onUpdate(contract: Contract): void
}

interface States {
  isNotValid?: boolean
}

class MonthEditDialog extends React.Component<Props & WithTranslation, States> {
  contract: Contract

  constructor(props) {
    super(props)
    const { cartItem } = this.props
    this.contract = generateContract(cartItem)
    this.state = {
      isNotValid: true
    }
  }

  handleValidationChange = (isNotValid: boolean) => {
    this.setState({ isNotValid })
  }

  componentDidMount() {
    this.props.dispatch(simulatorActions.addItem(this.contract))
  }

  componentWillUnmount() {
    this.props.dispatch(simulatorActions.removeItem(this.contract.id))
  }

  handleChange = () => {
    const { contract } = this

    this.props.dispatch(
      simulatorActions.updateItem(contract)
    )
  }

  handleUpdate = () => {
    if (!this.contract.price) {
      showNotify(this.props.t('contracts.zero_message'), 'error')
      return
    }

    this.props.onUpdate(this.contract)
  }

  render() {
    const { toggle, EditComponent, t } = this.props
    const { isNotValid } = this.state

    return (
      <Modal className="cart-edit-modal" isOpen={true} toggle={toggle}>
        <ModalHeader toggle={toggle} />
        <ModalBody>
          <EditComponent
            simulation={this.props.simulation}
            contract={this.contract}
            benefit={this.contract.benefit}
            onChange={this.handleChange}
            onValidationChange={this.handleValidationChange}
          />
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-success"
            onClick={this.handleUpdate}
            disabled={isNotValid}
          >
            {t('buttons.update')}
          </button>{' '}
          <button type="button" className="btn btn-default" onClick={toggle}>
            {t('buttons.cancel')}
          </button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default withTranslation()(MonthEditDialog)

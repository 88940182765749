import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { withLayout } from 'pages/Layout'
import { getBenefits } from 'store/benefit/reducer'
import { getContractItems } from 'store/simulator/reducer'
import { getSimulation } from 'store/simulator/reducer'
import HealthInsurancePage from './HealthInsurancePage'

const mapStateToProps = state => ({
  benefits: getBenefits(state),
  cart: getContractItems(state),
  simulation: getSimulation(state),
})

export default withRouter(
  withLayout('Health Insurnace', connect(mapStateToProps)(HealthInsurancePage))
)

import React from 'react'
import PropTypes from 'prop-types'
import access from 'safe-access'

import { getBenefitsByCategory } from 'store/benefit/actions'
import CalcDetail from 'components/CalcDetail'
import BenefitDetail from 'components/BenefitDetail'
import GeneralProduct from './GeneralProduct'
import FoodVoucherInfo from './FoodVoucherInfo'
import Gym from './Gym'
import Kindergarten from './Kindergarten'
import LifeInsurance from './LifeInsurance'
import LifeInsuranceAxa from './LifeInsuranceAxa'
import Lottery from './Lottery'
import Renting from './Renting'
import HoleYearProduct from './HoleYearProduct'

class ProductsPage extends React.Component {
  constructor(props) {
    super(props)

    const {
      match: {
        params: { category },
      },
    } = this.props
    this.props.dispatch(getBenefitsByCategory(category))
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.match.params.category !== nextProps.match.params.category) {
      this.props.dispatch(
        getBenefitsByCategory(nextProps.match.params.category)
      )
    }
  }

  getCustomPage(contractMethod) {
    if (contractMethod === 'tickets') {
      return Lottery
    } else if (contractMethod === 'hole-year') {
      return HoleYearProduct
    } else {
      return GeneralProduct
    }
  }

  getStandartPage(category, benefit) {
    if (category === 'food' && benefit.numberOfFace) {
      return FoodVoucherInfo
    } else if (category === 'food') {
      return GeneralProduct
    } else if (category === 'gym') {
      return Gym
    } else if (category === 'kindergarten') {
      return Kindergarten
    } else if (category === 'life-insurance') {
      return LifeInsurance
    } else if (category === 'life-insurance-axa') {
      return LifeInsuranceAxa
    } else if (category === 'renting') {
      return Renting
    } else if (category === 'lottery') {
      return Lottery
    } else {
      return GeneralProduct
    }
  }

  render() {
    const {
      match: {
        params: { category: categoryAlias },
      },
      benefits,
    } = this.props

    return (
      <div>
        <div className="product-container">
          <div className="product-col">
            {benefits.map(benefit => {
              if (benefit.product.category.alias !== categoryAlias) return null

              const categoryData = access(benefit, 'product.category')
              const contractMethod = access(benefit, 'contractMethod')
              const { alias: category, isDefault } = categoryData

              let editComponent
              if (categoryData && !isDefault && contractMethod) {
                editComponent = this.getCustomPage(contractMethod)
              } else if (isDefault) {
                editComponent = this.getStandartPage(category, benefit)
              }

              return (
                <BenefitDetail
                  key={benefit.id}
                  benefit={benefit}
                  EditComponent={editComponent}
                  {...this.props}
                />
              )
            })}
          </div>
          <div className="calc-col">
            <CalcDetail {...this.props} />
          </div>
        </div>
      </div>
    )
  }
}

ProductsPage.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    picture: PropTypes.string,
  }),
}

export default ProductsPage

import React from 'react';

const OtrosSvg: React.FC<{ className?: string }> = ({ className }) => (
  <svg className={className} width="39" height="36" viewBox="0 0 39 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M37.0855 8.16357C37.0855 10.2156 37.0855 12.2602 37.0855 14.3123C37.0855 14.5204 37.0706 14.7435 37.0037 14.9368C36.8253 15.4349 36.4461 15.7026 35.9182 15.7398C35.7918 15.7472 35.6654 15.7398 35.539 15.7398C31.5093 15.7398 27.487 15.7398 23.4573 15.7398C23.2714 15.7398 23.0855 15.7398 22.9071 15.7175C22.3495 15.6431 21.9331 15.2045 21.8885 14.6394C21.8811 14.513 21.8885 14.3866 21.8885 14.2602C21.8885 10.2082 21.8885 6.1487 21.8885 2.09665C21.8885 2.0223 21.8885 1.95539 21.8885 1.88104C21.9034 1.10781 22.3643 0.6171 23.1376 0.557621C23.2491 0.550186 23.3606 0.557621 23.4796 0.557621C27.4944 0.557621 31.5093 0.557621 35.5167 0.557621C35.7026 0.557621 35.8885 0.557621 36.0669 0.594796C36.684 0.713755 37.093 1.17472 37.1004 1.80669C37.1153 2.57249 37.1004 3.33086 37.1004 4.09665C37.1004 5.44981 37.1004 6.81041 37.1004 8.16357H37.0855Z" fill="white" />
    <path d="M16.9145 8.1487C16.9145 12.6691 13.2788 16.3048 8.75094 16.3048C4.26767 16.3048 0.594807 12.6394 0.609677 8.15613C0.624547 3.43494 4.48328 0.00743484 8.74351 -1.07391e-07C13.2193 -0.00743505 16.9443 3.64312 16.9145 8.1487Z" fill="white" />
    <path d="M8.72864 35.7993C7.55391 35.7993 6.37919 35.7844 5.21191 35.7993C4.55763 35.8067 4.11897 35.5316 3.8067 34.974C2.61711 32.8773 1.41265 30.7881 0.200755 28.6989C-0.0743379 28.2156 -0.0520331 27.7695 0.22306 27.3011C0.907075 26.1338 1.58365 24.9591 2.26767 23.7844C2.80299 22.855 3.36061 21.9405 3.88105 20.9963C4.17102 20.461 4.60224 20.2305 5.1896 20.2305C5.658 20.2305 6.11897 20.2305 6.58737 20.2305C8.48328 20.2305 10.3718 20.2305 12.2677 20.2305C12.5428 20.2305 12.8327 20.2825 13.0929 20.3717C13.3978 20.4758 13.5688 20.7509 13.7324 21.026C14.7881 22.8773 15.8513 24.7212 16.9145 26.5725C17.0781 26.855 17.2416 27.1375 17.3978 27.4126C17.6134 27.7844 17.6134 28.1636 17.4127 28.5428C17.368 28.632 17.316 28.7138 17.2714 28.803C16.0744 30.8699 14.8699 32.9219 13.6877 34.9963C13.368 35.5539 12.9294 35.7918 12.3048 35.7844C11.1153 35.7695 9.9331 35.7844 8.74351 35.7844L8.72864 35.7993Z" fill="white" />
    <path d="M29.4127 35.7993C27.0558 35.7993 24.6915 35.7993 22.3346 35.7993C22.0372 35.7993 21.7398 35.7695 21.4721 35.6283C20.7956 35.2639 20.5576 34.4312 20.9517 33.7249C21.4127 32.8996 21.8885 32.0818 22.3643 31.2639C24.2677 27.9628 26.171 24.6543 28.0669 21.3532C28.1561 21.197 28.2454 21.0335 28.3495 20.8773C28.8773 20.1264 29.9703 20.1041 30.5279 20.8327C30.6022 20.9294 30.6617 21.0483 30.7286 21.1599C33.1078 25.2937 35.487 29.4275 37.8736 33.5613C38.0595 33.8885 38.2156 34.2156 38.1413 34.6022C38.0223 35.2714 37.5167 35.7249 36.8327 35.7918C36.7063 35.8067 36.5799 35.7918 36.4535 35.7918C34.1041 35.7918 31.7621 35.7918 29.4127 35.7918V35.7993Z" fill="white" />
  </svg>

);

export default OtrosSvg;